<div *ngIf="vo$ | async; let vo" class="app-exposition-access-config">
	<app-card-outline class="app-card-outline__metadata">
			<div card-outline-title i18n="@@general.metadata">metadata</div>
			<form [formGroup]="accessMetadataForm.form" card-outline-content>
				<mat-form-field [appearance]="Appearance">
					<mat-label i18n="@@exposition.metadata.form.label">label</mat-label>
					<input
						type="text"
						id="{{ cmpId + '-label-input' }}"
						matInput
						[formControlName]="AccessMetadataFormControls.Label" />
					<mat-error
						*ngIf="
							accessMetadataForm.form
								.get(AccessMetadataFormControls.Label)
								?.hasError(ValidationErrorKeys.Required)
						"
						i18n="@@general.form.errors.required">
						max length
					</mat-error>
				</mat-form-field>
				<mat-form-field [appearance]="Appearance">
					<mat-label i18n="@@exposition.metadata.form.description">
						description
					</mat-label>
					<textarea
						type="text"
						[rows]="4"
						id="{{ cmpId + '-description-input' }}"
						matInput
						[formControlName]="
							AccessMetadataFormControls.Description
						"></textarea>
				</mat-form-field>
				<div class="app-exposition-access-config__status">
					<mat-label i18n="@@exposition.metadata.form.status">status</mat-label>
					<div class="app-exposition-access-config__status-toggle">
						<mat-slide-toggle
							id="{{ cmpId + '-active-toggle' }}"
							color="primary"
							[formControlName]="
								AccessMetadataFormControls.IsActive
							"></mat-slide-toggle>
						<span
							*ngIf="
								accessMetadataForm.form.get(AccessMetadataFormControls.IsActive)
									?.value
							"
							i18n="@@exposition.metadata.form.active">
							active
						</span>
						<span
							*ngIf="
								!accessMetadataForm.form.get(
									AccessMetadataFormControls.IsActive
								)?.value
							"
							i18n="@@exposition.metadata.form.inactive">
							inactive
						</span>
					</div>
				</div>
			</form>
		</app-card-outline>

	<app-panel headerHeight="3rem"
		class="app-card-outline__columns"
		isAlwaysOpen="true"
		hasSearchableGrid="true"
		[actionsAvailable]="columnsGrid.selectionCount"
		[itemsCount]="vo.accessConfig.columnsConfig.elements.size"
		[actions]="accessColumnsActions"
		(search)="columnsGrid.search($event)">
		<span panel-title i18n="@@exposition.access.list.column.columns">
			columns
		</span>
		<div panel-info>
			<div *ngIf="columnsGrid.selectionCount">
				<span>{{ columnsGrid.selectionCount }}&#32;</span>
				<span i18n="@@expositions.columns.config.selectedElements">Elements selectionnée</span>
			</div>
			<ng-container *ngIf="vo.listErrors.size > 0 && vo.listErrors.has(ColumnConfigErrorKey.NoActiveColumns)">
				<div class="activated-columns-alert error">
					<mat-icon [svgIcon]="DcIcons.Warning"></mat-icon>
					<span	i18n="@@expositions.columns.config.alert.minOneActive">min one active column</span>
				</div>
			</ng-container>
		</div>
		<app-exposition-columns-config
			#columnsGrid="ColumnsGrid"
			[columnConfigList]="vo.accessConfig.columnsConfig"
			[isInAccessConfigMode]="true"
			(errors)="modifyColumnConfigValidity($event)"></app-exposition-columns-config>
	</app-panel>

	<div class="app-exposition-access-config-type">
			<mat-form-field class="app-exposition-access-config__access-type"  [appearance]="'outline'">
				<mat-label i18n="@@expositions.access.config.access">Access</mat-label>
				<mat-select
					[value]="vo.accessConfig.type"
					(selectionChange)="changeIsAccessLimited($event)"
					disableOptionCentering>
					<mat-option matTooltip="openAccessDisabledOption" i18n-matTooltip="@@openAccessDisabledOption" [matTooltipDisabled]="!vo.canNotCreateOpenAccess"
											matTooltipPosition="right"
						[disabled]="vo.canNotCreateOpenAccess" [value]="AccessType.Open">
						<mat-icon [svgIcon]="DcIcons.LockOpen"></mat-icon>
						<span i18n="@@expositions.access.config.open">open</span>
					</mat-option>
					<mat-option [value]="AccessType.Limited">
						<mat-icon [svgIcon]="DcIcons.Group"></mat-icon>
						<span i18n="@@expositions.access.config.limited">limited</span>
					</mat-option>
				</mat-select>
			</mat-form-field>
		<div  class="app-card-outline__consumers">
			<div
				*ngIf="vo.accessConfig.type === AccessType.Limited"
				class="app-exposition-access-config__search-consumers">
				<mat-form-field>
					<input
						matInput
						i18n-placeholder="@@expositions.access.config.searchUserOrGroup"
						placeholder="search user or group"
						[ngModel]="vo.searchInput"
						(ngModelChange)="onSearchInputChange($event)"
						[matAutocomplete]="auto"
						id="{{ cmpId }}-search-consumers-input" />
				</mat-form-field>
				<mat-autocomplete
					#auto="matAutocomplete"
				>
					<mat-option
						id="{{ cmpId }}-consumer-{{ i }}-option"
						*ngFor="let result of vo.filteredConsumers; let i = index"
						(onSelectionChange)="selected($event)"
						[value]="result.name">
						<span class="app-exposition-access-config__search-consumers-result">
							{{ result.name }}
							<mat-icon
								*ngIf="result.isGroup"
								[svgIcon]="DcIcons.Group"></mat-icon>
						</span>
					</mat-option>
				</mat-autocomplete>
			</div>
			<app-panel *ngIf="vo.accessConfig.type === AccessType.Limited"

								 headerHeight="3rem"
								 isAlwaysOpen="true"
								 hasSearchableGrid="true"
								 [actionsAvailable]="consumersGrid.selectionCount"
								 [itemsCount]="vo.accessConfig.consumers.elements.size"
								 [actions]="consumersActions"
								 (search)="consumersGrid.search($event)">
			<span panel-title i18n="@@expositions.access.config.consumers">
				consumers
			</span>
				<span
					*ngIf="!vo.accessConfig.consumers.elements.size"
					panel-info
					class="minimum-consumers-alert">
				<mat-icon [svgIcon]="DcIcons.Warning" [color]="'warn'"></mat-icon>
				<mat-error i18n="@@exposition.access.list.consumers.minimumConsumer">
					minimum 1 consumer
				</mat-error>
			</span>
				<app-exposition-consumer-list
					#consumersGrid="ConsumersGrid"
					[consumerList]="vo.accessConfig.consumers"
					(removeConsumers)="
					removeConsumers($event)
				"></app-exposition-consumer-list>
			</app-panel>
		</div>

		<div
			class="app-exposition-access-config__open-access"
			*ngIf="vo.accessConfig.type === AccessType.Open"
			i18n="@@expositions.access.config.openAccess.message">
			All referenced users can access the API.
		</div>
	</div>

	<app-card-outline class="app-card-outline__lines">
		<div card-outline-title class="app-card-outline__lines-title">
		<span i18n="@@expositions.access.config.lines">lines</span>
			<app-overlay-badge
				[icon]="ComponentIcons.Help"
				[isOpen]="vo.isOverlayInfoOpened"
				(overlayDismiss)="closeOverlayInfo()"
				(click)="openOverlayInfo()"
				[ngStyle]="{ '--bg-clr': '#FFF'}"
			>
				<app-popover
					overlayContent
					title="accepted syntax"
					i18n-title="@@expositions.access.config.lines.title"
					[icon]="{ iconName: ComponentIcons.Help }"
					[ngStyle]="{ '--popover-body-h': '15rem', '--popover-body-max-h': '15rem' ,'--popover-w': '30rem'}"
					(adbPopoverClose)="closeOverlayInfo()"
				>
			<div  class="app-card-outline__lines-overlay">
				<div class="app-card-outline__lines-syntax">
					<span i18n="@@expositions.access.config.lines.simpleSyntax" class="app-card-outline__lines-bold"> simple syntax</span> :
					<span i18n="@@expositions.access.config.lines.syntaxFormat">format</span>
				</div>
				<div class="app-card-outline__lines-operator-description">
					<span i18n="@@expositions.access.config.lines.groupOperator" class="app-card-outline__lines-operator">()</span>
					<span i18n="@@expositions.access.config.lines.groupOperator.description">description</span>
				</div>
				<div class="app-card-outline__lines-operator-description">
					<span i18n="@@expositions.access.config.lines.orOperator" class="app-card-outline__lines-operator">and</span>
					<span i18n="@@expositions.access.config.lines.orOperator.description">description</span>
				</div>
				<div class="app-card-outline__lines-operator-description">
					<span i18n="@@expositions.access.config.lines.andOperator" class="app-card-outline__lines-operator">and</span>
					<span i18n="@@expositions.access.config.lines.andOperator.description">description</span>
				</div>
				<div class="app-card-outline__lines-operator-description">
					<div>
						<span i18n="@@expositions.access.config.lines.doubleQuotes" class="app-card-outline__lines-operator">""</span>
						<span i18n="@@expositions.access.config.lines.or">or</span>
						<span i18n="@@expositions.access.config.lines.singleQuotes" class="app-card-outline__lines-operator">''</span>
					</div>
					<span i18n="@@expositions.access.config.lines.quotesOperator.description">description</span>
				</div>
				<div class="app-card-outline__lines-example">
				<span class="app-card-outline__lines-bold" i18n="@@expositions.access.config.lines.examples"> examples</span>
				<span class="app-card-outline__lines-example-details" i18n="@@expositions.access.config.lines.example-details"> example</span>
				</div>
			</div>
				</app-popover>
			</app-overlay-badge>
		</div>
		<div card-outline-content>
				<mat-form-field>
					<mat-label i18n="@@expositions.access.config.filter">
						filter
					</mat-label>
					<textarea
						matInput
						[ngModel]="vo.accessConfig.filter"
						(ngModelChange)="changeFilter($event)"></textarea>
				</mat-form-field>
			</div>
		</app-card-outline>
</div>
