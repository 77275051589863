import { Component } from '@angular/core';
import { combineAll, DcBaseComponent } from '@dc-common-core';
import { firstValueFrom, merge, Observable, of } from 'rxjs';

@Component({
	selector: 'app-card-outline',
	templateUrl: './app-card-outline.component.html',
	styleUrls: ['./app-card-outline.component.scss'],
	inputs: ['view'],
})
export class AppCardOutlineComponent extends DcBaseComponent {
	public state = true;
	public vo$: Observable<{
		view: boolean;
	}>;

	public constructor() {
		super();
		const view$ = merge(of(true), this.toObservable<boolean>('view'));

		this.vo$ = combineAll({
			view: view$,
		});
	}
	public async getState(): Promise<boolean> {
		return await firstValueFrom(this.toObservable<boolean>('view'));
	}
}
