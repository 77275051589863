import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { CORE, CoreState, IAppRootState } from './app.state';

@Injectable()
export class AppSelector {
	public constructor(private readonly store: Store<IAppRootState>) {}

	private get state(): Observable<CoreState> {
		return this.store.pipe(select((store) => store[CORE]));
	}
}
