import { createAction, props } from '@ngrx/store';

export enum CoreAction {
	SetUserAdminRole = '[AngularJS] Set User Admin Role',
}

export const setUserAdminRole = createAction(
	CoreAction.SetUserAdminRole,
	props<{ isAdmin: boolean }>()
);
