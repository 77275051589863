import { Injectable } from '@angular/core';
import { AppTagItemEntity, UuidService } from '@dc-common-core';
import { AvatarEntity, ColumnType } from '@dc-common-ui';
import { Map, OrderedMap, OrderedSet } from 'immutable';

import { ProjectStorage } from '../core/project.storage';
import { normalize } from '../core/utils/normalize';
import { IDcMetadataResponse } from '../requesters/core/metadata.model';
import { IViewMembers } from '../requesters/core/view-members.model';
import { IExpositionPublicationAccessConfig } from '../requesters/exposition/draft-publication.model';
import { IExpositionColumnConfig } from '../requesters/exposition/exposition-column-config.model';
import { IExpositionResponse } from '../requesters/exposition/exposition.model';
import {
	IConsumerForDatablockExposition,
	IEligibleForMigration,
	IMigratedExposition,
	IPreviousExpositionConsumer,
} from '../requesters/exposition/migrated-exposition.model';
import { IPublicMetadata } from '../requesters/exposition/public-metadata.model';
import {
	ActionType,
	HistoryStatus,
	IPublicationHistoryDetails,
} from '../requesters/exposition/publication-history.model';
import { IShareableDatablockColumn } from '../requesters/exposition/shareable-datablock-column.model';
import { IShareableDatablock } from '../requesters/exposition/shareable-datablock.model';
import { AccessConfigEntity } from './components/exposition-access-config/exposition-access-config.entity';
import { ExpositionAccessListEntity } from './components/exposition-access-list/exposition-access-list.entity';
import {
	AccessType,
	ExpositionAccessEntity,
} from './components/exposition-access-list/exposition-access.entity';
import { ExpositionColumnConfigListEntity } from './components/exposition-columns-config/exposition-column-config-list.entity';
import { ExpositionColumnConfigEntity } from './components/exposition-columns-config/exposition-column-config.entity';
import { ExpositionConsumerListEntity } from './components/exposition-consumers-list/exposition-consumer-list.entity';
import { ExpositionConsumerEntity } from './components/exposition-consumers-list/exposition-consumer.entity';
import { ExpositionDatablockColumnEntity } from './components/exposition-datablock-list/exposition-datablock-column.entity';
import { ExpositionDatablockListEntity } from './components/exposition-datablock-list/exposition-datablock-list.entity';
import { ExpositionDatablockEntity } from './components/exposition-datablock-list/exposition-datablock.entity';
import { ExpositionEndpointMetadataEntity } from './components/exposition-endpoint-metadata-config/exposition-endpoint-metadata.entity';
import {
	ExpositionHistoryItemEntity,
	HistoryActionType,
} from './components/exposition-history-list/exposition-history-item.entity';
import { ExpositionHistoryListEntity } from './components/exposition-history-list/exposition-history-list.entity';
import { ExpositionMetadataEntity } from './components/exposition-internal-metadata-config/exposition-metadata.entity';
import { ExpositionItemEntity } from './components/exposition-list/exposition-item.entity';
import { ExpositionListEntity } from './components/exposition-list/exposition-list.entity';
import { ExpositionPreviousConsumerEntity } from './components/exposition-migration-stepper/exposition-previous-consumer.entity';
import { MigrationReadyExpositionItemEntity } from './components/exposition-migration-stepper/migration-ready-exposition-item.entity';
import { MigrationReadyExpositionList } from './components/exposition-migration-stepper/migration-ready-exposition-list.entity';
import { MigratedExpositionItemEntity } from './components/exposition-migrations-list/migrated-exposition-item.entity';
import { MigratedExpositionListEntity } from './components/exposition-migrations-list/migrated-exposition-list.entity';
import { ExpositionEntity } from './components/exposition-view/exposition.entity';
import { PublicationStatus } from './components/publication-status.enum';
import { ExpositionHistoryEntity } from './components/published-exposition/exposition-history.entity';
import { PublishedExpositionEntity } from './components/published-exposition/published-exposition.entity';

@Injectable()
export class ExpositionsAdapter {
	public constructor(
		private readonly projectStorage: ProjectStorage,
		private readonly uuidService: UuidService
	) {}

	public generateExposition(response: IExpositionResponse): ExpositionEntity {
		const accessList = this.generateAccessList();
		const accessTuples = response.draft_publication?.access?.reduce(
			(acc, curr) => {
				const entity = this.generateAccessEntity(curr);
				acc.push([`${entity.id}`, entity]);
				return acc;
			},
			new Array<[string, ExpositionAccessEntity]>()
		);
		return ExpositionEntity.build({
			id: response.id,
			columns: this.generateExpositionColumns(
				response.draft_publication?.columns ?? []
			),
			accessPoints: accessList.setElements(Map(accessTuples)),
			metadata: response.metadata
				? this.buildExpositionMetadataEntity(response.metadata)
				: ExpositionMetadataEntity.build(),
			endpointMetadata: ExpositionEndpointMetadataEntity.build({
				accessPoint: response.draft_publication?.access_point ?? '',
				details: response.draft_publication?.details ?? '',
				title: response.draft_publication?.title ?? '',
				keywords: OrderedSet(
					response.draft_publication?.keywords?.map((keyword) =>
						AppTagItemEntity.buildKeyword({
							label: keyword,
						})
					) ?? []
				),
			}),
			published: PublishedExpositionEntity.build({
				id: response.id,
				status: response.status
					? this.getPublicationStatus(response.status)
					: PublicationStatus.Unknown,
				datablockId: response.datablock?.id ?? -1,
				datablockLabel: response.datablock?.label ?? '',
				datablockUrl: response.datablock?.id
					? this.parseDatablockPageUrl(response.datablock.id)
					: '',
				url: response.url,
				dataUrl: response.url
					? `${response.data_root_url ?? ''}${response.url}`
					: '',
				oDataUrl: response.url
					? `${response.odata_root_url ?? ''}${response.url}`
					: '',
				publicationDate: response.publication_date
					? new Date(response.publication_date).toString()
					: '',
				history: response.history?.map((h) =>
					ExpositionHistoryEntity.build({
						duration: h.duration ? h.duration / 1000 : 0,
						avatar: h.updated_by
							? AvatarEntity.build(
									`${h.updated_by.first_name} ${h.updated_by.last_name}`
							  )
							: AvatarEntity.build('user'),
						access: h.access,
						linesCount: h.nbr_of_lines,
						date: h.date ? new Date(h.date).toString() : '',
						status: h.status
							? this.getPublicationStatus(h.status)
							: PublicationStatus.Unknown,
						updateDescription: h.action
							? this.getExpositionUpdateDescription(h.action)
							: '',
					})
				),
				details: response.details,
				title: response.title,
				keywords:
					response.keywords?.map((keyword) =>
						AppTagItemEntity.buildKeyword({
							label: keyword,
						})
					) ?? [],
				dataCount: response.count,
				accessCount: response.access,
				publisherAvatar: AvatarEntity.build(
					`${response.last_updated_by?.first_name} ${response.last_updated_by?.last_name}`,
					2
				),
				columnsCount: response.nbr_of_cols ?? 0,
				hasActiveAccess:
					response.draft_publication?.access?.filter((a) => a.active).length !==
					0,
			}),
		});
	}

	public generateExpositionColumns(
		response: Array<IExpositionColumnConfig>
	): ExpositionColumnConfigListEntity {
		const columns = this.generateColumnConfigList();
		const columnsTuples = response.reduce((acc, curr) => {
			const entity = this.generateColumnEntity(curr);
			acc.push([entity.id, entity]);
			return acc;
		}, new Array<[string, ExpositionColumnConfigEntity]>());
		return columns.setElements(Map(columnsTuples)).setHashedAndHiddenColumns(
			response.map((response) => response.uuid ?? ''),
			[]
		);
	}

	public generateExpositionAccessEntity(
		response: IExpositionPublicationAccessConfig
	): AccessConfigEntity {
		return AccessConfigEntity.build({
			id: response.id,
			label: response.label,
			description: response.description,
			type: response.type === 'LIMITED' ? AccessType.Limited : AccessType.Open,
			isActive: response.active,
			consumers: response.members
				? this.generateConsumerList(response.members)
				: ExpositionConsumerListEntity.build([]),
			filter: response.filter,
			columnsConfig: response.columns
				? this.generateExpositionColumns(response.columns)
				: ExpositionColumnConfigListEntity.build([]),
		});
	}

	public generateExistingAccessEntity(
		response: IExpositionPublicationAccessConfig,
		columns: Array<IExpositionColumnConfig>
	): AccessConfigEntity {
		const expositionColumns = this.generateExpositionColumns(columns);
		const hashedColumns = expositionColumns.elements
			.filter((c) => c.isActive && c.isHashed)
			.toArray()
			.map(([id]) => id);
		const hiddenColumns = expositionColumns.elements
			.filter((c) => c.isActive && c.isHidden)
			.toArray()
			.map(([id]) => id);

		const cols = expositionColumns.elements
			.filter((c) => c.isActive)
			.toArray()
			.map(([, c]) => c.updateHash(false).updateIsActive(false));
		if (response.columns) {
			response.columns
				.filter((c) => c.active)
				.map((column) => {
					const col = cols.find((c) => c.id === column.uuid);
					if (col) {
						cols.push(
							col
								.updateIsActive(column.active ?? false)
								.updateHash(column.hash ?? false)
						);
					}
				});
		}

		return AccessConfigEntity.build({
			id: response.id,
			label: response.label,
			description: response.description,
			type: response.type === 'LIMITED' ? AccessType.Limited : AccessType.Open,
			isActive: response.active,
			consumers: response.members
				? this.generateConsumerList(response.members)
				: ExpositionConsumerListEntity.build([]),
			filter: response.filter,
			columnsConfig: expositionColumns
				.updateElements(cols)
				.setHashedAndHiddenColumns(hashedColumns, hiddenColumns),
		});
	}

	public generateNewAccessEntity(
		columns: Array<IExpositionColumnConfig>
	): AccessConfigEntity {
		const expositionColumns = this.generateExpositionColumns(columns);

		const hashedColumns = expositionColumns.elements
			.filter((c) => c.isActive && c.isHashed)
			.toArray()
			.map(([id]) => id);
		const hiddenColumns = expositionColumns.elements
			.filter((c) => c.isActive && c.isHidden)
			.toArray()
			.map(([id]) => id);

		return AccessConfigEntity.build({
			columnsConfig: expositionColumns
				.updateElements(
					expositionColumns.elements
						.filter((c) => c.isActive)
						.toArray()
						.map(([, c]) => c.updateHash(false))
				)
				.setHashedAndHiddenColumns(hashedColumns, hiddenColumns),
		});
	}

	public generateConsumerList(
		members: IViewMembers
	): ExpositionConsumerListEntity {
		const list = this.generateExpositionConsumerList();
		const consumers = this.generateConsumers(members);
		const consumersTuples = consumers.reduce((acc, curr) => {
			acc.push([`${curr.id}`, curr]);
			return acc;
		}, new Array<[string, ExpositionConsumerEntity]>());
		return list.setElements(Map(consumersTuples));
	}

	public buildExpositionMetadataEntity(
		response: IDcMetadataResponse
	): ExpositionMetadataEntity {
		const tags =
			response.tags?.map((t) =>
				AppTagItemEntity.build({
					id: t.id,
					label: t.code,
					color: t.color,
				})
			) ?? [];

		return ExpositionMetadataEntity.build({
			label: response.label,
			description: response.description,
			tags: OrderedSet(tags),
		});
	}

	public buildExpositionEndpointMetadata(
		response: IPublicMetadata
	): ExpositionEndpointMetadataEntity {
		const keywords =
			response.keywords?.map((keyword) =>
				AppTagItemEntity.buildKeyword({
					label: keyword,
				})
			) ?? [];

		return ExpositionEndpointMetadataEntity.build({
			accessPoint: response.access_point,
			details: response.details,
			title: response.title,
			keywords: OrderedSet(keywords),
		});
	}

	public buildDatablockList(
		response: ReadonlyArray<IShareableDatablock>
	): ExpositionDatablockListEntity {
		const list = this.generateExpositionDatablockList();
		const tuples = response.reduce((acc, curr) => {
			const entity = this.generateExpositionDatablockEntity(curr);
			acc.push([`${curr.id}`, entity]);
			return acc;
		}, new Array<[string, ExpositionDatablockEntity]>());
		return list.setElements(Map(tuples));
	}

	public generateDatablockColumns(
		response: ReadonlyArray<IShareableDatablockColumn>
	): Array<ExpositionDatablockColumnEntity> {
		return response.map((datablock) =>
			this.generateDatablockColumnEntity(datablock)
		);
	}

	public buildColumnConfigList(
		response: ReadonlyArray<IShareableDatablockColumn>
	): ExpositionColumnConfigListEntity {
		const list = this.generateColumnConfigList();
		const tuples = response.reduce((acc, curr) => {
			const entity = this.generateColumnConfigEntity(curr);
			acc.push([entity.id, entity]);
			return acc;
		}, new Array<[string, ExpositionColumnConfigEntity]>());
		return list.setElements(Map(tuples)).setHashedAndHiddenColumns(
			response.map((response) => response.uuid ?? ''),
			[]
		);
	}

	public generateConsumers(
		response: IViewMembers
	): Array<ExpositionConsumerEntity> {
		const consumers = new Array<ExpositionConsumerEntity>();
		response.users.forEach((user, index) => {
			const entity = ExpositionConsumerEntity.build({
				id: user.id,
				name: user?.name ?? `user-${index}`,
				isGroup: false,
			});
			consumers.push(entity);
		});

		response.groups.forEach((group, index) => {
			const entity = ExpositionConsumerEntity.build({
				id: group.id,
				name: group?.name ?? `group-${index}`,
				isGroup: true,
			});
			consumers.push(entity);
		});
		return consumers;
	}

	public generatePreviousExpositionConsumers(
		resp: IConsumerForDatablockExposition
	): { requestId: string; consumers: Array<ExpositionPreviousConsumerEntity> } {
		let previouslyKnowConsumers = resp.users.reduce<
			Array<ExpositionPreviousConsumerEntity>
		>((acc, curr) => {
			acc.push(this.adaptPreviousExpoUser(curr));
			return acc;
		}, []);

		previouslyKnowConsumers = resp.groups.reduce<
			Array<ExpositionPreviousConsumerEntity>
		>((acc, curr) => {
			acc.push(this.adaptPreviousExpoGroup(curr));
			return acc;
		}, previouslyKnowConsumers);
		return {
			consumers: previouslyKnowConsumers,
			requestId: resp.request_id,
		};
	}

	public generateList(
		resp: ReadonlyArray<IExpositionResponse>
	): ExpositionListEntity {
		const columns = this.generateExpositionList();
		const columnsTuples = resp.reduce((acc, curr) => {
			acc.push([`${curr.id}`, this.generateExpositionListItem(curr)]);
			return acc;
		}, new Array<[string, ExpositionItemEntity]>());
		return columns.setElements(Map(columnsTuples));
	}

	public generateReadyForMigrationExpositionList(
		resp: ReadonlyArray<IEligibleForMigration>
	): MigrationReadyExpositionList {
		const cols = MigrationReadyExpositionList.build([
			{
				field: 'label',
				alias: $localize`:i18n=@@exposition.migration.stepper.firstStep.grid.col1:`,
				type: 'string',
				visibleIndex: 0,
			},
			{
				field: 'accessPoint',
				alias: $localize`:i18n=@@exposition.migration.stepper.firstStep.grid.col2:`,
				type: 'string',
				visibleIndex: 1,
			},
		]);
		const columnsTuples = resp.reduce((acc, curr) => {
			acc.push([
				`${curr.id}`,
				MigrationReadyExpositionItemEntity.build({
					sourceDatablockId: curr.id,
					label: curr.label,
					accessPoint: curr.access_point,
					tags:
						curr?.tags?.map((t) =>
							AppTagItemEntity.build({
								id: t.id,
								label: t.code,
								color: t.color,
							})
						) ?? [],
				}),
			]);
			return acc;
		}, new Array<[string, MigrationReadyExpositionItemEntity]>());
		return cols.setElements(Map(columnsTuples));
	}

	public generateMigratedExpositionList(
		resp: ReadonlyArray<IMigratedExposition>
	): MigratedExpositionListEntity {
		const list = MigratedExpositionListEntity.build([
			{
				field: 'oldExpositionLabel',
				alias: $localize`:i18n=@@exposition.migrated.grid.col1:`,
				type: 'string',
				visibleIndex: 0,
			},
			{
				field: 'newExpositionLabel',
				alias: $localize`:i18n=@@exposition.migrated.grid.col2:`,
				type: 'string',
				visibleIndex: 1,
			},
			{
				field: 'migrationDate',
				alias: $localize`:i18n=@@exposition.migrated.grid.col3:`,
				type: 'date',
				visibleIndex: 2,
			},
		]);
		const tuples = resp.reduce((acc, curr, idx) => {
			const entity = MigratedExpositionItemEntity.build({
				id: idx,
				oldExpositionLabel: curr?.old_exposition_label ?? 'old',
				newExpositionLabel: curr?.new_exposition_label ?? 'new',
				newExpositionId: curr?.new_exposition_id ?? '0',
				oldExpositionId: curr?.old_exposition_id ?? '0',
				migrationDate: new Date(curr?.migration_date ?? ''),
			});
			acc.push([`${entity.newExpositionId}`, entity]);
			return acc;
		}, new Array<[string, MigratedExpositionItemEntity]>());
		return list.setElements(Map(tuples));
	}

	public generateExpositionHistoryList(
		response: Array<IPublicationHistoryDetails>
	): ExpositionHistoryListEntity {
		const list = ExpositionHistoryListEntity.build([
			{
				field: 'status',
				alias: $localize`:i18n=@@expositions.history.list.column.status:`,
				type: '',
				isSearchable: false,
				visibleIndex: 0,
				hasHeaderFilter: true,
			},
			{
				field: 'actionDescription',
				alias: $localize`:i18n=@@expositions.history.list.column.action:`,
				type: 'string',
				isSearchable: true,
				visibleIndex: 1,
				hasHeaderFilter: true,
			},
			{
				field: 'columns',
				alias: $localize`:i18n=@@expositions.history.list.column.config:`,
				type: '',
				isSearchable: false,
				visibleIndex: 2,
			},
			{
				field: 'dataEntries',
				alias: $localize`:i18n=@@expositions.history.list.column.lines:`,
				type: '',
				isSearchable: false,
				visibleIndex: 3,
			},
			{
				field: 'date',
				alias: $localize`:i18n=@@expositions.history.list.column.date:`,
				type: '',
				isSearchable: true,
				visibleIndex: 4,
			},
			{
				field: 'duration',
				alias: $localize`:i18n=@@expositions.history.list.column.duration:`,
				type: '',
				isSearchable: true,
				visibleIndex: 5,
				hasHeaderFilter: false,
			},
			{
				field: 'avatar',
				alias: $localize`:i18n=@@expositions.history.list.column.triggeredBy:`,
				type: '',
				isSearchable: false,
				visibleIndex: 6,
				hasHeaderFilter: false,
			},
		]);

		const tuples = response.reduce((acc, curr) => {
			const entity = ExpositionHistoryItemEntity.build({
				id: curr.id ?? -1,
				access: curr.access_list ?? [],
				columns: curr.columns
					? curr.columns
							.filter((c) => c.active)
							.map((c) => ({
								label: c.label ?? '',
								type: c.type ? this.getColumnType(c.type) : ColumnType.Unknown,
								isList: c.list ?? false,
							}))
					: [],
				actionDescription: curr.action
					? this.getExpositionUpdateDescription(curr.action)
					: '',
				action: curr.action
					? this.getHistoryActionType(curr.action)
					: HistoryActionType.Unknown,
				duration: curr.duration ? curr.duration / 1000 : 0,
				avatar: curr.updated_by
					? AvatarEntity.build(
							`${curr.updated_by.first_name} ${curr.updated_by.last_name}`
					  )
					: AvatarEntity.build('user'),
				dataEntries: curr.nbr_of_lines,
				date: curr.date ? new Date(curr.date).toString() : '',
				status: curr.status
					? this.getPublicationStatus(curr.status)
					: PublicationStatus.Unknown,
				endPointMetadata: ExpositionEndpointMetadataEntity.build({
					accessPoint: curr.public_metadata?.access_point,
					title: curr.public_metadata?.title,
					details: curr.public_metadata?.details,
					keywords: OrderedSet(
						curr.public_metadata?.keywords?.map((keyword) =>
							AppTagItemEntity.buildKeyword({
								label: keyword,
							})
						) ?? []
					),
				}),
			});
			acc.push([`${entity.id}`, entity]);
			return acc;
		}, new Array<[string, ExpositionHistoryItemEntity]>());
		return list.setElements(
			OrderedMap(tuples.sort(([, e1], [, e2]) => e2.id - e1.id))
		);
	}

	private adaptPreviousExpoUser(
		pUser: IPreviousExpositionConsumer
	): ExpositionPreviousConsumerEntity {
		if (pUser.id === undefined) {
			console.error('retrieved a user with no id, generating random uuid');
			return ExpositionPreviousConsumerEntity.build({
				id: this.uuidService.uuidV4(),
				targetConsumerId: null,
				fullName: `${pUser.first_name} ${pUser.last_name}`,
				unRecognized: true,
				description: pUser?.name ?? '',
				isGroup: false,
			});
		}
		return ExpositionPreviousConsumerEntity.build({
			id: pUser.id,
			targetConsumerId: null,
			fullName: `${pUser.first_name} ${pUser.last_name}`,
			unRecognized: !!(!pUser.enabled || pUser.unknown),
			description: pUser?.name ?? '',
			isGroup: false,
		});
	}

	private adaptPreviousExpoGroup(
		pGroup: IPreviousExpositionConsumer
	): ExpositionPreviousConsumerEntity {
		if (pGroup.id === undefined) {
			console.error('retrieved a group with no id, generating random uuid');
			return ExpositionPreviousConsumerEntity.build({
				id: this.uuidService.uuidV4(),
				targetConsumerId: null,
				fullName: `${pGroup.label}`,
				unRecognized: true,
				description: pGroup?.description ?? '',
				isGroup: true,
			});
		}
		return ExpositionPreviousConsumerEntity.build({
			id: pGroup.id,
			targetConsumerId: null,
			fullName: `${pGroup.label}`,
			unRecognized: !!(!pGroup.enabled || pGroup.unknown),
			description: pGroup?.description ?? '',
			isGroup: true,
		});
	}

	private generateExpositionListItem(
		resp: IExpositionResponse
	): ExpositionItemEntity {
		const tags =
			resp.metadata?.tags?.map((t) =>
				AppTagItemEntity.build({
					id: t.id,
					label: t.code,
					color: t.color,
				})
			) ?? [];

		const expositionActionBy = AvatarEntity.build(
			`${resp.draft_publication?.drafted_by?.first_name} ${resp.draft_publication?.drafted_by?.last_name}`,
			2
		);
		const elemModifiedBy = AvatarEntity.build(
			`${resp.last_updated_by?.first_name} ${resp.last_updated_by?.last_name}`,
			2
		);
		return ExpositionItemEntity.build({
			id: resp.id,
			label: `${resp.metadata?.label}`,
			expositionUrl: resp?.url ?? '',
			sourceUrl: resp.datablock?.id
				? this.parseDatablockPageUrl(resp.datablock.id)
				: '',
			tags: OrderedSet(tags),
			datablockSource: resp?.datablock?.label ?? '',
			apiStatus: resp.status
				? this.getPublicationStatus(resp.status)
				: PublicationStatus.Unknown,
			columnsCount: resp.nbr_of_cols ?? 0,
			dataEntries: resp?.count ?? 0,
			accessCount: resp.draft_publication?.access?.length ?? 0,
			duration: resp?.duration_ms ? `${resp?.duration_ms}` : '0',
			publishState: this.getPublicationStatus(
				resp.draft_publication?.status ?? HistoryStatus.WAITING
			),
			accessPointLabel: resp?.draft_publication?.access_point ?? '',
			accessPointUrl: resp?.url ?? '',
			lastExpositionActionBy: expositionActionBy,
			lastElemModificationBy: elemModifiedBy,
			lastElemModification: resp.metadata?.update_date
				? new Date(resp.metadata.update_date)
				: null,
			lastExpositionAction: resp.history
				? resp.history.length > 0
					? resp.history[0].date
					: null
				: null,
		});
	}

	private generateExpositionList(): ExpositionListEntity {
		return ExpositionListEntity.build([
			{
				field: 'label',
				alias: $localize`:i18n=@@expositions.list.column.label:`,
				type: '',
				isSearchable: true,
				hasHeaderFilter: false,
				visibleIndex: 0,
			},
			{
				field: 'datablockSource',
				alias: $localize`:i18n=@@expositions.list.column.source:`,
				type: '',
				isSearchable: true,
				isFilterable: true,
				visibleIndex: 1,
			},
			{
				field: 'lastElemModification',
				alias: $localize`:i18n=@@expositions.list.column.lastMod:`,
				type: 'date',
				format: 'yyyy-MM-dd HH:mm',
				isSearchable: true,
				visibleIndex: 2,
			},
			{
				field: 'lastElemModificationBy',
				alias: '',
				type: '',
				isSearchable: false,
				hasHeaderFilter: false,
				visibleIndex: 3,
			},
			{
				field: 'apiStatus',
				alias: $localize`:i18n=@@expositions.list.column.APIStatus:`,
				type: '',
				isSearchable: true,
				visibleIndex: 4,
			},
			{
				field: 'accessPointLabel',
				alias: $localize`:i18n=@@expositions.list.column.accessPoint:`,
				type: '',
				isSearchable: true,
				hasHeaderFilter: false,
				visibleIndex: 5,
			},
			{
				field: 'columnsCount',
				alias: $localize`:i18n=@@expositions.list.column.configuration:`,
				type: '',
				isSearchable: true,
				hasHeaderFilter: false,
				visibleIndex: 6,
			},
			{
				field: 'accessCount',
				alias: '',
				type: '',
				isSearchable: true,
				hasHeaderFilter: false,
				visibleIndex: 7,
			},
			{
				field: 'dataEntries',
				alias: $localize`:i18n=@@expositions.list.column.lines:`,
				type: '',
				isSearchable: true,
				hasHeaderFilter: false,
				visibleIndex: 8,
			},
			{
				field: 'duration',
				alias: $localize`:i18n=@@expositions.list.column.duration:`,
				type: '',
				isSearchable: true,
				hasHeaderFilter: false,
				visibleIndex: 10,
			},
			{
				field: 'publishState',
				alias: $localize`:i18n=@@expositions.list.column.status:`,
				type: '',
				isSearchable: true,
				visibleIndex: 9,
			},
			{
				field: 'lastExpositionAction',
				alias: $localize`:i18n=@@expositions.list.column.lastPublicationUpdate:`,
				isSearchable: true,
				type: 'date',
				format: 'yyyy-MM-dd HH:mm',
				visibleIndex: 11,
			},
			{
				field: 'lastExpositionActionBy',
				alias: '',
				isSearchable: false,
				type: '',
				hasHeaderFilter: false,
				visibleIndex: 12,
			},
		]);
	}

	private generateColumnConfigList(): ExpositionColumnConfigListEntity {
		return ExpositionColumnConfigListEntity.build([
			{
				field: 'columnType',
				alias: '',
				type: '',
				isSearchable: false,
				visibleIndex: 0,
			},
			{
				field: 'label',
				alias: $localize`:@@exposition.columns.config.column.label:`,
				type: 'string',
				isSearchable: true,
				visibleIndex: 1,
			},
			{
				field: 'alias',
				alias: $localize`:@@exposition.columns.config.column.alias:`,
				type: 'string',
				isSearchable: true,
				visibleIndex: 2,
			},
			{
				field: 'isActive',
				alias: $localize`:@@exposition.columns.config.column.active:`,
				type: 'boolean',
				isSearchable: false,
				visibleIndex: 3,
			},
			{
				field: 'description',
				alias: $localize`:@@exposition.columns.config.column.description:`,
				type: 'string',
				isSearchable: true,
				visibleIndex: 4,
			},

			{
				field: 'isPrimaryKey',
				alias: $localize`:@@exposition.columns.config.column.id:`,
				type: 'boolean',
				isSearchable: false,
				visibleIndex: 5,
			},
			{
				field: 'isFiltered',
				alias: $localize`:@@exposition.columns.config.column.filterable:`,
				type: 'boolean',
				isSearchable: false,
				visibleIndex: 6,
			},
			{
				field: 'isCaseSensitive',
				alias: $localize`:@@exposition.columns.config.column.caseSensitive:`,
				type: 'boolean',
				isSearchable: false,
				visibleIndex: 7,
			},
			{
				field: 'isHashed',
				alias: $localize`:@@exposition.columns.config.column.hashable:`,
				type: 'boolean',
				isSearchable: false,
				visibleIndex: 8,
			},
			{
				field: 'isHidden',
				alias: $localize`:@@exposition.columns.config.column.hide:`,
				type: 'boolean',
				isSearchable: false,
				visibleIndex: 9,
			},
		]);
	}

	private generateExpositionDatablockList(): ExpositionDatablockListEntity {
		return ExpositionDatablockListEntity.build([
			{
				field: 'label',
				alias: $localize`:i18n=@@exposition.datablock.list.column.label:`,
				type: 'string',
				isSearchable: true,
			},
			{
				field: 'columnsCount',
				alias: $localize`:i18n=@@exposition.datablock.list.column.columns:`,
				type: 'number',
				isSearchable: false,
			},
			{
				field: 'updateDate',
				alias: $localize`:i18n=@@exposition.datablock.list.column.lastUpdate:`,
				type: 'date',
				format: 'yyyy-MM-dd HH:mm',
				isSearchable: false,
			},
			{
				field: 'avatar',
				alias: '',
				type: '',
				isSearchable: false,
			},
		]);
	}

	private generateExpositionDatablockEntity(
		response: IShareableDatablock
	): ExpositionDatablockEntity {
		return ExpositionDatablockEntity.build({
			id: response.id,
			label: response.label,
			updateDate: new Date(response.last_update ?? ''),
			url: response.id ? this.parseDatablockPageUrl(response.id) : '',
			tags: response.tags?.map((t) =>
				AppTagItemEntity.build({
					id: t.id,
					label: t.code,
					color: t.color,
				})
			),
			columnsCount: response.column_count,
			avatar: AvatarEntity.build(
				`${response.last_update_by?.first_name} ${response.last_update_by?.last_name}`
			),
		});
	}

	private generateDatablockColumnEntity(
		response: IShareableDatablockColumn
	): ExpositionDatablockColumnEntity {
		return ExpositionDatablockColumnEntity.build({
			label: response.lib,
			type: response.type
				? this.getColumnType(response.type)
				: ColumnType.Unknown,
			isList: response.is_list,
		});
	}

	private generateColumnConfigEntity(
		response: IShareableDatablockColumn
	): ExpositionColumnConfigEntity {
		return ExpositionColumnConfigEntity.build({
			id: response.uuid,
			label: response.lib,
			columnType: response.type
				? this.getColumnType(response.type)
				: ColumnType.Unknown,
			alias: response.lib ? normalize(response.lib) : '',
			isList: response.is_list,
		});
	}

	public generateExpositionConsumerList(): ExpositionConsumerListEntity {
		return ExpositionConsumerListEntity.build([
			{
				field: 'avatar',
				alias: '',
				type: '',
				isSearchable: false,
				visibleIndex: 0,
			},
			{
				field: 'name',
				alias: $localize`:@@expositions.access.config.userOrGroupName:`,
				type: 'string',
				isSearchable: true,
				visibleIndex: 1,
			},
		]);
	}

	private parseDatablockPageUrl(datablockId: number): string {
		const projectId = this.projectStorage.getValue()?.projectId;
		return `/#/project/${projectId}/datablocks/edit/${datablockId}`;
	}

	private parseExpositionPageUrl(expositionId: number): string {
		const projectId = this.projectStorage.getValue()?.projectId;
		return `/#/project/${projectId}/exposition/${expositionId}/view`;
	}

	private getColumnType(type: string): ColumnType {
		switch (type.toLowerCase()) {
			case 'string':
				return ColumnType.Str;
			case 'file':
				return ColumnType.File;
			case 'double':
				return ColumnType.Double;
			case 'decimal':
				return ColumnType.Dec;
			case 'date':
				return ColumnType.Date;
			case 'big_integer':
				return ColumnType.BigInt;
			case 'binary':
				return ColumnType.Bin;
			case 'words':
				return ColumnType.Words;
			case 'integer':
				return ColumnType.Int;
			case 'boolean':
				return ColumnType.Bool;
			case 'geometry':
				return ColumnType.Geo;
			default:
				return ColumnType.Unknown;
		}
	}

	private getPublicationStatus(status: HistoryStatus): PublicationStatus {
		switch (status) {
			case HistoryStatus.UNPUBLISHED:
				return PublicationStatus.Unpublished;
			case HistoryStatus.AVAILABLE:
				return PublicationStatus.Available;
			case HistoryStatus.UNAVAILABLE:
				return PublicationStatus.Unavailable;
			case HistoryStatus.WAITING:
				return PublicationStatus.Waiting;
			case HistoryStatus.RUNNING:
				return PublicationStatus.Running;
			case HistoryStatus.SUCCESS:
				return PublicationStatus.Success;
			case HistoryStatus.ERROR:
				return PublicationStatus.Error;
			default:
				return PublicationStatus.Unpublished;
		}
	}

	private getExpositionUpdateDescription(update: ActionType): string {
		switch (update) {
			case ActionType.UPDATE_PUBLIC_METADATA:
				return $localize`:i18n=@@expositions.publication.api.updateType.configuration:`;
			case ActionType.UPDATE_ACCESS:
				return $localize`:i18n=@@expositions.publication.api.updateType.configuration:`;
			case ActionType.UPDATE_COLUMNS:
				return $localize`:i18n=@@expositions.publication.api.updateType.configuration:`;
			case ActionType.UPDATE_DATA:
				return $localize`:i18n=@@expositions.publication.api.updateType.data:`;
			case ActionType.UPDATE_CONF:
				return $localize`:i18n=@@expositions.publication.api.updateType.configuration:`;
			case ActionType.PUBLISH_DATA:
				return $localize`:i18n=@@expositions.publication.api.updateType.publish:`;
			case ActionType.CLEAR_DATA:
				return $localize`:i18n=@@expositions.publication.api.updateType.clear:`;
			case ActionType.ENABLE:
				return $localize`:i18n=@@expositions.publication.api.updateType.activate:`;
			case ActionType.DISABLE:
				return $localize`:i18n=@@expositions.publication.api.updateType.deactivate:`;
			default:
				return '';
		}
	}

	private getHistoryActionType(update: ActionType): HistoryActionType {
		switch (update) {
			case ActionType.UPDATE_PUBLIC_METADATA:
				return HistoryActionType.UpdateConfig;
			case ActionType.UPDATE_ACCESS:
				return HistoryActionType.UpdateConfig;
			case ActionType.UPDATE_COLUMNS:
				return HistoryActionType.UpdateConfig;
			case ActionType.UPDATE_DATA:
				return HistoryActionType.UpdateData;
			case ActionType.UPDATE_CONF:
				return HistoryActionType.UpdateConfig;
			case ActionType.PUBLISH_DATA:
				return HistoryActionType.Publish;
			case ActionType.CLEAR_DATA:
				return HistoryActionType.ClearData;
			case ActionType.ENABLE:
				return HistoryActionType.Activate;
			case ActionType.DISABLE:
				return HistoryActionType.Deactivate;
			default:
				return HistoryActionType.Unknown;
		}
	}

	private generateColumnEntity(
		response: IExpositionColumnConfig
	): ExpositionColumnConfigEntity {
		return ExpositionColumnConfigEntity.build({
			id: response.uuid,
			label: response.label,
			description: response.description,
			columnType: response.type
				? this.getColumnType(response.type)
				: ColumnType.Unknown,
			alias: response.alias ?? normalize(response.label ?? ''),
			isList: response.list,
			isActive: response.active,
			isHashed: response.hash,
			isFiltered: response.filter,
			isCaseSensitive: response.case_insensitive,
			isPrimaryKey: response.id,
			isHidden: response.hidden,
		});
	}

	private generateAccessEntity(
		response: IExpositionPublicationAccessConfig
	): ExpositionAccessEntity {
		return ExpositionAccessEntity.build({
			id: response.id,
			label: response.label,
			isActive: response.active,
			accessType:
				response.type === 'LIMITED' ? AccessType.Limited : AccessType.Open,
			usersCount: response.members?.users.length,
			groupsCount: response.members?.groups.length,
			columnsCount: response.columns?.filter((c) => c.active).length,
			columns: response.columns
				? response.columns
						.filter((c) => c.active)
						.map((c) => ({
							label: c.label ?? '',
							type: c.type ? this.getColumnType(c.type) : ColumnType.Unknown,
						}))
				: [],
			users: response.members?.users.map((user) => ({
				label: `${user.name}`,
				avatar: AvatarEntity.build(`${user.name}`, 2),
			})),
			groups: response.members?.groups.map((group) => ({
				label: `${group.name}`,
				avatar: AvatarEntity.build(`${group.name}`, 1),
			})),
			lines: response.filter,
		});
	}

	private generateAccessList(): ExpositionAccessListEntity {
		return ExpositionAccessListEntity.build([
			{
				field: 'label',
				alias: $localize`:i18n=@@exposition.access.list.column.label:`,
				type: 'string',
				isSearchable: true,
				visibleIndex: 0,
			},
			{
				field: 'accessType',
				alias: $localize`:i18n=@@exposition.access.list.column.accessType:`,
				type: '',
				isSearchable: false,
				visibleIndex: 1,
			},
			{
				field: 'users',
				alias: $localize`:i18n=@@exposition.access.list.column.consumers:`,
				type: '',
				isSearchable: false,
				visibleIndex: 2,
			},
			{
				field: 'columns',
				alias: $localize`:i18n=@@exposition.access.list.column.columns:`,
				type: 'number',
				isSearchable: false,
				visibleIndex: 3,
			},
			{
				field: 'lines',
				alias: $localize`:i18n=@@exposition.access.list.column.lines:`,
				type: '',
				isSearchable: false,
				visibleIndex: 4,
			},
		]);
	}
}
