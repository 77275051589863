import { Component, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DcBaseComponent } from '@dc-common-core';
import { AppTagItemEntity, combineAll, ModalData } from '@dc-common-ui';
import { Store } from '@ngrx/store';
import { OrderedSet } from 'immutable';
import { BehaviorSubject, Observable } from 'rxjs';

import { DcIcons } from '../../../ui/app-dc.icons';
import {
	ExpositionsSelector,
	initExpositionMetadataConfig,
	saveExpositionMetadata,
} from '../../store';
import { ExpositionInternalMetadataConfigComponent } from '../exposition-internal-metadata-config/exposition-internal-metadata.config.component';
import { ExpositionMetadataEntity } from '../exposition-internal-metadata-config/exposition-metadata.entity';

@Component({
	selector: 'app-exposition-metadata-popup',
	templateUrl: './exposition-metadata-popup.component.html',
	styleUrls: ['./exposition-metadata-popup.component.scss'],
})
export class ExpositionMetadataPopupComponent<
	T extends { expositionId: number }
> extends DcBaseComponent {
	public readonly Icons = DcIcons;
	public isValid$ = new BehaviorSubject<boolean>(true);
	public vo$: Observable<{
		metadata: ExpositionMetadataEntity;
		availableTags: OrderedSet<AppTagItemEntity>;
		isValid: boolean;
		canEdit: boolean;
	}>;

	@ViewChild(ExpositionInternalMetadataConfigComponent)
	public expositionMetadataCmp: ExpositionInternalMetadataConfigComponent | null =
		null;
	public constructor(
		private readonly store: Store,
		private readonly expositionsSelector: ExpositionsSelector,
		@Inject(MAT_DIALOG_DATA) public readonly modalData: ModalData & T
	) {
		super();
		this.cmpId = 'edit-exposition-metadata';

		this.store.dispatch(
			initExpositionMetadataConfig({
				expositionId: this.modalData.expositionId,
			})
		);

		this.vo$ = combineAll({
			metadata: this.expositionsSelector.getMetadata$(),
			availableTags: this.expositionsSelector.getAvailableTags$(),
			isValid: this.isValid$,
			canEdit: this.expositionsSelector.getCanEditExposition$(),
		});
	}

	public checkValidity(): void {
		if (this.expositionMetadataCmp) {
			this.isValid$.next(
				this.expositionMetadataCmp.internalMetadataForm.form.valid
			);
		}
	}

	public async save(): Promise<void> {
		if (this.expositionMetadataCmp) {
			const metadata = await this.expositionMetadataCmp.extractFormData();
			this.store.dispatch(
				saveExpositionMetadata({
					expositionId: this.modalData.expositionId,
					metadata,
				})
			);
		}
	}
}
