import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import {
	openCreateNewExpositionModal,
	openEditExpositionApiModal,
	openEditExpositionColumnsModal,
	openInitNewExpositionMigrationModal,
} from './store';

@Injectable()
export class NgExpositions {
	public constructor(private readonly store: Store) {}

	public openCreateNewExpositionModal(): void {
		this.store.dispatch(openCreateNewExpositionModal());
	}

	public openInitNewExpositionMigration(): void {
		this.store.dispatch(openInitNewExpositionMigrationModal());
	}

	public openEditExpositionColumnsModal(expositionId: number): void {
		this.store.dispatch(
			openEditExpositionColumnsModal({
				expositionId,
			})
		);
	}

	public openEditExpositionApiModal(
		expositionId: number,
		accessId: string
	): void {
		this.store.dispatch(
			openEditExpositionApiModal({
				expositionId,
				accessId,
			})
		);
	}
}
