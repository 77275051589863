import { downgradeInjectable, UpgradeModule } from '@angular/upgrade/static';
import Keycloak, { KeycloakConfig, KeycloakProfile } from 'keycloak-js';
import {
	AngularJSToken,
	DcBaseInitializationService,
	WINDOW,
	UuidService,
	BuildEnv,
	CommonInitializationService,
} from '@dc-common-core';

import * as _angular_ from 'angular';
import * as $ from 'jquery';
import { environment } from '../environments/environment';
import { ProjectStorage } from './core/project.storage';
import { NgExportTemplate } from './export-template/ng-export-template';
import { NgExpositions } from './expositions/ng-expositions';

declare global {
	const angular: typeof _angular_;
}

function differentialModuleBootstrapping() {
	const buildNumber =
		DcBaseInitializationService.getConfigInstance().buildNumber;
	if (buildNumber === BuildEnv.Dev) {
		const mdl = ['demosModule'];
		angular.module('dcApp').requires.push(...mdl);
		return;
	}
}

function bootstrap(upgrade: UpgradeModule): void {
	differentialModuleBootstrapping();
	upgrade.bootstrap(document.body, ['dcApp'], {
		strictDi: true,
	});
}

function authenticate(kc: KeycloakConfig): Promise<Keycloak> {
	return new Promise(function (resolve, reject) {
		// @ts-ignore
		const keycloak = new Keycloak(kc);
		keycloak
			.init({
				onLoad: 'login-required',
				pkceMethod: 'S256',
				checkLoginIframe: false,
			})
			.then(() => {
				keycloak
					.loadUserProfile()
					.then((profile: KeycloakProfile) => resolve(keycloak), reject)
					.catch(console.error);
			});

		keycloak.onTokenExpired = function () {
			console.log('token expired', keycloak.token);
			keycloak.updateToken(60).then(
				() => {
					console.log('successfully get a new token', keycloak.token);
				},
				() => {
					console.log('error get a new token');
				}
			);
		};
	});
}

function bootstrapWithConfig(upgrade: UpgradeModule): void {
	angular.module('dcApp').config([
		'IdleProvider',
		'KeepaliveProvider',
		'TitleProvider',
		function (IdleProvider: any, KeepaliveProvider: any, TitleProvider: any) {
			KeepaliveProvider.interval(1); // in seconds
			TitleProvider.enabled(false);
		},
	]);
	bootstrap(upgrade);
}

function setKcAjsConstants(kcConfig: any): void {
	angular.module('dcApp').constant('PROJECT_ROLE_NAME', kcConfig.projectRole);
	angular.module('dcApp').constant('ADMIN_ROLE_NAME', kcConfig.adminRole);
	angular.module('dcApp').constant('USER_ROLE_NAME', kcConfig.userRole);
	angular.module('dcApp').constant('MEMBER_ROLE_NAME', kcConfig.memberRole);
	angular
		.module('dcApp')
		.constant('BACKEND_CLIENT_ID', kcConfig.backendClientId);
}

function shouldNotAuthenticate(window: Window): boolean {
	const excludedUrls = ['#/data/', '#/public/'];
	return excludedUrls.reduce<boolean>(
		(acc, curr) => acc || window.location.hash.startsWith(curr),
		false
	);
}

async function setEnvConfig(): Promise<unknown> {
	return new Promise(function (resolve, reject) {
		$.getJSON('./src/components/base/constants/versions.json', (conf: any) => {
			angular.module('dcApp').constant('APP_VERSION', conf.version);
			angular.module('dcApp').constant('APP_BUILD', conf.build);
			angular.module('dcApp').constant('APP_BUILD_DATE', conf.buildDate);

			// derives if debug is enabled based on build number, version and environment
			// debug is enabled by default in dev mode
			// this step should be performed before enabling/disabling logging
			DcBaseInitializationService.updateConfig(
				DcBaseInitializationService.getConfigInstance().setBuildNumber(
					conf.build,
					conf.version,
					environment.enableDebug
				)
			);

			// enables/disables logging based on currently known state of DcBaseInitializationService service
			angular.module('dcApp').config([
				'$logProvider',
				'$provide',
				function ($logProvider: any, $provide: any) {
					$provide.decorator('$log', [
						'$delegate',
						function ($delegate: any) {
							var origInfo = $delegate.info;
							var origLog = $delegate.log;
							var origWarn = $delegate.warn;

							const debugEnabled =
								DcBaseInitializationService.getConfigInstance().isDebugEnabled;

							console.info = debugEnabled ? console.info : () => {};
							console.debug = debugEnabled ? console.debug : () => {};
							console.warn = debugEnabled ? console.warn : () => {};

							$delegate.info = function () {
								if (debugEnabled) origInfo.apply(null, arguments);
							};

							$delegate.log = function () {
								if (debugEnabled) origLog.apply(null, arguments);
							};

							$delegate.warn = function () {
								if (debugEnabled) origWarn.apply(null, arguments);
							};

							return $delegate;
						},
					]);
				},
			]);
		}).then(resolve, () => reject('could not query "versions.json" file'));
	});
}

function exposeNgServicesToAjs(): void {
	angular
		.module('dcApp')
		.factory('ProjectStorage', downgradeInjectable(ProjectStorage) as any)
		.factory('NgExportTemplate', downgradeInjectable(NgExportTemplate) as any)
		.factory('NgExpositions', downgradeInjectable(NgExpositions) as any)
		.factory('UuidService', downgradeInjectable(UuidService) as any)
		.factory(
			'CommonInitializationService',
			downgradeInjectable(CommonInitializationService) as any
		);
}

async function initApp(upgrade: UpgradeModule, window: Window): Promise<void> {
	exposeNgServicesToAjs();
	await setEnvConfig();
	$.getJSON(
		'./src/components/base/constants/keycloak.json',
		(kcConfig: any) => {
			setKcAjsConstants(kcConfig);

			if (shouldNotAuthenticate(window)) {
				setTimeout(() => bootstrap(upgrade), 160);
				return;
			}

			return authenticate(kcConfig).then(
				(kc) => {
					// @ts-ignore
					window._keycloak = kc;
					bootstrapWithConfig(upgrade);
				},
				(reason) =>
					console.error(`something went wrong during authentication: ${reason}`)
			);
		}
	);
}

export const angularJSProvider = {
	provide: AngularJSToken,
	useFactory: initApp,
	deps: [UpgradeModule, WINDOW],
	multi: true,
};
