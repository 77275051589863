import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { ExpositionMetadataEntity } from './exposition-metadata.entity';

export enum InternalMetadataFormControls {
	Id = 'id',
	Label = 'label',
	Description = 'description',
}

@Injectable()
export class ExpositionInternalMetadataForm {
	public form: FormGroup;

	public constructor(protected readonly fb: FormBuilder) {
		this.form = this.fb.group({
			[InternalMetadataFormControls.Id]: [
				{
					value: -1,
					disabled: true,
				},
			],
			[InternalMetadataFormControls.Label]: [
				'',
				[Validators.required, Validators.maxLength(256)],
			],
			[InternalMetadataFormControls.Description]: [
				'',
				[Validators.maxLength(2500)],
			],
		});
	}

	public extract(): Partial<ExpositionMetadataEntity> {
		const formValues = this.form.value;
		return ExpositionMetadataEntity.build({
			id: formValues[InternalMetadataFormControls.Id] ?? -1,
			label: formValues[InternalMetadataFormControls.Label] ?? '',
			description: formValues[InternalMetadataFormControls.Description] ?? '',
		});
	}

	public populate(config: Partial<ExpositionMetadataEntity> | null): void {
		if (config === null || config.id === undefined) {
			throw new Error('Invalid entity');
		}
		this.form.patchValue({
			[InternalMetadataFormControls.Id]: config.id,
			[InternalMetadataFormControls.Label]: config?.label ?? '',
			[InternalMetadataFormControls.Description]: config?.description ?? '',
		});
	}
}
