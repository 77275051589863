import { AvatarEntity } from '@dc-common-ui';
import { immerable } from 'immer';

interface IConsumerEntity {
	id: number;
	name: string;
	isGroup: boolean;
	avatar: AvatarEntity;
}

const entityDefaults: IConsumerEntity = {
	id: -1,
	name: '',
	isGroup: false,
	avatar: AvatarEntity.build('user'),
};

export class ExpositionConsumerEntity implements IConsumerEntity {
	private [immerable] = true;
	public id = entityDefaults.id;
	public name = entityDefaults.name;
	public isGroup = entityDefaults.isGroup;
	public avatar = entityDefaults.avatar;

	public static build(
		params: Partial<IConsumerEntity> = {}
	): ExpositionConsumerEntity {
		const instance = new ExpositionConsumerEntity();
		instance.id = params.id ?? entityDefaults.id;
		instance.name = params.name ?? entityDefaults.name;
		instance.isGroup = params.isGroup ?? entityDefaults.isGroup;
		if (params.name) {
			instance.avatar = AvatarEntity.build(params.name, params.isGroup ? 1 : 2);
		}
		return instance;
	}
}
