import { ColumnType } from '@dc-common-core';

interface IExpositionDatablockColumnEntity {
	label: string;
	type: ColumnType;
	isList: boolean;
}

const entityDefaults: IExpositionDatablockColumnEntity = {
	label: '',
	type: ColumnType.Unknown,
	isList: false,
};

export class ExpositionDatablockColumnEntity
	implements IExpositionDatablockColumnEntity
{
	public label = entityDefaults.label;
	public type = entityDefaults.type;
	public isList = entityDefaults.isList;

	public static build(
		params: Partial<IExpositionDatablockColumnEntity> = {}
	): ExpositionDatablockColumnEntity {
		const instance = new ExpositionDatablockColumnEntity();
		instance.label = params.label ?? entityDefaults.label;
		instance.type = params.type ?? entityDefaults.type;
		instance.isList = params.isList ?? entityDefaults.isList;
		return instance;
	}
}
