import {
	STEPPER_GLOBAL_OPTIONS,
	StepperSelectionEvent,
} from '@angular/cdk/stepper';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatSelectChange } from '@angular/material/select';
import { MatStepper } from '@angular/material/stepper';
import { combineAll } from '@dc-common-core';
import { DcBaseComponent } from '@dc-common-ui';
import { Store } from '@ngrx/store';
import { DxDataGridComponent } from 'devextreme-angular';
import { RowClickEvent, SelectionChangedEvent } from 'devextreme/ui/data_grid';
import { firstValueFrom, map, Observable, takeUntil, tap } from 'rxjs';

import { DcIcons } from '../../../ui/app-dc.icons';
import {
	closeMigrateExpositionModal,
	ExpositionsSelector,
	fetchAvailableExpositionsForMigration,
	migrateSelectedExpositions,
	refreshReadyToMigrateExpositionsList,
	retrievesConsumersToMap,
} from '../../store';
import { ExpositionConsumerEntity } from '../exposition-consumers-list/exposition-consumer.entity';
import {
	ExpositionMigrationsStore,
	IExpositionMigrationStepsCompletions,
	MigrationStep,
} from './exposition-migrations.store';
import { ExpositionPreviousConsumerEntity } from './exposition-previous-consumer.entity';
import { MigrationReadyExpositionList } from './migration-ready-exposition-list.entity';

@Component({
	selector: 'app-exposition-migration-stepper',
	templateUrl: './exposition-migration-stepper.component.html',
	styleUrls: ['./exposition-migration-stepper.component.scss'],
	providers: [
		ExpositionMigrationsStore,
		{
			provide: STEPPER_GLOBAL_OPTIONS,
			useValue: {
				displayDefaultIndicatorType: false,
			},
		},
	],
})
export class ExpositionMigrationStepperComponent
	extends DcBaseComponent
	implements OnInit, AfterViewInit
{
	protected readonly DcIcons = DcIcons;
	protected readonly MigrationStep = MigrationStep;

	@ViewChild(MatStepper)
	public stepper: MatStepper | null = null;

	@ViewChild('expositionReadyForMigrationDatagrid')
	private readonly migrationExpoListGridCmp: DxDataGridComponent | null = null;

	public vo$: Observable<{
		currentStep: MigrationStep;
		stepsCompletion: IExpositionMigrationStepsCompletions;
		readyForMigration: MigrationReadyExpositionList | null;
		consumersToMap: Array<ExpositionPreviousConsumerEntity>;
		availableConsumers: Array<ExpositionConsumerEntity>;
		migrationInProgress: boolean;
	}>;

	public constructor(
		private readonly store: Store,
		private readonly stepperStore: ExpositionMigrationsStore,
		private readonly expositionSelector: ExpositionsSelector
	) {
		super();
		this.cmpId = 'exposition-migration-stepper';

		this.vo$ = combineAll({
			migrationInProgress: this.expositionSelector.isSavingInProgress$(),
			currentStep: this.stepperStore.currentStep$,
			stepsCompletion: this.stepperStore.stepsCompletion$,
			readyForMigration: this.stepperStore.readyForMigration$,
			consumersToMap: this.stepperStore.consumersToMap$.pipe(
				map((els) => els.toList().toArray())
			),
			availableConsumers: this.expositionSelector.getAvailableConsumers$(),
		});
	}

	public ngOnInit(): void {
		this.expositionSelector
			.getExpositionsForMigration$()
			.pipe(
				takeUntil(this.onDestroy$),
				tap((list) => this.stepperStore.setReadyForMigration(list))
			)
			.subscribe();

		this.expositionSelector
			.getUnmappedConsumers$()
			.pipe(
				takeUntil(this.onDestroy$),
				tap((consumers) => this.stepperStore.setConsumersToMap(consumers))
			)
			.subscribe();
	}

	public ngAfterViewInit(): void {
		super.ngAfterViewInit();
		if (this.stepper === null) {
			console.error('stepper not found');
			return;
		}
	}

	public close(): void {
		this.store.dispatch(closeMigrateExpositionModal());
	}

	public refresh(): void {
		this.store.dispatch(refreshReadyToMigrateExpositionsList());
	}

	public changeCurrentStep($event: StepperSelectionEvent): void {
		this.stepperStore.changeStep({
			currentStep: $event.selectedIndex,
			previousStep: $event.previouslySelectedIndex,
		});
	}

	public async onStepReady(): Promise<void> {
		if (!this.stepper) {
			return;
		}
		const currentStep = await firstValueFrom(this.stepperStore.currentStep$);
		const previousStep = await firstValueFrom(this.stepperStore.previousStep$);

		if (
			currentStep === MigrationStep.ExpositionsSelection &&
			previousStep === null
		) {
			this.store.dispatch(fetchAvailableExpositionsForMigration());
		} else if (
			previousStep === MigrationStep.ExpositionsSelection &&
			currentStep === MigrationStep.ConsumersMapping
		) {
			const list = (await firstValueFrom(
				this.stepperStore.readyForMigration$
			)) as MigrationReadyExpositionList;
			const ids = list.getSelected().map((el) => el.sourceDatablockId);
			this.store.dispatch(
				retrievesConsumersToMap({
					datablockIds: ids,
				})
			);
		} else if (
			currentStep === MigrationStep.ExpositionsSelection &&
			previousStep === MigrationStep.ConsumersMapping
		) {
			return;
		}
	}

	public async migrate(): Promise<void> {
		const mapping = await firstValueFrom(this.stepperStore.consumersToMap$);
		this.store.dispatch(
			migrateSelectedExpositions({
				mapping,
			})
		);
	}

	public nextStep(): void {
		if (this.stepper === null) {
			return;
		}
		this.stepper.next();
	}

	public async previousStep(): Promise<void> {
		if (this.stepper === null) {
			return;
		}
		const currentCompletionState = await firstValueFrom(
			this.stepperStore.stepsCompletion$
		);
		this.stepperStore.back(this.stepper, currentCompletionState);
	}

	public searchForExpositionToMigrate(searchTerm: string): void {
		if (!this.migrationExpoListGridCmp) {
			return;
		}
		this.migrationExpoListGridCmp.instance.searchByText(searchTerm);
	}

	public async reloadUsers(): Promise<void> {
		this.stepperStore.setConsumersToMap([]);
		const list = (await firstValueFrom(
			this.stepperStore.readyForMigration$
		)) as MigrationReadyExpositionList;
		const ids = list.getSelected().map((el) => el.sourceDatablockId);

		const currentCompletionState = await firstValueFrom(
			this.stepperStore.stepsCompletion$
		);

		this.stepperStore.setStepCompletion({
			expositionSelection: currentCompletionState.expositionSelection,
			consumersMapping: false,
		});

		this.store.dispatch(
			retrievesConsumersToMap({
				datablockIds: ids,
			})
		);
	}

	public async onExpositionClick($event: RowClickEvent): Promise<void> {
		// const expositions = await firstValueFrom(
		// 	this.stepperStore.readyForMigration$
		// );
		// if (expositions === null) {
		// 	return;
		// }
		//
		// const selected = $event.component.getSelectedRowKeys() as Array<number>;
		// const index = selected.indexOf($event.key);
		//
		// if (index > -1) {
		// 	selected.splice(index, 1);
		// 	await $event.component.selectRows(selected, false);
		// } else {
		// 	selected.push($event.key);
		// 	await $event.component.selectRows(selected, true);
		// }
	}

	public async onExpositionSelectionChange(
		$event: SelectionChangedEvent
	): Promise<void> {
		const expositions = await firstValueFrom(
			this.stepperStore.readyForMigration$
		);
		if (expositions === null) {
			return;
		}
		let updated = expositions.select($event.currentSelectedRowKeys, true);
		updated = updated.select($event.currentDeselectedRowKeys, false);
		this.stepperStore.setReadyForMigration(updated);

		const currentCompletionState = await firstValueFrom(
			this.stepperStore.stepsCompletion$
		);
		const selectedCount = updated.getSelected().length;
		this.stepperStore.setStepCompletion({
			expositionSelection: selectedCount !== 0,
			consumersMapping: currentCompletionState.consumersMapping,
		});
	}

	public async assignTargetConsumer(
		$event: MatSelectChange,
		unmappedConsumer: ExpositionPreviousConsumerEntity
	): Promise<void> {
		const currentMapping = await firstValueFrom(
			this.stepperStore.consumersToMap$
		);
		const updated = unmappedConsumer.mapTo($event.value);
		const newMapping = currentMapping.set(updated.id, updated);
		this.stepperStore.setConsumersToMap(newMapping);

		const currentCompletionState = await firstValueFrom(
			this.stepperStore.stepsCompletion$
		);
		const isAllMapped = newMapping.reduce<boolean>(
			(acc, curr) => acc && !!curr.targetConsumerId,
			true
		);
		this.stepperStore.setStepCompletion({
			expositionSelection: currentCompletionState.expositionSelection,
			consumersMapping: isAllMapped,
		});
	}
}
