import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DcBaseComponent } from '@dc-common-core';
import { AppModalService, combineAll, ModalData } from '@dc-common-ui';
import { Observable } from 'rxjs';

import { DcIcons } from '../../../ui/app-dc.icons';
export interface IUpdateParam {
	isChecked: boolean;
	isDisabled: boolean;
	tooltip?: string;
}

export enum ExpositionAction {
	Publish = 'publish',
	ClearData = 'clear-data',
	UpdateData = 'update-data',
	Update = 'update',
	Activate = 'activate',
	Deactivate = 'deactivate',
	Delete = 'delete',
	DeleteAccess = 'delete-access',
}

export interface IExpositionActionConfirmationModalData {
	expositionId: number | Array<number>;
	element: string;
	action: ExpositionAction;
	hasActiveAccess?: boolean;
	hasColumnsChanged?: boolean;
}
@Component({
	selector: 'app-exposition-action-confirmation-popup',
	templateUrl: './exposition-action-confirmation-popup.component.html',
	styleUrls: ['./exposition-action-confirmation-popup.component.scss'],
})
export class ExpositionActionConfirmationPopupComponent<
	T extends IExpositionActionConfirmationModalData
> extends DcBaseComponent {
	public readonly Icons = DcIcons;
	public vo$: Observable<{
		element: string;
		expositionId: number | Array<number>;
		action: ExpositionAction;
		isMultipleElement: boolean;
	}>;
	public updateMetadata = false;
	public updateColumns: IUpdateParam = {
		isChecked: false,
		isDisabled: !this.modalData.hasActiveAccess,
		tooltip: this.modalData.hasActiveAccess
			? $localize`:i18n=@@expositions.publication.api.confirmation.popup.columnsDisabled.tooltip:`
			: $localize`:i18n=@@expositions.publication.api.confirmation.popup.columnsDisabled.noActiveAccess.tooltip:`,
	};
	public updateAccess: IUpdateParam = {
		isChecked: false,
		isDisabled: !this.modalData.hasActiveAccess,
		tooltip: this.modalData.hasActiveAccess
			? $localize`:i18n=@@expositions.publication.api.confirmation.popup.accessDisabled.tooltip:`
			: $localize`:i18n=@@expositions.publication.api.confirmation.popup.accessDisabled.noActiveAccess.tooltip:`,
	};
	public confirmDeletion = false;

	protected readonly DcIcons = DcIcons;
	protected readonly ExpositionAction = ExpositionAction;
	public constructor(
		private readonly modalService: AppModalService,
		@Inject(MAT_DIALOG_DATA) public readonly modalData: ModalData & T
	) {
		super();
		this.cmpId = 'exposition-confirm';

		this.vo$ = combineAll({
			element: this.modalData.element,
			isMultipleElement:
				Array.isArray(this.modalData.expositionId) &&
				this.modalData.element === '',
			expositionId: this.modalData.expositionId,
			action: this.modalData.action,
		});
	}

	public changeUpdateColumns(value: boolean): void {
		this.updateColumns = {
			isChecked: value,
			isDisabled: false,
		};
		this.updateAccess = {
			isChecked: value,
			isDisabled: value,
			tooltip: $localize`:i18n=@@expositions.publication.api.confirmation.popup.accessDisabled.tooltip:`,
		};
	}
	public changeUpdateAccess(value: boolean): void {
		this.updateAccess = {
			isChecked: value,
			isDisabled: false,
		};

		if (this.modalData.hasColumnsChanged) {
			this.updateColumns = {
				isChecked: value,
				isDisabled: value,
				tooltip: $localize`:i18n=@@expositions.publication.api.confirmation.popup.columnsDisabled.tooltip:`,
			};
		}
	}

	public launchAction(): void {
		const { action } = this.modalData;
		if (action === ExpositionAction.Update) {
			this.modalService.closeActiveModal({
				updateMetadata: this.updateMetadata,
				updateColumns: this.updateColumns.isChecked,
				updateAccess: this.updateAccess.isChecked,
				shouldLaunch: true,
			});
		}
		this.modalService.closeActiveModal({
			shouldLaunch: true,
		});
	}
}
