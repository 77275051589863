import { IisEqual } from '@dc-common-core';
import { immerable } from 'immer';

interface IExportTxtMetaConfigurationEntity {
	encoding: string | 'DEFAULT' | 'UTF_8';
	separator: string;
	quote: string;
	shouldApplyOnAllFields: boolean;
	shouldApplyEmptyNullFieldPlaceholderValue: boolean;
	emptyNullFieldPlaceholderValue: string;
	escapeCharacter: string;
	keepLeadingWhiteSpace: boolean;
	keepTrailingWhiteSpace: boolean;
	withHeaders: boolean;
	metadata: string;
}

const entityDefaults: IExportTxtMetaConfigurationEntity = {
	encoding: 'UTF_8',
	separator: ';',
	quote: '"',
	shouldApplyOnAllFields: false,
	shouldApplyEmptyNullFieldPlaceholderValue: true,
	emptyNullFieldPlaceholderValue: '',
	escapeCharacter: '\\',
	keepLeadingWhiteSpace: false,
	keepTrailingWhiteSpace: false,
	withHeaders: false,
	metadata: '',
};

export class ExportTxtMetaConfigurationEntity
	implements
		IExportTxtMetaConfigurationEntity,
		IisEqual<ExportTxtMetaConfigurationEntity>
{
	private [immerable] = true;
	public quote = entityDefaults.quote;
	public shouldApplyOnAllFields = entityDefaults.shouldApplyOnAllFields;
	public shouldApplyEmptyNullFieldPlaceholderValue =
		entityDefaults.shouldApplyEmptyNullFieldPlaceholderValue;
	public emptyNullFieldPlaceholderValue =
		entityDefaults.emptyNullFieldPlaceholderValue;
	public encoding = entityDefaults.encoding;
	public escapeCharacter = entityDefaults.escapeCharacter;
	public separator = entityDefaults.separator;
	public keepLeadingWhiteSpace = entityDefaults.keepLeadingWhiteSpace;
	public keepTrailingWhiteSpace = entityDefaults.keepTrailingWhiteSpace;
	public withHeaders = entityDefaults.withHeaders;
	public metadata = entityDefaults.metadata;

	public static build(
		params: Partial<IExportTxtMetaConfigurationEntity> = {}
	): ExportTxtMetaConfigurationEntity {
		const instance = new ExportTxtMetaConfigurationEntity();
		instance.encoding = params.encoding ?? entityDefaults.encoding;
		instance.escapeCharacter =
			params.escapeCharacter ?? entityDefaults.escapeCharacter;
		instance.quote = params.quote ?? entityDefaults.quote;
		instance.shouldApplyOnAllFields =
			params.shouldApplyOnAllFields ?? entityDefaults.shouldApplyOnAllFields;
		instance.shouldApplyEmptyNullFieldPlaceholderValue =
			params.shouldApplyEmptyNullFieldPlaceholderValue ??
			entityDefaults.shouldApplyEmptyNullFieldPlaceholderValue;
		instance.emptyNullFieldPlaceholderValue =
			params.emptyNullFieldPlaceholderValue ??
			entityDefaults.emptyNullFieldPlaceholderValue;
		instance.separator = params.separator ?? entityDefaults.separator;
		instance.keepLeadingWhiteSpace =
			params.keepLeadingWhiteSpace ?? entityDefaults.keepLeadingWhiteSpace;
		instance.keepTrailingWhiteSpace =
			params.keepTrailingWhiteSpace ?? entityDefaults.keepTrailingWhiteSpace;
		instance.withHeaders = params.withHeaders ?? entityDefaults.withHeaders;
		instance.metadata = params.metadata ?? entityDefaults.metadata;
		return instance;
	}
	private constructor() {}

	public isEqualTo(obj: ExportTxtMetaConfigurationEntity): boolean {
		if (this.keepLeadingWhiteSpace !== obj.keepLeadingWhiteSpace) {
			return false;
		}
		if (this.keepTrailingWhiteSpace !== obj.keepTrailingWhiteSpace) {
			return false;
		}
		if (this.quote.localeCompare(obj.quote) !== 0) {
			return false;
		}
		if (this.shouldApplyOnAllFields !== obj.shouldApplyOnAllFields) {
			return false;
		}
		if (
			this.emptyNullFieldPlaceholderValue.localeCompare(
				obj.emptyNullFieldPlaceholderValue
			) !== 0
		) {
			return false;
		}
		if (
			this.shouldApplyEmptyNullFieldPlaceholderValue !==
			obj.shouldApplyEmptyNullFieldPlaceholderValue
		) {
			return false;
		}
		if (this.withHeaders !== obj.withHeaders) {
			return false;
		}
		if (this.encoding.localeCompare(obj.encoding) !== 0) {
			return false;
		}
		if (this.escapeCharacter.localeCompare(obj.escapeCharacter) !== 0) {
			return false;
		}
		if (this.separator.localeCompare(obj.separator) !== 0) {
			return false;
		}
		if (this.metadata.localeCompare(obj.metadata) !== 0) {
			return false;
		}
		return true;
	}
}
