<div *ngIf="vo$ | async; let vo" class="exposition-internal-metadata-form">
	<form
		*ngIf="!vo.isInViewMode"
		[formGroup]="internalMetadataForm.form"
		class="exposition-internal-metadata-form__config">
		<mat-form-field [appearance]="Appearance">
			<mat-label i18n="@@exposition.metadata.form.label">label</mat-label>
			<input
				type="text"
				id="{{ cmpId + '-label-input' }}"
				matInput
				[formControlName]="OwnMetadataFormControls.Label" />
			<mat-error
				*ngIf="
					internalMetadataForm.form
						.get(OwnMetadataFormControls.Label)
						?.hasError(ValidationErrorKeys.Maxlength)
				"
				i18n="@@general.form.errors.maxlength">
				max length
			</mat-error>
			<mat-error
				*ngIf="
						internalMetadataForm.form
							.get(OwnMetadataFormControls.Label)
							?.hasError(ValidationErrorKeys.Required)
					"
				i18n="@@general.form.errors.required">
				max length
			</mat-error>
		</mat-form-field>
		<mat-form-field [appearance]="Appearance">
			<mat-label i18n="@@exposition.metadata.form.description">
				description
			</mat-label>
			<textarea
				type="text"
				[rows]="4"
				id="{{ cmpId + '-description-input' }}"
				matInput
				[formControlName]="OwnMetadataFormControls.Description"></textarea>
			<mat-error
				*ngIf="
					internalMetadataForm.form
						.get(OwnMetadataFormControls.Description)
						?.hasError(ValidationErrorKeys.Maxlength)
				"
				i18n="@@general.form.errors.maxlength">
				max length
			</mat-error>
		</mat-form-field>

		<app-tag-configurator
			displayMode="tags" configMode="ActiveEdit"
			[configuredTags]="vo.expositionMetadata.tags | toArray"
			[availableTags]="vo.availableTags | toArray"></app-tag-configurator>
	</form>

	<div *ngIf="vo.isInViewMode" class="exposition-internal-metadata-form__view">
			<span class="exposition-internal-metadata-form__view-description">
				{{ vo.expositionMetadata.description }}
			</span>
			<app-tag-configurator
				displayMode="tags" configMode="View"
				[configuredTags]="vo.expositionMetadata.tags"></app-tag-configurator>
	</div>
</div>
