import { Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { combineAll, DcBaseComponent } from '@dc-common-core';
import { Store } from '@ngrx/store';
import { DxDataGridComponent } from 'devextreme-angular';
import { SelectionChangedEvent } from 'devextreme/ui/data_grid';
import { BehaviorSubject, Observable, takeUntil, tap } from 'rxjs';

import { ComponentIcons } from '../../../ui/app-components.icons';
import { DcIcons } from '../../../ui/app-dc.icons';
import { ExpositionsSelector, getDataBlocksColumns } from '../../store';
import { ExpositionDatablockListEntity } from './exposition-datablock-list.entity';
import { ExpositionDatablockEntity } from './exposition-datablock.entity';

@Component({
	selector: 'app-exposition-datablock-list',
	templateUrl: './exposition-datablock-list.component.html',
	styleUrls: ['./exposition-datablock-list.component.scss'],
	exportAs: 'DatablocksGrid',
})
export class ExpositionDatablockListComponent extends DcBaseComponent {
	public datablocksCount = 0;
	public openedOverlayIdSubject = new BehaviorSubject<number | null>(null);

	@ViewChild(DxDataGridComponent)
	private readonly grid: DxDataGridComponent | null = null;
	@Output()
	public selectedDatablockIdEvent = new EventEmitter<number>();

	public vo$: Observable<{
		list: ExpositionDatablockListEntity;
		openedOverlayId: number | null;
		isLoadingColumns: boolean;
	}>;

	protected readonly DcIcons = DcIcons;
	protected readonly ComponentIcons = ComponentIcons;
	public constructor(
		private readonly store: Store,
		private readonly expositionsSelector: ExpositionsSelector
	) {
		super();
		this.cmpId = 'exposition-datablock-list';
		this.dxLocalStorageKey = 'dx.grid.expositions:datablock-list';
		this.expositionsSelector
			.getDatablockList$()
			.pipe(
				takeUntil(this.onDestroy$),
				tap((datablocks) => (this.datablocksCount = datablocks.elements.size))
			)
			.subscribe();
		this.vo$ = combineAll({
			list: this.expositionsSelector.getDatablockList$(),
			openedOverlayId: this.openedOverlayIdSubject,
			isLoadingColumns:
				this.expositionsSelector.getIsLoadingDatablockColumns$(),
		});
	}

	public openColumnsPopover(
		event: Event,
		column: ExpositionDatablockEntity
	): void {
		event.stopPropagation();
		if (!column.columnsCount) {
			return;
		}
		this.openedOverlayIdSubject.next(column.id);
		this.store.dispatch(
			getDataBlocksColumns({
				datablockId: column.id,
			})
		);
	}

	public closeColumnsPopover(): void {
		this.openedOverlayIdSubject.next(null);
	}

	public onSelection(event: SelectionChangedEvent): void {
		this.selectedDatablockIdEvent.emit(event.currentSelectedRowKeys[0]);
	}

	public search(searchTerm: string): void {
		if (this.grid === null) {
			return;
		}
		this.grid.instance.searchByText(searchTerm);
	}
}
