import { Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { combineAll, DcBaseComponent, PopoverIcon } from '@dc-common-core';
import { DxDataGridComponent } from 'devextreme-angular';
import { BehaviorSubject, firstValueFrom, Observable } from 'rxjs';

import { ComponentIcons } from '../../../ui/app-components.icons';
import { DcIcons } from '../../../ui/app-dc.icons';
import { ExpositionsSelector } from '../../store';
import { ExpositionAccessListEntity } from './exposition-access-list.entity';
import { AccessType, ExpositionAccessEntity } from './exposition-access.entity';

interface OpenedOverlayId {
	columns: string | null;
	users: string | null;
	groups: string | null;
	lines: string | null;
}
@Component({
	selector: 'app-exposition-access-list',
	templateUrl: './exposition-access-list.component.html',
	styleUrls: ['./exposition-access-list.component.scss'],
	inputs: ['expositionAccessList'],
	exportAs: 'AccessPointsGrid',
})
export class ExpositionAccessListComponent extends DcBaseComponent {
	public ComponentIcons = ComponentIcons;
	public openedOverlayIdsSubject = new BehaviorSubject<OpenedOverlayId>({
		groups: null,
		columns: null,
		lines: null,
		users: null,
	});
	public isOverlayContentLoadingSubject = new BehaviorSubject<boolean>(false);
	public vo$: Observable<{
		list: ExpositionAccessListEntity;
		openedOverlays: OpenedOverlayId;
		isOverlayContentLoading: boolean;
		canEdit: boolean;
	}>;
	protected readonly DcIcons = DcIcons;

	protected readonly AccessType = AccessType;

	@ViewChild(DxDataGridComponent)
	private readonly grid: DxDataGridComponent | null = null;

	@Output()
	public editAccess = new EventEmitter<string>();

	@Output()
	public toggleAccessStatus = new EventEmitter<string>();

	@Output()
	public deleteAccess = new EventEmitter<ExpositionAccessEntity>();

	public constructor(
		private readonly expositionsSelector: ExpositionsSelector
	) {
		super();
		this.cmpId = 'exposition-access-list';
		this.dxLocalStorageKey = 'dx.grid.exposition:access-list';

		this.vo$ = combineAll({
			list: this.toObservable<ExpositionAccessListEntity>(
				'expositionAccessList'
			),
			openedOverlays: this.openedOverlayIdsSubject,
			isOverlayContentLoading: this.isOverlayContentLoadingSubject,
			canEdit: this.expositionsSelector.getCanEditExposition$(),
		});
	}

	public closeOverlay(): void {
		this.isOverlayContentLoadingSubject.next(false);
		this.openedOverlayIdsSubject.next({
			groups: null,
			columns: null,
			lines: null,
			users: null,
		});
	}

	public async openColumnsOverlay(
		entry: ExpositionAccessEntity
	): Promise<void> {
		const { id, columnsCount } = entry;
		if (!columnsCount) {
			return;
		}
		const openedIds = await firstValueFrom(this.openedOverlayIdsSubject);
		if (id === '') {
			this.isOverlayContentLoadingSubject.next(false);
			this.openedOverlayIdsSubject.next({
				...openedIds,
				columns: null,
			});
		} else {
			this.openedOverlayIdsSubject.next({
				...openedIds,
				columns: id,
			});
		}
	}

	public async openUsersOverlay(entry: ExpositionAccessEntity): Promise<void> {
		const { id, usersCount } = entry;
		if (!usersCount) {
			return;
		}
		const openedIds = await firstValueFrom(this.openedOverlayIdsSubject);
		if (id === '') {
			this.openedOverlayIdsSubject.next({
				...openedIds,
				users: null,
			});
		} else {
			this.openedOverlayIdsSubject.next({
				...openedIds,
				users: id,
			});
		}
	}

	public async openGroupsOverlay(entry: ExpositionAccessEntity): Promise<void> {
		const { id, groupsCount } = entry;
		if (!groupsCount) {
			return;
		}
		const openedIds = await firstValueFrom(this.openedOverlayIdsSubject);
		if (id === '') {
			this.openedOverlayIdsSubject.next({
				...openedIds,
				groups: null,
			});
		} else {
			this.openedOverlayIdsSubject.next({
				...openedIds,
				groups: id,
			});
		}
	}

	public async openLinesOverlay(entry: ExpositionAccessEntity): Promise<void> {
		const { id, lines } = entry;
		if (!lines) {
			return;
		}
		const openedIds = await firstValueFrom(this.openedOverlayIdsSubject);
		if (id === '') {
			this.openedOverlayIdsSubject.next({
				...openedIds,
				lines: null,
			});
		} else {
			this.openedOverlayIdsSubject.next({
				...openedIds,
				lines: id,
			});
		}
	}

	public goToAccessConfig(id: string): void {
		this.editAccess.emit(id);
	}

	public toggleStatus(id: string): void {
		this.toggleAccessStatus.emit(id);
	}

	public deleteExpositionAccess(access: ExpositionAccessEntity): void {
		this.deleteAccess.emit(access);
	}

	public search(searchTerm: string): void {
		if (this.grid === null) {
			return;
		}
		this.grid.instance.searchByText(searchTerm);
	}
}
