import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, tap } from 'rxjs';

import { NgCommService } from '../../ajs-ng-communication.service';
import { AjsScopeService } from '../../ajs-scope.service';
import { setUserAdminRole } from './app.actions';

@Injectable()
export class AppEffects {
	public userIsAdmin$ = createEffect(() =>
		this.ajsScopeService.listenOnRootScope<boolean>('sync:user:is-admin').pipe(
			map((val) =>
				setUserAdminRole({
					isAdmin: val,
				})
			)
		)
	);

	public redirectHome$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType('redirect:home'),
				tap(() =>
					this.ngCommService.notifyOnWarning(
						$localize`:i18n=@@general.project.invalid:`
					)
				)
			),
		{
			dispatch: false,
		}
	);

	// TODO: translate title keys
	public setTitleOnTransitionSuccess$ = createEffect(
		() =>
			this.ajsScopeService
				.listenOnRootScope<string>('state:transition:success')
				.pipe(tap((v) => this.titleService.setTitle(`Datachain - ${v}`))),
		{
			dispatch: false,
		}
	);

	public constructor(
		private readonly actions$: Actions,
		private readonly titleService: Title,
		private readonly ajsScopeService: AjsScopeService,
		private readonly ngCommService: NgCommService
	) {}
}
