<app-page *ngIf="vo$ | async; let vo">
	<app-page-header>
		<app-breadcrumb [paths]="paths"></app-breadcrumb>
	</app-page-header>
	<app-page-actions>
		<button *ngIf="vo.canDelete"
			mat-icon-button
			class="app-exposition-view__action-btn"
			[matMenuTriggerFor]="actions">
			<mat-icon [svgIcon]="DcIcons.MenuMoreOptionsHandle"></mat-icon>
		</button>
		<mat-menu  #actions="matMenu">
			<ng-template matMenuContent>
				<button *ngIf="vo.canDelete"
					id="{{ cmpId }}-delete-btn"
					(click)="openDeleteConfirmationPopup()"
					mat-menu-item>
					<span i18n="@@general.action.delete">delete</span>
				</button>
				<button *ngIf="false" id="{{ cmpId }}-help-btn"
								mat-menu-item>
					<span i18n="@@general.action.help">help</span>
				</button>
			</ng-template>
		</mat-menu>
	</app-page-actions>

	<app-page-content>
		<div class="app-exposition-view">
			<section class="app-exposition-view__metadata-api">
				<!--	Exposition Element Metadata			-->
				<app-card-outline class="app-exposition-view__metadata">
					<div card-outline-title class="app-exposition-view__metadata-label">
						{{ vo.metadata.label }}
					</div>
					<button
						card-outline-btns
						mat-icon-button
						class="app-exposition-view__action-btn"
						(click)="editMetadata()">
						<mat-icon [svgIcon]="DcIcons.Edit"></mat-icon>
					</button>
					<app-exposition-internal-metadata-config
						card-outline-content
						[expositionMetadata]="vo.metadata"
						[isInViewMode]="true"></app-exposition-internal-metadata-config>
				</app-card-outline>

				<!--	Publication API			-->
				<app-card-outline class="app-exposition-view__api">
					<div card-outline-title class="app-exposition-view__api-header">
						<span i18n="@@expositions.publication.api">api publication</span>
						<app-exposition-publication-status
							[status]="
								vo.published.status
							"></app-exposition-publication-status>
					</div>
					<div *ngIf="vo.canEdit" card-outline-btns class="app-exposition-view__api__btns">
						<button
							*ngIf="vo.published.status === PublicationStatus.Unpublished"
							id="{{ cmpId }}-publish-btn"
							mat-raised-button
							color="primary"
							class="app-exposition-view__api__publish-btn"
							matTooltip="Un accès actif est obligatoire pour publier l’API"
							i18n-matTooltip="
								@@expositions.publication.api.tooltip.activeAccess"
							[matTooltipDisabled]="vo.published.hasActiveAccess"
							[disabled]="
								!vo.published.hasActiveAccess ||
								vo.actionInProgress === 'publish'
							"
							(click)="openPublishConfirmationPopup()">
							<span class="app-exposition-view__api__spinner">
								<mat-spinner
									*ngIf="vo.actionInProgress === 'publish'"
									[diameter]="20"></mat-spinner>
								<span i18n="@@expositions.publication.api.action.publish">
									publish
								</span>
							</span>
						</button>
						<ng-container
							*ngIf="vo.published.status !== PublicationStatus.Unpublished">
							<button
								class="action-btn"
								color="primary"
								id="{{ cmpId }}-update-data-btn"
								mat-icon-button
								matTooltip="update data"
								i18n-matTooltip="
									@@expositions.publication.api.action.updateData"
								[disabled]="vo.actionInProgress !== 'none'"
								(click)="openUpdateDataConfirmationPopup()">
								<mat-icon *ngIf="vo.actionInProgress === 'update-data'">
									<mat-spinner [diameter]="20"></mat-spinner>
								</mat-icon>
								<mat-icon
									*ngIf="vo.actionInProgress !== 'update-data'"
									[svgIcon]="DcIcons.Input"></mat-icon>
							</button>
							<button
								class="action-btn"
								color="primary"
								id="{{ cmpId }}-update-btn"
								mat-icon-button
								matTooltip="update"
								i18n-matTooltip="@@expositions.publication.api.action.update"
								[disabled]="vo.actionInProgress !== 'none'"
								(click)="openUpdateConfirmationPopup()">
								<mat-icon *ngIf="vo.actionInProgress === 'update'">
									<mat-spinner [diameter]="20"></mat-spinner>
								</mat-icon>
								<mat-icon
									*ngIf="vo.actionInProgress !== 'update'"
									[svgIcon]="DcIcons.Settings"></mat-icon>
							</button>
							<button
								class="action-btn"
								color="primary"
								*ngIf="vo.published.status === PublicationStatus.Unavailable"
								id="{{ cmpId }}-activate-btn"
								mat-icon-button
								matTooltip="activate"
								i18n-matTooltip="@@expositions.publication.api.action.activate"
								[disabled]="vo.actionInProgress !== 'none'"
								(click)="openActivateConfirmationPopup()">
								<mat-icon *ngIf="vo.actionInProgress === 'activate'">
									<mat-spinner [diameter]="20"></mat-spinner>
								</mat-icon>
								<mat-icon
									*ngIf="vo.actionInProgress !== 'activate'"
									[svgIcon]="DcIcons.Share"></mat-icon>
							</button>
							<button
								class="action-btn"
								color="primary"
								*ngIf="vo.published.status === PublicationStatus.Available"
								id="{{ cmpId }}-deactivate-btn"
								mat-icon-button
								matTooltip="deactivate"
								i18n-matTooltip="
									@@expositions.publication.api.action.deactivate"
								[disabled]="vo.actionInProgress !== 'none'"
								(click)="openDeactivateConfirmationPopup()">
								<mat-icon *ngIf="vo.actionInProgress === 'deactivate'">
									<mat-spinner [diameter]="20"></mat-spinner>
								</mat-icon>
								<mat-icon
									*ngIf="vo.actionInProgress !== 'deactivate'"
									[svgIcon]="DcIcons.ShareOff"></mat-icon>
							</button>
							<button
								class="action-btn delete-data"
								color="primary"
								id="{{ cmpId }}-clear-data-btn"
								mat-icon-button
								matTooltip="clear data"
								i18n-matTooltip="@@expositions.publication.api.action.clear"
								[disabled]="vo.actionInProgress !== 'none'"
								(click)="openClearDataConfirmationPopup()">
								<mat-icon *ngIf="vo.actionInProgress === 'clear-data'">
									<mat-spinner [diameter]="20"></mat-spinner>
								</mat-icon>
								<mat-icon
									*ngIf="vo.actionInProgress !== 'clear-data'"
									[svgIcon]="DcIcons.Output"></mat-icon>
							</button>
						</ng-container>
					</div>
					<app-published-exposition [published]="vo.published"
						card-outline-content></app-published-exposition>
				</app-card-outline>
			</section>

			<section class="app-exposition-view__parameters">
				<!--	Exposition Endpoint Metadata			-->
				<app-panel
					headerHeight="3rem"
					initialState="true"
					[isAlwaysOpen]="false"
					[actions]="publicMetadataActions">
					<span panel-title i18n="@@exposition.metadata.public">Metadata</span>
					<app-exposition-endpoint-metadata-config
						[expositionMetadata]="vo.endpointMetadata"
						[isInViewMode]="true"></app-exposition-endpoint-metadata-config>
				</app-panel>

				<!--	Column List Configuration			-->
				<app-panel
					class="app-card-outline__columns"
					headerHeight="3rem"
					isAlwaysOpen="true"
					hasSearchableGrid="true"
					[itemsCount]="vo.columns.elements.size"
					[actions]="columnsActions"
					(search)="columnsGrid.search($event)">
					<span panel-title i18n="@@exposition.access.list.column.columns">
						columns
					</span>
					<app-exposition-columns-config
						#columnsGrid="ColumnsGrid"
						[columnConfigList]="vo.columns"
						[isInViewMode]="true"></app-exposition-columns-config>
				</app-panel>

				<!--	Access List Configuration			-->
				<app-panel
					headerHeight="3rem"
					class="app-card-outline__access-list"
					isAlwaysOpen="true"
					hasSearchableGrid="true"
					[itemsCount]="vo.accessPoints.elements.size"
					[actions]="accessListActions"
					(search)="accessPointsGrid.search($event)">
					<span panel-title i18n="@@exposition.access.list.title">columns</span>
					<app-exposition-access-list
						#accessPointsGrid="AccessPointsGrid"
						[expositionAccessList]="vo.accessPoints"
						(editAccess)="goToAccessConfig($event)"
						(toggleAccessStatus)="toggleAccessStatus($event)"
						(deleteAccess)="deleteAccess($event)"></app-exposition-access-list>
				</app-panel>
			</section>
		</div>
	</app-page-content>
</app-page>
