import { ChangeDetectionStrategy, Component, ViewChild } from '@angular/core';
import { DcBaseComponent, combineAll } from '@dc-common-core';
import { AppTagConfiguratorComponent, AppTagItemEntity } from '@dc-common-ui';
import { Store } from '@ngrx/store';
import { OrderedSet } from 'immutable';
import {
	BehaviorSubject,
	firstValueFrom,
	map,
	Observable,
	takeUntil,
	tap,
} from 'rxjs';

import { AppMetadataComponent } from '../../../ui/components/app-metadata/app-metadata.component';
import { AppMetadataEntity } from '../../../ui/components/app-metadata/app-metadata.entity';
import {
	createExportTemplateConfig,
	ExportTemplateSelector,
	fetchExportTemplateConfig,
	goToExportTemplateListView,
	initExportTemplateConfig,
	updateExportTemplateConfig,
} from '../../store';
import { ExportTemplateDetailsComponent } from '../export-template-details/export-template-details.component';
import { ExportTemplateDetailsEntity } from '../export-template-details/export-template-details.entity';

@Component({
	selector: 'app-export-template',
	templateUrl: './export-template.component.html',
	styleUrls: ['./export-template.component.scss'],
	inputs: ['exportConfigId'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ExportTemplateComponent extends DcBaseComponent {
	private readonly isExportDetailsErroredSubject = new BehaviorSubject<boolean>(
		false
	);
	private readonly isMetadataErroredSubject = new BehaviorSubject<boolean>(
		false
	);

	private readonly isMetadataValueChangedSubject = new BehaviorSubject<boolean>(
		false
	);

	private readonly isTagsListChangedSubject = new BehaviorSubject<boolean>(
		false
	);

	private readonly isExportDetailsValueChangedSubject =
		new BehaviorSubject<boolean>(false);

	private readonly isNewSubject = new BehaviorSubject<boolean>(false);

	@ViewChild(ExportTemplateDetailsComponent)
	public exportTemplateDetailsCmp!: ExportTemplateDetailsComponent;

	@ViewChild(AppMetadataComponent)
	public templateMetadataCmp!: AppMetadataComponent;

	@ViewChild(AppTagConfiguratorComponent)
	public tagConfiguratorComponentCmp: AppTagConfiguratorComponent | null = null;

	public vo$: Observable<{
		isSaveDisabled: boolean;
		isModifiedByUser: boolean;
		templateDetails: ExportTemplateDetailsEntity;
		templateMetadata: AppMetadataEntity;
		isNewTemplate: boolean;
		availableTags: OrderedSet<AppTagItemEntity>;
	}>;

	public constructor(
		private readonly store: Store,
		private readonly exportTemplateSelector: ExportTemplateSelector
	) {
		super();
		this.cmpId = 'export-template';
		this.store.dispatch(initExportTemplateConfig());

		combineAll({
			exportId: this.toObservable<number>('exportConfigId'),
		})
			.pipe(
				takeUntil(this.onDestroy$),
				tap(({ exportId }) => {
					if (exportId === -1) {
						this.isNewSubject.next(false);
						return;
					}
					this.isNewSubject.next(true);
					return this.store.dispatch(
						fetchExportTemplateConfig({
							exportId,
						})
					);
				})
			)
			.subscribe();

		const isSaveDisabled = combineAll({
			exportHasError: this.isExportDetailsErroredSubject,
			metaDataHasError: this.isMetadataErroredSubject,
		}).pipe(map((result) => result.exportHasError || result.metaDataHasError));

		const isModifiedByUser$ = combineAll({
			isMetadataChanged: this.isMetadataValueChangedSubject,
			isDetailsChanged: this.isExportDetailsValueChangedSubject,
			isTagsListChanged: this.isTagsListChangedSubject,
		}).pipe(map((res) => res.isDetailsChanged || res.isMetadataChanged));

		this.vo$ = combineAll({
			isSaveDisabled,
			isModifiedByUser: isModifiedByUser$,
			templateDetails: this.exportTemplateSelector.getExportTemplateConfig$(),
			templateMetadata: this.exportTemplateSelector.getExportMetadata$(),
			availableTags: this.exportTemplateSelector.getAvailableTags$(),
			isNewTemplate: this.isNewSubject,
		});
	}

	public onMetadataError(isErrored: boolean): void {
		this.isMetadataErroredSubject.next(isErrored);
	}

	public onExportDetailsError(isErrored: boolean): void {
		this.isExportDetailsErroredSubject.next(isErrored);
	}

	public hasMetaDataChangedValue($event: boolean): void {
		this.isMetadataValueChangedSubject.next($event);
	}

	public hasTagsListChanged($event: boolean): void {
		this.isMetadataValueChangedSubject.next($event);
	}

	public hasDetailsChangedValue($event: boolean): void {
		this.isExportDetailsValueChangedSubject.next($event);
	}

	public exitPage(): void {
		// TODO: check if form was changed in case we are in full edit mode (no distinction between display mode and edit mode)
		// even in full edit mode we still a change detection mechanism to determine if the correct export template should be updated
		return this.store.dispatch(goToExportTemplateListView());
	}

	public async save(): Promise<void> {
		const isInEditMode = await firstValueFrom(this.isNewSubject);
		let templateTags = new Array<AppTagItemEntity>();
		if (this.tagConfiguratorComponentCmp) {
			templateTags = await this.tagConfiguratorComponentCmp.getUsedTags();
		}
		if (isInEditMode) {
			return this.store.dispatch(
				updateExportTemplateConfig({
					payload: {
						templateDetails: this.exportTemplateDetailsCmp.extractFormData(),
						templateMetadata: this.templateMetadataCmp.extractFormData(),
						templateTags,
					},
				})
			);
		}
		return this.store.dispatch(
			createExportTemplateConfig({
				payload: {
					templateDetails: this.exportTemplateDetailsCmp.extractFormData(),
					templateMetadata: this.templateMetadataCmp.extractFormData(),
					templateTags,
				},
			})
		);
	}
}
