import {
	IListAttrs,
	IListColumnConfig,
	IListOperations,
} from '@datachain/adb-common';
import { immerable, produce } from 'immer';
import { Map } from 'immutable';

import { ExpositionConsumerEntity } from './exposition-consumer.entity';

export interface IConsumerListExtra
	extends IListColumnConfig<ExpositionConsumerEntity> {
	isFixed?: boolean;
	isSelectable?: boolean;
	isSearchable?: boolean;
	isInGroup?: boolean;
	isVisible?: boolean;
	canBeHidden?: boolean;
	canBeReordered?: boolean;
	canBeChosen?: boolean;
	format?: string;
	visibleIndex?: number;
}

interface IConsumerListEntity extends IListAttrs<ExpositionConsumerEntity> {
	availableColumns: Map<string, IConsumerListExtra>;
}

const entityDefaults: IConsumerListEntity = {
	elements: Map(),
	availableColumns: Map(),
};

export class ExpositionConsumerListEntity
	implements
		IConsumerListEntity,
		IListOperations<ExpositionConsumerListEntity, ExpositionConsumerEntity>
{
	private [immerable] = true;
	public elements = entityDefaults.elements;
	public availableColumns = entityDefaults.availableColumns;

	public static build(
		availableCols: Array<IConsumerListExtra>
	): ExpositionConsumerListEntity {
		const inst = new ExpositionConsumerListEntity();
		const cols = availableCols.reduce((acc, curr) => {
			acc.push([curr.field, curr]);
			return acc;
		}, new Array<[string, IConsumerListExtra]>());
		inst.availableColumns = Map(cols);
		return inst;
	}

	private constructor() {}

	public getAvailableColumns(): Map<
		string,
		IListColumnConfig<ExpositionConsumerEntity>
	> {
		return this.availableColumns;
	}
	public setElements(
		elems: Map<string, ExpositionConsumerEntity>
	): ExpositionConsumerListEntity {
		return produce(this, (draft: ExpositionConsumerListEntity) => {
			draft.elements = elems;
		});
	}

	public addConsumer(
		elem: ExpositionConsumerEntity
	): ExpositionConsumerListEntity {
		const elems = this.elements.set(`${elem.id}`, elem);
		return produce(this, (draft: ExpositionConsumerListEntity) => {
			draft.elements = elems;
		});
	}

	public removeConsumers(ids: Array<string>): ExpositionConsumerListEntity {
		const elems = this.elements.deleteAll(ids);
		return produce(this, (draft: ExpositionConsumerListEntity) => {
			draft.elements = elems;
		});
	}
}
