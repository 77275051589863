import { Inject, Injectable, Injector } from '@angular/core';
import { ReplaySubject } from 'rxjs';

import { ANGULARJS_BOOTSTRAP_TIMEOUT } from './core/bootstrap-timeout.token';

@Injectable()
export class AjsInjectorService {
	private readonly injectorSubject = new ReplaySubject<any>(1);
	public readonly $injector = this.injectorSubject.asObservable();

	public constructor(
		@Inject(ANGULARJS_BOOTSTRAP_TIMEOUT) private readonly ajsTimeout: number,
		private readonly injector: Injector
	) {
		setTimeout(() => {
			console.warn('Getting AngularJS injector');
			this.injectorSubject.next(this.injector.get('$injector'));
		}, this.ajsTimeout);
	}
}
