import { AppTagItemEntity, AvatarEntity } from '@dc-common-ui';
import { immerable, produce } from 'immer';
import { OrderedSet } from 'immutable';

import { PublicationAction } from '../../store';
import { ExpositionEndpointMetadataEntity } from '../exposition-endpoint-metadata-config/exposition-endpoint-metadata.entity';
import { ExpositionEntity } from '../exposition-view/exposition.entity';
import { PublicationStatus } from '../publication-status.enum';

interface IExpositionItemEntity {
	id: number;
	label: string;
	datablockSource: string;
	tags: OrderedSet<AppTagItemEntity>;
	publishState: PublicationStatus;
	apiStatus: PublicationStatus;
	accessPointLabel: string;
	accessPointUrl: string;
	columnsCount: number;
	accessCount: number;
	dataEntries: number;
	lastExpositionAction: string | Date | null; // FIXME:
	lastExpositionActionBy: AvatarEntity | null;
	lastElemModification: string | Date | null; // FIXME:
	lastElemModificationBy: AvatarEntity | null;
	duration: string; // FIXME:
	expositionUrl: string;
	sourceUrl: string;
	actionInProgress: PublicationAction;
	isSelected: boolean;
	endPointMetadata: ExpositionEndpointMetadataEntity;
}

const entityDefaults: IExpositionItemEntity = {
	id: -1,
	label: '',
	datablockSource: '',
	tags: OrderedSet(),
	publishState: PublicationStatus.Unavailable,
	apiStatus: PublicationStatus.Unavailable,
	accessPointLabel: '',
	accessPointUrl: '',
	columnsCount: 0,
	accessCount: 0,
	dataEntries: 0,
	lastExpositionAction: null,
	lastExpositionActionBy: null,
	lastElemModification: null,
	lastElemModificationBy: null,
	duration: '',
	expositionUrl: '',
	sourceUrl: '',
	actionInProgress: 'none',
	isSelected: false,
	endPointMetadata: ExpositionEndpointMetadataEntity.build(),
};

export class ExpositionItemEntity implements IExpositionItemEntity {
	private [immerable] = true;
	public id = entityDefaults.id;
	public datablockSource = entityDefaults.datablockSource;
	public label = entityDefaults.label;
	public tags = entityDefaults.tags;
	public publishState = entityDefaults.publishState;
	public accessPointLabel = entityDefaults.accessPointLabel;
	public accessPointUrl = entityDefaults.accessPointUrl;
	public columnsCount = entityDefaults.columnsCount;
	public accessCount = entityDefaults.accessCount;
	public dataEntries = entityDefaults.dataEntries;
	public lastExpositionAction = entityDefaults.lastExpositionAction;
	public lastExpositionActionBy = entityDefaults.lastExpositionActionBy;
	public lastElemModification = entityDefaults.lastElemModification;
	public lastElemModificationBy = entityDefaults.lastElemModificationBy;
	public apiStatus = entityDefaults.apiStatus;
	public duration = entityDefaults.duration;
	public expositionUrl = entityDefaults.expositionUrl;
	public sourceUrl = entityDefaults.sourceUrl;
	public actionInProgress = entityDefaults.actionInProgress;
	public isSelected = entityDefaults.isSelected;
	public endPointMetadata = entityDefaults.endPointMetadata;

	public static build(
		params: Partial<IExpositionItemEntity> = {}
	): ExpositionItemEntity {
		const inst = new ExpositionItemEntity();
		inst.id = params.id ?? entityDefaults.id;
		inst.label = params.label ?? entityDefaults.label;
		inst.datablockSource =
			params.datablockSource ?? entityDefaults.datablockSource;
		inst.tags = params.tags ?? entityDefaults.tags;
		inst.publishState = params.publishState ?? entityDefaults.publishState;
		inst.accessPointLabel =
			params.accessPointLabel ?? entityDefaults.accessPointLabel;
		inst.accessPointUrl =
			params.accessPointUrl ?? entityDefaults.accessPointUrl;
		inst.columnsCount = params.columnsCount ?? entityDefaults.columnsCount;
		inst.accessCount = params.accessCount ?? entityDefaults.accessCount;
		inst.dataEntries = params.dataEntries ?? entityDefaults.dataEntries;
		inst.lastExpositionAction =
			params.lastExpositionAction ?? entityDefaults.lastExpositionAction;
		inst.lastExpositionActionBy =
			params.lastExpositionActionBy ?? entityDefaults.lastExpositionActionBy;
		inst.lastElemModification =
			params.lastElemModification ?? entityDefaults.lastElemModification;
		inst.lastElemModificationBy =
			params.lastElemModificationBy ?? entityDefaults.lastElemModificationBy;
		inst.apiStatus = params.apiStatus ?? entityDefaults.apiStatus;
		inst.duration = params.duration ?? entityDefaults.duration;
		inst.expositionUrl = params.expositionUrl ?? entityDefaults.expositionUrl;
		inst.sourceUrl = params.sourceUrl ?? entityDefaults.sourceUrl;
		inst.actionInProgress =
			params.actionInProgress ?? entityDefaults.actionInProgress;
		inst.isSelected = params.isSelected ?? entityDefaults.isSelected;
		inst.endPointMetadata =
			params.endPointMetadata ?? entityDefaults.endPointMetadata;
		return inst;
	}

	public setRunningAction(action: PublicationAction): ExpositionItemEntity {
		return produce(this, (draft: ExpositionItemEntity) => {
			draft.actionInProgress = action;
		});
	}

	public resetRunningAction(): ExpositionItemEntity {
		return produce(this, (draft: ExpositionItemEntity) => {
			draft.actionInProgress =
				draft.actionInProgress !== 'none' ? draft.actionInProgress : 'none';
		});
	}

	public toggleSelection(): ExpositionItemEntity {
		return produce(this, (draft: ExpositionItemEntity) => {
			draft.isSelected = !draft.isSelected;
		});
	}

	public updateView(view: ExpositionEntity): ExpositionItemEntity {
		return produce(this, (draft: ExpositionItemEntity) => {
			draft.endPointMetadata = view.endpointMetadata;
		});
	}
}
