import { Injectable } from '@angular/core';
import { AppTagItemEntity } from '@dc-common-ui';
import { OrderedSet } from 'immutable';

import {
	IDcMetadataResponse,
	IMetadataTag,
} from '../requesters/core/metadata.model';
import { AppMetadataEntity } from '../ui/components/app-metadata/app-metadata.entity';
import { TagAdapter } from './tag.adapter';

@Injectable({
	providedIn: 'root',
})
export class MetadataAdapter {
	public constructor(private readonly tagResponseAdapter: TagAdapter) {}

	public generateMetadata(
		response: IDcMetadataResponse | undefined
	): AppMetadataEntity {
		if (response === undefined) {
			throw new Error('no data found to parse');
		}
		return AppMetadataEntity.build({
			title: response?.label ?? '',
			id: response?.id ?? -1,
			description: response?.description ?? '',
			isActive: response?.actif ?? false,
			code: response?.code ?? '',
			createdBy: response?.created_by ?? '',
			updatedBy: response?.updated_by ?? '',
			updateDate: response?.update_date ?? -1,
			creationDate: response?.creation_date ?? -1,
			tags: this.generateMetaDataTags(response.tags),
		});
	}

	private generateMetaDataTags(
		tags: ReadonlyArray<IMetadataTag> | undefined
	): OrderedSet<AppTagItemEntity> {
		if (tags === undefined) {
			return OrderedSet();
		}
		return this.tagResponseAdapter.generateResponseMetadataTag(tags);
	}
}
