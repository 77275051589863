import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { firstValueFrom, map, Observable } from 'rxjs';

import { AccessConfigEntity } from '../exposition-access-config/exposition-access-config.entity';
import { ExpositionColumnConfigListEntity } from '../exposition-columns-config/exposition-column-config-list.entity';
import { ExpositionConsumerListEntity } from '../exposition-consumers-list/exposition-consumer-list.entity';
import { ExpositionEndpointMetadataEntity } from '../exposition-endpoint-metadata-config/exposition-endpoint-metadata.entity';
import { ExpositionMetadataEntity } from '../exposition-internal-metadata-config/exposition-metadata.entity';
export enum ExpositionStep {
	Datablock = 0,
	Metadata = 1,
	Columns = 2,
	Access = 3,
}

interface IExpositionStepperState {
	currentStep: ExpositionStep;
	previousStep: ExpositionStep;
	selectedDatablockId: number;
	hasDatablockSelectionChanged: boolean;
	expositionMetadata: ExpositionMetadataEntity;
	endpointMetadata: ExpositionEndpointMetadataEntity;
	columnsConfiguration: ExpositionColumnConfigListEntity;
	accessConfig: AccessConfigEntity;
	stepsValidity: {
		step1: boolean;
		step2: boolean;
		step3: boolean;
		step4: boolean;
	};
}

@Injectable()
export class ExpositionStore extends ComponentStore<IExpositionStepperState> {
	public readonly selectedDatablockId$ = this.state.pipe(
		map((state) => state.selectedDatablockId)
	);
	public readonly hasDatablockSelectionChanged$ = this.state.pipe(
		map((state) => state.hasDatablockSelectionChanged)
	);
	public readonly currentStep$ = this.state.pipe(
		map((state) => state.currentStep)
	);
	public readonly previousStep$ = this.state.pipe(
		map((state) => state.previousStep)
	);

	public readonly metadata$ = this.state.pipe(
		map((state) => state.expositionMetadata)
	);

	public readonly endpointMetadata$ = this.state.pipe(
		map((state) => state.endpointMetadata)
	);

	public readonly stepsValidity$ = this.state.pipe(
		map((state) => state.stepsValidity)
	);
	public readonly isCurrentStepValid$ = this.state.pipe(
		map((state) => {
			if (state.currentStep === 0) {
				return state.stepsValidity.step1;
			}
			if (state.currentStep === 1) {
				return state.stepsValidity.step2;
			}
			if (state.currentStep === 2) {
				return state.stepsValidity.step3;
			}
			if (state.currentStep === 3) {
				return state.stepsValidity.step4;
			}
			return false;
		})
	);
	public readonly columnsConfiguration$ = this.state.pipe(
		map((state) => state.columnsConfiguration)
	);

	public readonly accessConfig$ = this.state.pipe(
		map((state) => state.accessConfig)
	);

	public constructor() {
		super({
			currentStep: ExpositionStep.Datablock,
			previousStep: ExpositionStep.Datablock,
			selectedDatablockId: -1,
			hasDatablockSelectionChanged: false,
			expositionMetadata: ExpositionMetadataEntity.build({}),
			endpointMetadata: ExpositionEndpointMetadataEntity.build({}),
			accessConfig: AccessConfigEntity.build({
				consumers: ExpositionConsumerListEntity.build([
					{
						field: 'avatar',
						alias: '',
						type: '',
						isSearchable: false,
						visibleIndex: 0,
					},
					{
						field: 'name',
						alias: $localize`:@@expositions.access.config.userOrGroupName:`,
						type: 'string',
						isSearchable: true,
						visibleIndex: 1,
					},
				]),
			}),
			columnsConfiguration: ExpositionColumnConfigListEntity.build([]),
			stepsValidity: {
				step1: false,
				step2: false,
				step3: false,
				step4: false,
			},
		});
	}
	private get state(): Observable<IExpositionStepperState> {
		return this.select((state) => state);
	}

	public changeStep(steps: {
		currentStep: number;
		previousStep: number;
	}): void {
		this.patchState({
			previousStep: steps.previousStep,
			currentStep: steps.currentStep,
		});
	}

	public setDatablockId(selectedDatablockId: number): void {
		this.patchState({
			selectedDatablockId,
			hasDatablockSelectionChanged: true, // FIXME: possible de reset les valeurs tout de suite en cas de changement
			// pas besoin de flagger commer modifier si l'action peut eter prise immediatement
		});
	}

	public async setValidity(isValid: boolean): Promise<void> {
		const currentState = await firstValueFrom(this.state);
		let { stepsValidity } = currentState;
		switch (currentState.currentStep) {
			case 0: // FIXME: use enum
				stepsValidity = {
					...currentState.stepsValidity,
					step1: isValid,
					step3: false,
					step4: false,
				};
				break;
			case 1:
				stepsValidity = {
					...currentState.stepsValidity,
					step2: isValid,
				};
				break;
			case 2:
				stepsValidity = {
					...currentState.stepsValidity,
					step3: isValid,
				};
				break;
			case 3:
				stepsValidity = {
					...currentState.stepsValidity,
					step4: isValid,
				};
		}
		this.patchState({
			stepsValidity,
		});
	}

	public setColumnsConfiguration(
		columnsConfiguration: ExpositionColumnConfigListEntity
	): void {
		this.patchState({
			columnsConfiguration,
			hasDatablockSelectionChanged: false,
		});
	}

	public setAccessConfig(accessConfig: AccessConfigEntity): void {
		this.patchState({
			accessConfig,
		});
	}

	public setExpositionMetadata(
		expositionMetadata: ExpositionMetadataEntity
	): void {
		this.patchState({
			expositionMetadata,
		});
	}

	public setEndpointMetadata(
		endpointMetadata: ExpositionEndpointMetadataEntity
	): void {
		this.patchState({
			endpointMetadata,
		});
	}
}
