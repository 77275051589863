import { Injectable } from '@angular/core';
import { CachePolicy, CachePolicyEntity, UrlService } from '@dc-common-core';
import { AppTagItemEntity } from '@dc-common-ui';
import { NEVER, Observable } from 'rxjs';

import { ExportCsvConfigurationEntity } from '../../export-template/components/export-csv-configuration/export-csv-configuration.entity';
import { ExportDbConfigurationEntity } from '../../export-template/components/export-db-configuration/export-db-configuration.entity';
import { ExportExcelConfigurationEntity } from '../../export-template/components/export-excel-configuration/export-excel-configuration.entity';
import {
	ExportJSONConfigEntity,
	ExportNoConfigEntity,
	ExportParquetConfigEntity,
	ExportTemplateDetailsEntity,
} from '../../export-template/components/export-template-details/export-template-details.entity';
import { ExportTxtMetaConfigurationEntity } from '../../export-template/components/export-txt-meta-configuration/export-txt-meta-configuration.entity';
import { ExportXmlConfigurationEntity } from '../../export-template/components/export-xml-configuration/export-xml-configuration.entity';
import { AppMetadataEntity } from '../../ui/components/app-metadata/app-metadata.entity';
import { DcBaseRequester } from '../core/dc-base.requester';
import { ExportCsvTemplateRequester } from './export-csv-template.requester';
import { ExportDatabaseTemplateRequester } from './export-database-template.requester';
import { ExportExcelTemplateRequester } from './export-excel-template.requester';
import { ExportJSONTemplateRequester } from './export-json-template.requester';
import { ExportParquetTemplateRequester } from './export-parquet-template.requester';
import {
	IExportTemplateConfig,
	IExportTemplateResponse,
} from './export-template.models';
import { ExportTxtMetaTemplateRequester } from './export-txt-meta-template.requester';
import { ExportXMLTemplateRequester } from './export-xml-template.requester';

@Injectable({
	providedIn: 'root',
})
export class ExportTemplateRequester extends DcBaseRequester<IExportTemplateResponse> {
	protected resource;
	protected elementsListUrl;
	protected singleElementUrl;
	public constructor(
		private readonly urlService: UrlService,
		private readonly csvTemplateRequester: ExportCsvTemplateRequester,
		private readonly txtMetaTemplateRequester: ExportTxtMetaTemplateRequester,
		private readonly xmlTemplateRequester: ExportXMLTemplateRequester,
		private readonly excelTemplateRequester: ExportExcelTemplateRequester,
		private readonly databaseTemplateRequester: ExportDatabaseTemplateRequester,
		private readonly jsonTemplateRequester: ExportJSONTemplateRequester,
		private readonly parquetTemplateRequester: ExportParquetTemplateRequester
	) {
		super();
		this.resource = this.generateResourceUrl('DATABLOCK_EXPORT_TEMPLATE');
		this.elementsListUrl = this.resource;
		this.singleElementUrl = `${this.elementsListUrl}/{templateId}`;
	}

	public getAvailableTemplates(
		excludePublic: boolean,
		excludeInactive: boolean
	): Observable<ReadonlyArray<IExportTemplateResponse>> {
		const url = this.urlService.generateUrl(
			this.elementsListUrl,
			{},
			{
				project: this.projectId,
				excludePublic,
				excludeInactive,
			}
		);
		return this.get<ReadonlyArray<IExportTemplateResponse>>(
			url,
			CachePolicyEntity.build({
				policy: CachePolicy.NetworkAndCache,
				evictTimeout: 5 * 60,
				isCacheable: true,
			})
		);
	}

	public getAvailableSearchableTemplates(): Observable<
		ReadonlyArray<IExportTemplateResponse>
	> {
		const url = this.urlService.generateUrl(
			this.elementsListUrl,
			{},
			{
				project: this.projectId,
				excludePublic: false,
				excludeInactive: true,
			}
		);
		return this.get<ReadonlyArray<IExportTemplateResponse>>(
			url,
			CachePolicyEntity.build({
				policy: CachePolicy.CacheOnly,
				evictTimeout: 5 * 60,
				isCacheable: true,
			})
		);
	}

	public searchTemplatesByTerm(
		searchTerm: string,
		excludePublic: boolean
	): Observable<ReadonlyArray<IExportTemplateResponse>> {
		const url = this.urlService.generateUrl(
			this.generateResourceUrl('SEARCH_EXPORT_TEMPLATE'),
			{},
			{
				project: this.projectId,
				searchTerm: encodeURIComponent(searchTerm),
				excludePublic,
			}
		);
		return this.get<ReadonlyArray<IExportTemplateResponse>>(
			url,
			CachePolicyEntity.build({
				policy: CachePolicy.CacheOnly,
				evictTimeout: 60,
				isCacheable: true,
			})
		);
	}

	public getById(id: number): Observable<IExportTemplateResponse> {
		const url = this.urlService.generateUrl(
			this.singleElementUrl,
			{
				templateId: id,
			},
			{
				project: this.projectId,
			}
		);
		return this.get<IExportTemplateResponse>(
			url,
			CachePolicyEntity.build({
				isCacheable: true,
				policy: CachePolicy.NetworkOnly,
				evictTimeout: 60,
			})
		);
	}

	public deleteByIds(templateIds: Array<number>): Observable<void> {
		const url = this.urlService.generateUrl(
			this.generateResourceUrl('DATABLOCK_EXPORT_TEMPLATE_DELETE_MULTIPLE'),
			{},
			{
				project: this.projectId,
				templatesIds: templateIds,
			}
		);
		return this.http.delete<void>(url);
	}

	public createExportTemplateConfiguration(payload: {
		templateDetails: ExportTemplateDetailsEntity;
		templateMetadata: AppMetadataEntity;
		templateTags: Array<AppTagItemEntity>;
	}): Observable<IExportTemplateResponse> {
		const exportConfig = payload.templateDetails.config;
		if (exportConfig instanceof ExportCsvConfigurationEntity) {
			return this.csvTemplateRequester.updateTemplate(true, payload);
		} else if (exportConfig instanceof ExportTxtMetaConfigurationEntity) {
			return this.txtMetaTemplateRequester.updateTemplate(true, payload);
		} else if (exportConfig instanceof ExportXmlConfigurationEntity) {
			return this.xmlTemplateRequester.updateTemplate(true, payload);
		} else if (exportConfig instanceof ExportExcelConfigurationEntity) {
			return this.excelTemplateRequester.updateTemplate(true, payload);
		} else if (exportConfig instanceof ExportDbConfigurationEntity) {
			return this.databaseTemplateRequester.updateTemplate(true, payload);
		} else if (exportConfig instanceof ExportJSONConfigEntity) {
			return this.jsonTemplateRequester.updateTemplate(true, payload);
		} else if (exportConfig instanceof ExportParquetConfigEntity) {
			return this.parquetTemplateRequester.updateTemplate(true, payload);
		} else if (exportConfig instanceof ExportNoConfigEntity) {
			console.error('no configuration');
			return NEVER;
		}
		return NEVER;
	}

	public updateExportTemplateConfiguration(
		id: number,
		payload: {
			templateDetails: ExportTemplateDetailsEntity;
			templateMetadata: AppMetadataEntity;
			templateTags: Array<AppTagItemEntity>;
		}
	): Observable<IExportTemplateResponse> {
		const exportConfig = payload.templateDetails.config;
		if (exportConfig instanceof ExportCsvConfigurationEntity) {
			return this.csvTemplateRequester.updateTemplate(false, {
				...payload,
				id,
			});
		} else if (exportConfig instanceof ExportTxtMetaConfigurationEntity) {
			return this.txtMetaTemplateRequester.updateTemplate(false, {
				...payload,
				id,
			});
		} else if (exportConfig instanceof ExportXmlConfigurationEntity) {
			return this.xmlTemplateRequester.updateTemplate(false, {
				...payload,
				id,
			});
		} else if (exportConfig instanceof ExportExcelConfigurationEntity) {
			return this.excelTemplateRequester.updateTemplate(false, {
				...payload,
				id,
			});
		} else if (exportConfig instanceof ExportDbConfigurationEntity) {
			return this.databaseTemplateRequester.updateTemplate(false, {
				...payload,
				id,
			});
		} else if (exportConfig instanceof ExportJSONConfigEntity) {
			return this.jsonTemplateRequester.updateTemplate(false, {
				...payload,
				id,
			});
		} else if (exportConfig instanceof ExportParquetConfigEntity) {
			return this.parquetTemplateRequester.updateTemplate(false, {
				...payload,
				id,
			});
		} else if (exportConfig instanceof ExportNoConfigEntity) {
			console.error('no configuration');
			return NEVER;
		}
		return NEVER;
	}

	public execute(
		clientId: string,
		datablockId: number,
		exportTemplate: ExportTemplateDetailsEntity
	): Observable<number> {
		let url = this.generateResourceUrl(
			'DATA_BLOCK_EXPORT_EXECUTION_TASK_PRIVATE'
		);
		url = this.urlService.generateUrl(
			url,
			{
				datablockId,
			},
			{
				project: this.projectId,
				clientId,
			}
		);
		let body: IExportTemplateConfig = {} as IExportTemplateConfig;
		if (exportTemplate.config instanceof ExportCsvConfigurationEntity) {
			body = this.csvTemplateRequester.generatePayload(exportTemplate);
		} else if (
			exportTemplate.config instanceof ExportTxtMetaConfigurationEntity
		) {
			body = this.txtMetaTemplateRequester.generatePayload(exportTemplate);
		} else if (exportTemplate.config instanceof ExportXmlConfigurationEntity) {
			body = this.xmlTemplateRequester.generatePayload(exportTemplate);
		} else if (
			exportTemplate.config instanceof ExportExcelConfigurationEntity
		) {
			body = this.excelTemplateRequester.generatePayload(exportTemplate);
		} else if (exportTemplate.config instanceof ExportDbConfigurationEntity) {
			body = this.databaseTemplateRequester.generatePayload(exportTemplate);
		} else if (exportTemplate.config instanceof ExportJSONConfigEntity) {
			body = this.jsonTemplateRequester.generatePayload(exportTemplate);
		} else if (exportTemplate.config instanceof ExportParquetConfigEntity) {
			body = this.parquetTemplateRequester.generatePayload(exportTemplate);
		}
		return this.http.post<number>(url, body);
	}
}
