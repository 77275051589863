<app-modal *ngIf="vo$ | async; let vo">
	<button mat-icon-button modal-close-btn>
		<mat-icon
			[svgIcon]="Icons.Close"
			></mat-icon>
	</button>

	<app-exposition-internal-metadata-config
		modal-body
		[expositionMetadata]="vo.metadata"
		[availableTags]="vo.availableTags"
		(hasInternalMetadataChanged)="
			checkValidity()
		"></app-exposition-internal-metadata-config>

	<div modal-footer class="app-expositions-metadata-popover__footer">
		<button *ngIf="vo.canEdit"
			id="{{ cmpId }}-save-btn"
			mat-raised-button
			color="primary"
			[disabled]="!vo.isValid"
			(click)="save()">
			<span i18n="@@general.action.save">save</span>
		</button>
	</div>
</app-modal>
