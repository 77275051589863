<ng-container *ngIf="vo$ | async; let vo">
	<dx-data-grid
		keyExpr="id"
		[elementAttr]="{
			id: 'exposition-column-config',
			class: 'app-exposition-column-config'
		}"
		[repaintChangesOnly]="true"
		[dataSource]="vo.columnConfigList.elements | toArray"
		[hoverStateEnabled]="true"
		[showColumnHeaders]="true"
		[allowColumnResizing]="true"
		[columnAutoWidth]="false"
		[showRowLines]="false"
		[showColumnLines]="false"
		[rowAlternationEnabled]="true"
		[showBorders]="false"
		[width]="'100%'"
		(onSelectionChanged)="onSelectionChange($event)">
		<dxo-selection
			*ngIf="!vo.isInViewMode"
			mode="multiple"
			[showCheckBoxesMode]="'always'"></dxo-selection>

		<dxo-scrolling mode="virtual"></dxo-scrolling>
		<!-- Fixable in position columns	-->
		<dxo-column-fixing [enabled]="true"></dxo-column-fixing>

		<dxo-load-panel
			shadingColor="rgba(0,0,0,0.4)"
			indicatorSrc="/src/img/loader-duotone.svg"
			[height]="100"
			[width]="100"
			[enabled]="true"
			[showIndicator]="true"
			[showPane]="true"
			[shading]="true"></dxo-load-panel>

		<dxo-editing
			mode="cell"
			[allowUpdating]="
				!vo.isInViewMode && !vo.isInAccessConfigMode
			"></dxo-editing>

		<ng-container
			*ngFor="let col of vo.columnConfigList.availableColumns | toArray">
			<dxi-column
				*ngIf="col.field === 'label'"
				[dataField]="col.field"
				[caption]="col.alias"
				[groupIndex]="col.groupIdx"
				[visibleIndex]="col.visibleIndex"
				[fixed]="false"
				[allowResizing]="true"
				[allowHiding]="true"
				[allowGrouping]="true"
				[allowSearch]="true"
				[allowFiltering]="col.isSearchable"
				[allowSorting]="true"
				[format]="col.format"
				[alignment]="'left'"
				[allowEditing]="false"
				[visible]="!vo.isInViewMode && !vo.isInAccessConfigMode"
				[width]="'10%'"></dxi-column>

			<dxi-column
				*ngIf="col.field === 'columnType'"
				[dataField]="col.field"
				[caption]="col.alias"
				[groupIndex]="col.groupIdx"
				[visibleIndex]="col.visibleIndex"
				[fixed]="false"
				[allowResizing]="true"
				[allowHiding]="true"
				[allowGrouping]="true"
				[allowSearch]="col.isSearchable"
				[allowSorting]="true"
				[format]="col.format"
				[alignment]="'left'"
				[allowEditing]="false"
				[width]="60"
				cellTemplate="columnTypeTemplate"></dxi-column>

			<dxi-column
				*ngIf="col.field === 'alias'"
				[dataField]="col.field"
				[caption]="col.alias"
				[groupIndex]="col.groupIdx"
				[visibleIndex]="col.visibleIndex"
				[fixed]="false"
				[allowResizing]="true"
				[allowHiding]="true"
				[allowGrouping]="true"
				[allowSearch]="col.isSearchable"
				[allowSorting]="true"
				[format]="col.format"
				[alignment]="'left'"
				[allowEditing]="false"
				[cellTemplate]="
					vo.isInViewMode || vo.isInAccessConfigMode ? '' : 'aliasTemplate'
				"
				[width]="vo.isInAccessConfigMode ? '50%' : '15%'"></dxi-column>

			<dxi-column
				*ngIf="col.field === 'description'"
				[dataField]="col.field"
				[caption]="col.alias"
				[visible]="!vo.isInAccessConfigMode"
				[groupIndex]="col.groupIdx"
				[visibleIndex]="col.visibleIndex"
				[fixed]="false"
				[allowResizing]="true"
				[allowHiding]="true"
				[allowGrouping]="true"
				[allowSearch]="col.isSearchable"
				[allowSorting]="true"
				[format]="col.format"
				[alignment]="'left'"
				[allowEditing]="false"
				[cellTemplate]="
					vo.isInViewMode || vo.isInAccessConfigMode
						? ''
						: 'descriptionTemplate'
				"
				[width]="'35%'"></dxi-column>

			<dxi-column
				*ngIf="col.field === 'isActive'"
				[dataField]="col.field"
				[caption]="col.alias"
				[groupIndex]="col.groupIdx"
				[visibleIndex]="col.visibleIndex"
				[fixed]="false"
				[allowResizing]="true"
				[allowHiding]="true"
				[allowGrouping]="true"
				[allowSearch]="col.isSearchable"
				[allowSorting]="true"
				[format]="col.format"
				[alignment]="'left'"
				[allowEditing]="false"
				[visible]="!vo.isInViewMode"
				[minWidth]="70"
				[headerCellTemplate]="vo.isInViewMode ? '' : 'activeHeaderTemplate'"
				cellTemplate="activeTemplate"></dxi-column>

			<dxi-column
				*ngIf="col.field === 'isPrimaryKey'"
				[dataField]="col.field"
				[caption]="col.alias"
				[visible]="!vo.isInAccessConfigMode"
				[groupIndex]="col.groupIdx"
				[visibleIndex]="col.visibleIndex"
				[fixed]="false"
				[allowResizing]="true"
				[allowHiding]="true"
				[allowGrouping]="true"
				[allowSearch]="col.isSearchable"
				[allowSorting]="true"
				[format]="col.format"
				[alignment]="'left'"
				[allowEditing]="false"
				[minWidth]="70"
				cellTemplate="idTemplate"></dxi-column>

			<dxi-column
				*ngIf="col.field === 'isFiltered'"
				[dataField]="col.field"
				[caption]="col.alias"
				[visible]="!vo.isInAccessConfigMode"
				[groupIndex]="col.groupIdx"
				[visibleIndex]="col.visibleIndex"
				[fixed]="false"
				[allowResizing]="true"
				[allowHiding]="true"
				[allowGrouping]="true"
				[allowSearch]="col.isSearchable"
				[allowSorting]="true"
				[format]="col.format"
				[alignment]="'left'"
				[allowEditing]="false"
				[minWidth]="70"
				[headerCellTemplate]="vo.isInViewMode ? '' : 'filterHeaderTemplate'"
				cellTemplate="filterTemplate"></dxi-column>

			<dxi-column
				*ngIf="col.field === 'isCaseSensitive'"
				[dataField]="col.field"
				[caption]="col.alias"
				[visible]="!vo.isInAccessConfigMode"
				[groupIndex]="col.groupIdx"
				[visibleIndex]="col.visibleIndex"
				[fixed]="false"
				[allowResizing]="true"
				[allowHiding]="true"
				[allowGrouping]="true"
				[allowSearch]="col.isSearchable"
				[allowSorting]="true"
				[format]="col.format"
				[alignment]="'left'"
				[allowEditing]="false"
				[minWidth]="70"
				[headerCellTemplate]="
					vo.isInViewMode ? '' : 'caseSensitiveHeaderTemplate'
				"
				cellTemplate="caseSensitiveTemplate"></dxi-column>

			<dxi-column
				*ngIf="col.field === 'isHashed'"
				[dataField]="col.field"
				[caption]="col.alias"
				[groupIndex]="col.groupIdx"
				[visibleIndex]="col.visibleIndex"
				[fixed]="false"
				[allowResizing]="true"
				[allowHiding]="true"
				[allowGrouping]="true"
				[allowSearch]="col.isSearchable"
				[allowSorting]="true"
				[format]="col.format"
				[alignment]="'left'"
				[allowEditing]="false"
				[minWidth]="70"
				[headerCellTemplate]="vo.isInViewMode ? '' : 'hashHeaderTemplate'"
				cellTemplate="hashTemplate"></dxi-column>

			<dxi-column
				*ngIf="col.field === 'isHidden'"
				[dataField]="col.field"
				[caption]="col.alias"
				[groupIndex]="col.groupIdx"
				[visibleIndex]="col.visibleIndex"
				[fixed]="false"
				[allowResizing]="true"
				[allowHiding]="true"
				[allowGrouping]="true"
				[allowSearch]="col.isSearchable"
				[allowSorting]="true"
				[format]="col.format"
				[alignment]="'left'"
				[allowEditing]="false"
				[minWidth]="70"
				[headerCellTemplate]="vo.isInViewMode ? '' : 'maskHeaderTemplate'"
				cellTemplate="hideTemplate"></dxi-column>
		</ng-container>

		<div *dxTemplate="let cell of 'aliasTemplate'">
			<dx-text-box
				*ngIf="!vo.isInViewMode && !vo.isInAccessConfigMode"
				(onValueChanged)="updateUsedAlias($event)"
				[value]="cell.data['alias']"
				[disabled]="
					vo.isInViewMode || vo.isInAccessConfigMode || !cell.data['isActive']
				">
				<dx-validator (onValidated)="updateAlias($event, cell.data)">
					<dxi-validation-rule
						type="pattern"
						[pattern]="aliasPattern"
						message="wrong format!"
						i18n-message="
							@@general.form.errors.unauthorizedCharacters"></dxi-validation-rule>
					<dxi-validation-rule
						type="stringLength"
						[max]="255"
						message="max: 255!"
						i18n-message="
							@@general.form.errors.maxlength"></dxi-validation-rule>
					<dxi-validation-rule
						type="stringLength"
						[min]="1"
						message="this field is required!"
						i18n-message="@@general.form.errors.required"></dxi-validation-rule>
				</dx-validator>
			</dx-text-box>
			<span *ngIf="vo.isInViewMode || vo.isInAccessConfigMode">
				{{ cell.data['alias'] }}
			</span>
		</div>

		<div *dxTemplate="let cell of 'descriptionTemplate'">
			<dx-text-box
				*ngIf="!vo.isInViewMode"
				[value]="cell.data['description']"
				[disabled]="
					vo.isInViewMode || vo.isInAccessConfigMode || !cell.data['isActive']
				">
				<dx-validator (onValidated)="updateDescription(cell.key, $event)">
					<dxi-validation-rule
						[max]="2500"
						type="stringLength"
						message="max: 2500!"
						i18n-message="
							@@general.form.errors.maxlength"></dxi-validation-rule>
				</dx-validator>
			</dx-text-box>
			<span *ngIf="vo.isInViewMode">{{ cell.data['description'] }}</span>
		</div>

		<div
			*dxTemplate="let cell of 'activeHeaderTemplate'"
			class="active-header"
			(mouseenter)="hideAllActiveToggle = true"
			(mouseleave)="hideAllActiveToggle = false">
			<div
				[hidden]="hideAllActiveToggle"
				[ngClass]="{
					'active-header-template-error': vo.activeColumnsCount === 0
				}">
				<span i18n="@@exposition.columns.config.column.active">active</span>
				({{ vo.activeColumnsCount }})
			</div>
			<div [hidden]="!hideAllActiveToggle" (click)="toggleAllTriggered($event)">
				<mat-slide-toggle
					id="{{ cmpId }}-{{ cell.rowIndex }}-activate-all-toggle"
					color="primary"
					[disabled]="vo.isInViewMode"
					[checked]="vo.columnConfigList.allActive"
					(change)="toggleActiveAllRows()"></mat-slide-toggle>
			</div>
		</div>

		<div
			*dxTemplate="let cell of 'columnTypeTemplate'"
			class="column-type-template">
			<app-column-type
				[type]="cell.data['columnType']"
				[isList]="cell.data['isList']"></app-column-type>
		</div>

		<div *dxTemplate="let cell of 'activeTemplate'">
			<mat-slide-toggle
				id="{{ cmpId }}-{{ cell.rowIndex }}-activate-toggle"
				color="primary"
				[disabled]="vo.isInViewMode"
				[checked]="cell.data['isActive']"
				(change)="
					updateIsActive([cell.key], $event.checked)
				"></mat-slide-toggle>
		</div>

		<div *dxTemplate="let cell of 'idTemplate'; let i = index">
			<mat-checkbox
				id="{{ cmpId }}-{{ cell.rowIndex }}-id-checkbox"
				color="primary"
				[disabled]="
					vo.isInViewMode ||
					cell.data['isList'] ||
					cell.data['columnType'] === ColumnType.Bool ||
					!cell.data['isActive']
				"
				[checked]="cell.data['isPrimaryKey']"
				(change)="updateIsPrimaryKey(cell.data, $event.checked)"></mat-checkbox>
		</div>

		<div *dxTemplate="let cell of 'filterTemplate'; let i = index">
			<mat-checkbox
				id="{{ cmpId }}-{{ cell.rowIndex }}-filter-checkbox"
				color="primary"
				[disabled]="
					vo.isInViewMode ||
					!cell.data['isActive'] ||
					cell.data['isList']
				"
				[checked]="cell.data['isFiltered']"
				(change)="updateIsFiltered([cell.key], $event.checked)"></mat-checkbox>
		</div>

		<div
			*dxTemplate="let cell of 'filterHeaderTemplate'"
			class="filter-header"
			(mouseenter)="hideAllFilterToggle = true"
			(mouseleave)="hideAllFilterToggle = false">
			<div [hidden]="hideAllFilterToggle">
				<span i18n="@@exposition.columns.config.column.filterable">filter</span>
			</div>
			<div [hidden]="!hideAllFilterToggle" (click)="toggleAllTriggered($event)">
				<mat-checkbox
					id="{{ cmpId }}-{{ cell.rowIndex }}-filter-all-toggle"
					color="primary"
					[disabled]="vo.isInViewMode || vo.activeColumnsCount === 0"
					[checked]="vo.columnConfigList.allFilter"
					(change)="toggleFilterAllRows()"></mat-checkbox>
			</div>
		</div>

		<div *dxTemplate="let cell of 'caseSensitiveTemplate'; let i = index">
			<mat-checkbox
				id="{{ cmpId }}-{{ cell.rowIndex }}-case-insensitive-checkbox"
				color="primary"
				[disabled]="
					vo.isInViewMode ||
					cell.data['isList'] ||
					cell.data['columnType'] !== ColumnType.Str ||
					!cell.data['isActive']
				"
				[checked]="cell.data['isCaseSensitive']"
				(change)="
					updateIsCaseSensitive([cell.key], $event.checked)
				"></mat-checkbox>
		</div>

		<div
			*dxTemplate="let cell of 'caseSensitiveHeaderTemplate'"
			class="case-sensitive-header"
			(mouseenter)="hideAllIcToggle = true"
			(mouseleave)="hideAllIcToggle = false">
			<div [hidden]="hideAllIcToggle">
				<span i18n="@@exposition.columns.config.column.caseSensitive">ic</span>
			</div>
			<div [hidden]="!hideAllIcToggle" (click)="toggleAllTriggered($event)">
				<mat-checkbox
					id="{{ cmpId }}-{{ cell.rowIndex }}-ic-all-toggle"
					color="primary"
					[disabled]="vo.isInViewMode || vo.activeColumnsCount === 0"
					[checked]="vo.columnConfigList.allIc"
					(change)="toggleIcAllRows()"></mat-checkbox>
			</div>
		</div>

		<div *dxTemplate="let cell of 'hashTemplate'; let i = index">
			<mat-checkbox
				*ngIf="vo.isInAccessConfigMode"
				id="{{ cmpId }}-{{ cell.rowIndex }}-hash-checkbox"
				color="primary"
				[disabled]="
					!cell.data['isActive'] ||
					!vo.columnConfigList.hashedColumns.includes(cell.key) ||
					cell.data['columnType'] !== ColumnType.Str
				"
				[checked]="cell.data['isHashed']"
				(change)="updateIsHashed([cell.key], $event.checked)"></mat-checkbox>

			<mat-checkbox
				*ngIf="!vo.isInAccessConfigMode"
				id="{{ cmpId }}-{{ cell.rowIndex }}-hash-checkbox"
				color="primary"
				[disabled]="
					vo.isInViewMode ||
					!cell.data['isActive'] ||
					cell.data['columnType'] !== ColumnType.Str
				"
				[checked]="cell.data['isHashed']"
				(change)="updateIsHashed([cell.key], $event.checked)"></mat-checkbox>
		</div>

		<div
			*dxTemplate="let cell of 'hashHeaderTemplate'"
			class="hash-header"
			(mouseenter)="hideAllHashToggle = true"
			(mouseleave)="hideAllHashToggle = false">
			<div [hidden]="hideAllHashToggle">
				<span
					*ngIf="!vo.isInAccessConfigMode"
					i18n="@@exposition.columns.config.column.hashable">
					ic
				</span>
				<span
					*ngIf="vo.isInAccessConfigMode"
					i18n="@@exposition.columns.config.column.hash">
					ic
				</span>
			</div>
			<div [hidden]="!hideAllHashToggle" (click)="toggleAllTriggered($event)">
				<mat-checkbox
					id="{{ cmpId }}-{{ cell.rowIndex }}-hash-all-toggle"
					color="primary"
					[disabled]="
						vo.isInViewMode ||
						vo.activeColumnsCount === 0 ||
						!vo.columnConfigList.hashedColumns.length
					"
					[checked]="vo.columnConfigList.allHash"
					(change)="toggleHashAllRows()"></mat-checkbox>
			</div>
		</div>

		<div *dxTemplate="let cell of 'hideTemplate'; let i = index">
			<mat-checkbox
				*ngIf="vo.isInAccessConfigMode"
				id="{{ cmpId }}-{{ cell.rowIndex }}-hide-checkbox"
				color="primary"
				[disabled]="true"
				[checked]="
					cell.data['isActive']
						? vo.columnConfigList.hiddenColumns.includes(cell.key)
						: cell.data['isHidden']
				"></mat-checkbox>

			<mat-checkbox
				*ngIf="!vo.isInAccessConfigMode"
				id="{{ cmpId }}-{{ cell.rowIndex }}-hide-checkbox"
				color="primary"
				[disabled]="
					vo.isInViewMode || !cell.data['isActive'] || cell.data['isList']
				"
				[checked]="cell.data['isHidden']"
				(change)="updateIsHidden([cell.key], $event.checked)"></mat-checkbox>
		</div>

		<div
			*dxTemplate="let cell of 'maskHeaderTemplate'"
			class="mask-header"
			(mouseenter)="hideAllMaskToggle = true"
			(mouseleave)="hideAllMaskToggle = false">
			<span
				*ngIf="vo.isInAccessConfigMode"
				i18n="@@exposition.columns.config.column.hidden">
				ic
			</span>
			<ng-container *ngIf="!vo.isInAccessConfigMode">
				<div [hidden]="hideAllMaskToggle">
					<span i18n="@@exposition.columns.config.column.hide">ic</span>
				</div>
				<div [hidden]="!hideAllMaskToggle" (click)="toggleAllTriggered($event)">
					<mat-checkbox
						id="{{ cmpId }}-{{ cell.rowIndex }}-mask-all-toggle"
						color="primary"
						[disabled]="vo.isInViewMode || vo.activeColumnsCount === 0"
						[checked]="vo.columnConfigList.allHidden"
						(change)="toggleMaskAllRows()"></mat-checkbox>
				</div>
			</ng-container>
		</div>

		<dxo-group-panel [visible]="false"></dxo-group-panel>

		<!-- Search input -->
		<dxo-search-panel [visible]="false"></dxo-search-panel>

		<!-- Sorting	-->
		<dxo-sorting mode="multiple"></dxo-sorting>

		<!-- Pagination -->
		<dxo-paging [pageSize]="1000"></dxo-paging>

		<dxo-pager [visible]="false"></dxo-pager>
		<!-- State persistence	-->
		<dxo-state-storing
			type="localStorage"
			[enabled]="false"
			[storageKey]="dxLocalStorageKey"></dxo-state-storing>
	</dx-data-grid>
</ng-container>
