import { CommonModule } from '@angular/common';
import { Injector, NgModule } from '@angular/core';
import { createCustomElement } from '@angular/elements';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DcLibUiModule } from '@dc-common-ui';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { MaterialModule } from '../ui/material.module';
import { UiModule } from '../ui/ui.module';
import { ExportCsvConfigurationComponent } from './components/export-csv-configuration/export-csv-configuration.component';
import { ExportDatablockModalComponent } from './components/export-datablock-modal/export-datablock-modal.component';
import { ExportDbConfigurationComponent } from './components/export-db-configuration/export-db-configuration.component';
import { ExportExcelConfigurationComponent } from './components/export-excel-configuration/export-excel-configuration.component';
import { ExportFileConfigurationComponent } from './components/export-file-configuration/export-file-configuration.component';
import { ExportPartitionConfigurationComponent } from './components/export-partition-configuration/export-partition-configuration.component';
import { ExportTemplateDeleteConfirmationComponent } from './components/export-template-delete-confirmation/export-template-delete-confirmation.component';
import { ExportTemplateDetailsComponent } from './components/export-template-details/export-template-details.component';
import { ExportTemplateComponent } from './components/export-template/export-template.component';
import { ExportTxtMetaConfigurationComponent } from './components/export-txt-meta-configuration/export-txt-meta-configuration.component';
import { ExportWriteModeConfigurationComponent } from './components/export-write-mode-configuration/export-write-mode-configuration.component';
import { ExportXmlConfigurationComponent } from './components/export-xml-configuration/export-xml-configuration.component';
import { ExportTemplateAdapter } from './export-template.adapter';
import { ExportTemplateParser } from './export-template.parser';
import { NgExportTemplate } from './ng-export-template';
import {
	ExportTemplateEffects,
	ExportTemplateSelector,
	ExportTemplateState,
	exportTemplateReducer,
} from './store';

@NgModule({
	declarations: [
		ExportTemplateDetailsComponent,
		ExportCsvConfigurationComponent,
		ExportTxtMetaConfigurationComponent,
		ExportPartitionConfigurationComponent,
		ExportXmlConfigurationComponent,
		ExportFileConfigurationComponent,
		ExportExcelConfigurationComponent,
		ExportWriteModeConfigurationComponent,
		ExportDbConfigurationComponent,
		ExportTemplateComponent,
		ExportDatablockModalComponent,
		ExportTemplateDeleteConfirmationComponent,
	],
	imports: [
		CommonModule,
		MaterialModule,
		StoreModule.forFeature(ExportTemplateState.key, exportTemplateReducer),
		EffectsModule.forFeature([ExportTemplateEffects]),
		FormsModule,
		ReactiveFormsModule,
		UiModule,
		DcLibUiModule,
	],
	providers: [
		ExportTemplateSelector,
		ExportTemplateAdapter,
		ExportTemplateParser,
		NgExportTemplate,
	],
})
export class ExportTemplateModule {
	public constructor(private readonly injector: Injector) {
		const exportModelDetailsCmpElement = createCustomElement(
			ExportTemplateDetailsComponent,
			{
				injector,
			}
		);
		customElements.define(
			'app-export-template-details-cmp',
			exportModelDetailsCmpElement
		);

		const exportModelCmpElement = createCustomElement(ExportTemplateComponent, {
			injector,
		});
		customElements.define('app-export-template', exportModelCmpElement);
	}
}
