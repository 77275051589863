import {
	IListAttrs,
	IListColumnConfig,
	IListOperations,
} from '@dc-common-core';
import { immerable, produce } from 'immer';
import { Map } from 'immutable';

import { MigrationReadyExpositionItemEntity } from './migration-ready-exposition-item.entity';

export interface IMigrationReadyExpositionListExtra
	extends IListColumnConfig<MigrationReadyExpositionItemEntity> {
	isFixed?: boolean;
	isSelectable?: boolean;
	isSearchable?: boolean;
	isInGroup?: boolean;
	isVisible?: boolean;
	canBeHidden?: boolean;
	canBeReordered?: boolean;
	canBeChosen?: boolean;
	format?: string;
	visibleIndex?: number;
	visible?: boolean;
	hasHeaderFilter?: boolean;
}

interface IMigrationReadyExpositionListEntity
	extends IListAttrs<MigrationReadyExpositionItemEntity> {
	availableColumns: Map<string, IMigrationReadyExpositionListExtra>;
}

const entityDefaults: IMigrationReadyExpositionListEntity = {
	elements: Map(),
	availableColumns: Map(),
};

export class MigrationReadyExpositionList
	implements
		IMigrationReadyExpositionListEntity,
		IListOperations<
			MigrationReadyExpositionList,
			MigrationReadyExpositionItemEntity
		>
{
	private [immerable] = true;
	public elements = entityDefaults.elements;
	public availableColumns = entityDefaults.availableColumns;

	public static build(
		availableCols: Array<IMigrationReadyExpositionListExtra>
	): MigrationReadyExpositionList {
		const inst = new MigrationReadyExpositionList();
		const cols = availableCols.reduce((acc, curr) => {
			acc.push([curr.field, curr]);
			return acc;
		}, new Array<[string, IMigrationReadyExpositionListExtra]>());
		inst.availableColumns = Map(cols);
		return inst;
	}

	private constructor() {}

	public getAvailableColumns(): Map<
		string,
		IListColumnConfig<MigrationReadyExpositionItemEntity>
	> {
		return this.availableColumns;
	}

	public setElements(
		elems: Map<string, MigrationReadyExpositionItemEntity>
	): MigrationReadyExpositionList {
		return produce(this, (draft: MigrationReadyExpositionList) => {
			draft.elements = elems;
		});
	}

	public getSelected(): Array<MigrationReadyExpositionItemEntity> {
		return this.elements
			.filter((e) => e.isSelected)
			.toList()
			.toArray();
	}

	public select(
		ids: Array<number>,
		isSelected: boolean
	): MigrationReadyExpositionList {
		// ids.forEach((id) => this.updateSelected(id));
		return produce(this, (draft: MigrationReadyExpositionList) => {
			draft.elements = ids.reduce((acc, curr) => {
				const item = acc.get(`${curr}`) as MigrationReadyExpositionItemEntity;
				if (item) {
					acc = acc.set(`${curr}`, item.toggleSelection(isSelected));
					return acc;
				}
				return acc;
			}, this.elements);
		});
	}
}
