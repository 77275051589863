import { AvatarEntity } from '@dc-common-ui';
import { immerable } from 'immer';

export enum AccessType {
	Open = 'open',
	Limited = 'limited',
}
interface IExpositionAccessEntity {
	id: string;
	label: string;
	columnsCount: number;
	usersCount: number;
	groupsCount: number;
	linesCount: number;
	columns: Array<{ label: string; type: string }>;
	users: Array<{ label: string; avatar: AvatarEntity }>;
	groups: Array<{ label: string; avatar: AvatarEntity }>;
	lines: string;
	followUp: string;
	accessType: AccessType;
	isActive: boolean;
}

const entityDefaults: IExpositionAccessEntity = {
	id: '',
	label: '',
	columnsCount: 0,
	usersCount: 0,
	groupsCount: 0,
	linesCount: 0,
	columns: [],
	users: [],
	groups: [],
	lines: '',
	followUp: '',
	accessType: AccessType.Limited,
	isActive: false,
};

export class ExpositionAccessEntity implements IExpositionAccessEntity {
	private [immerable] = true;
	public id = entityDefaults.id;
	public label = entityDefaults.label;
	public columnsCount = entityDefaults.columnsCount;
	public usersCount = entityDefaults.usersCount;
	public groupsCount = entityDefaults.groupsCount;
	public linesCount = entityDefaults.linesCount;
	public columns = entityDefaults.columns;
	public users = entityDefaults.users;
	public groups = entityDefaults.groups;
	public followUp = entityDefaults.followUp;
	public lines = entityDefaults.lines;
	public accessType = entityDefaults.accessType;
	public isActive = entityDefaults.isActive;

	public static build(
		params: Partial<IExpositionAccessEntity> = {}
	): ExpositionAccessEntity {
		const instance = new ExpositionAccessEntity();
		instance.id = params.id ?? entityDefaults.id;
		instance.label = params.label ?? entityDefaults.label;
		instance.columnsCount = params.columnsCount ?? entityDefaults.columnsCount;
		instance.usersCount = params.usersCount ?? entityDefaults.usersCount;
		instance.groupsCount = params.groupsCount ?? entityDefaults.groupsCount;
		instance.linesCount = params.linesCount ?? entityDefaults.linesCount;
		instance.columns = params.columns ?? entityDefaults.columns;
		instance.users = params.users ?? entityDefaults.users;
		instance.groups = params.groups ?? entityDefaults.groups;
		instance.lines = params.lines ?? entityDefaults.lines;
		instance.followUp = params.followUp ?? entityDefaults.followUp;
		instance.accessType = params.accessType ?? entityDefaults.accessType;
		instance.isActive = params.isActive ?? entityDefaults.isActive;
		return instance;
	}
}
