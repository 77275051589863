import {
	IListAttrs,
	IListColumnConfig,
	IListOperations,
} from '@dc-common-core';
import { immerable, produce } from 'immer';
import { Map } from 'immutable';

import { ExpositionHistoryItemEntity } from './exposition-history-item.entity';

export interface IExpositionListExtra
	extends IListColumnConfig<ExpositionHistoryItemEntity> {
	isFixed?: boolean;
	isSelectable?: boolean;
	isSearchable?: boolean;
	isInGroup?: boolean;
	isVisible?: boolean;
	canBeHidden?: boolean;
	canBeReordered?: boolean;
	canBeChosen?: boolean;
	format?: string;
	visibleIndex?: number;
	visible?: boolean;
	hasHeaderFilter?: boolean;
}

interface IExpositionHistoryListEntity
	extends IListAttrs<ExpositionHistoryItemEntity> {
	availableColumns: Map<string, IExpositionListExtra>;
}

const entityDefaults: IExpositionHistoryListEntity = {
	elements: Map(),
	availableColumns: Map(),
};

export class ExpositionHistoryListEntity
	implements
		IExpositionHistoryListEntity,
		IListOperations<ExpositionHistoryListEntity, ExpositionHistoryItemEntity>
{
	private [immerable] = true;
	public elements = entityDefaults.elements;
	public availableColumns = entityDefaults.availableColumns;

	public static build(
		availableCols: Array<IExpositionListExtra>
	): ExpositionHistoryListEntity {
		const inst = new ExpositionHistoryListEntity();
		const cols = availableCols.reduce((acc, curr) => {
			acc.push([curr.field, curr]);
			return acc;
		}, new Array<[string, IExpositionListExtra]>());
		inst.availableColumns = Map(cols);
		return inst;
	}

	private constructor() {}

	public getAvailableColumns(): Map<
		string,
		IListColumnConfig<ExpositionHistoryItemEntity>
	> {
		return this.availableColumns;
	}

	public setElements(
		elems: Map<string, ExpositionHistoryItemEntity>
	): ExpositionHistoryListEntity {
		return produce(this, (draft: ExpositionHistoryListEntity) => {
			draft.elements = elems;
		});
	}

	public updateJobLog(
		elementId: string,
		value: string
	): ExpositionHistoryListEntity {
		const elem = this.elements.get(elementId);
		if (!elem) {
			return this;
		}
		const elems = this.elements.set(elementId, elem.updateJobLog(value));
		return this.setElements(elems);
	}
}
