import { Injectable } from '@angular/core';
import { UrlService } from '@dc-common-core';
import {
	AppTagItemEntity,
	CachePolicy,
	CachePolicyEntity,
	ColumnType,
} from '@dc-common-ui';
import { Map } from 'immutable';
import { Observable, of, tap } from 'rxjs';

import {
	AccessConfigEntity,
	AccessType,
} from '../../expositions/components/exposition-access-config/exposition-access-config.entity';
import { ExpositionColumnConfigEntity } from '../../expositions/components/exposition-columns-config/exposition-column-config.entity';
import { ExpositionConsumerEntity } from '../../expositions/components/exposition-consumers-list/exposition-consumer.entity';
import { ExpositionEndpointMetadataEntity } from '../../expositions/components/exposition-endpoint-metadata-config/exposition-endpoint-metadata.entity';
import { ExpositionMetadataEntity } from '../../expositions/components/exposition-internal-metadata-config/exposition-metadata.entity';
import { ExpositionPreviousConsumerEntity } from '../../expositions/components/exposition-migration-stepper/exposition-previous-consumer.entity';
import { DcBaseRequester } from '../core/dc-base.requester';
import { IJobTracking } from '../core/job-tracking';
import { IDcMetadataResponse, IMetadataTag } from '../core/metadata.model';
import { IViewMembers } from '../core/view-members.model';
import { IExpositionPublicationAccessConfig } from './draft-publication.model';
import { IExpositionColumnConfig } from './exposition-column-config.model';
import { IExpositionCreate } from './exposition-create.model';
import { IExpositionResponse } from './exposition.model';
import {
	IConsumerForDatablockExposition,
	IEligibleForMigration,
	IMigratedExposition,
} from './migrated-exposition.model';
import { IPublicMetadata } from './public-metadata.model';
import { IPublicationHistoryDetails } from './publication-history.model';

@Injectable({
	providedIn: 'root',
})
export class ExpositionRequester extends DcBaseRequester<IExpositionResponse> {
	protected resource;
	protected elementsListUrl;
	protected singleElementUrl;

	public constructor(private readonly urlService: UrlService) {
		super();
		this.resource = this.generateResourceUrl('EXPOSITIONS_GET_ALL');
		this.elementsListUrl = this.resource;
		this.singleElementUrl = this.resource;
	}

	public getAllExpositions(): Observable<ReadonlyArray<IExpositionResponse>> {
		const url = this.urlService.generateUrl(
			this.resource,
			{},
			{
				project: this.projectId,
			}
		);
		return this.get<ReadonlyArray<IExpositionResponse>>(url);
	}

	public createExposition(payload: {
		datablockId: number;
		metadata: ExpositionMetadataEntity;
		endpointMetadata: ExpositionEndpointMetadataEntity;
		columnsConfig: Array<ExpositionColumnConfigEntity>;
		accessConfig: AccessConfigEntity;
	}): Observable<IExpositionResponse> {
		const body = this.generateExpositionPayloadForSave(
			payload.datablockId,
			payload.metadata,
			payload.endpointMetadata,
			payload.columnsConfig,
			payload.accessConfig
		);
		const resourceUrl = this.generateResourceUrl('EXPOSITIONS_GET_ALL');
		const url = this.urlService.generateUrl(
			resourceUrl,
			{},
			{
				project: this.projectId,
			}
		);
		return this.http.post<IExpositionResponse>(url, body);
	}

	public getExposition(id: number): Observable<IExpositionResponse> {
		const resourceUrl = this.generateResourceUrl('EXPOSITIONS_GET');
		const url = this.urlService.generateUrl(
			resourceUrl,
			{
				id,
			},
			{
				project: this.projectId,
			}
		);
		return this.get<IExpositionResponse>(url);
	}

	public getExpositionHistory(
		id: number
	): Observable<Array<IPublicationHistoryDetails>> {
		const resourceUrl = this.generateResourceUrl('EXPOSITIONS_HISTORY_LIST');
		const url = this.urlService.generateUrl(
			resourceUrl,
			{
				id,
			},
			{
				project: this.projectId,
			}
		);
		return this.get<Array<IPublicationHistoryDetails>>(url);
	}

	public getJobErrorMessage(
		expositionId: number,
		historyId: number
	): Observable<string> {
		const resourceUrl = this.generateResourceUrl(
			'EXPOSITIONS_HISTORY_ERROR_MSG'
		);
		const url = this.urlService.generateUrl(
			resourceUrl,
			{
				id: expositionId,
				historyId,
			},
			{
				project: this.projectId,
			}
		);
		return this.http.get(url, {
			responseType: 'text',
		});
	}

	public getExpositionMetadata(id: number): Observable<IDcMetadataResponse> {
		const resourceUrl = this.generateResourceUrl('EXPOSITIONS_METADATA');
		const url = this.urlService.generateUrl(
			resourceUrl,
			{
				id,
			},
			{
				project: this.projectId,
			}
		);
		return this.get<IDcMetadataResponse>(url);
	}

	public updateExpositionMetadata(
		id: number,
		metadata: ExpositionMetadataEntity
	): Observable<IDcMetadataResponse> {
		const resourceUrl = this.generateResourceUrl('EXPOSITIONS_METADATA');
		const body: IDcMetadataResponse = {
			label: metadata.label,
			description: metadata.description,
			tags: this.generateMetadataTagPayload(metadata.tags.toArray()),
			actif: false,
		};
		const url = this.urlService.generateUrl(
			resourceUrl,
			{
				id,
			},
			{
				project: this.projectId,
			}
		);
		return this.http.put<IDcMetadataResponse>(url, body);
	}

	public getExpositionEndpointMetadata(
		id: number
	): Observable<IPublicMetadata> {
		const resourceUrl = this.generateResourceUrl('EXPOSITIONS_PUBLIC_METADATA');
		const url = this.urlService.generateUrl(
			resourceUrl,
			{
				id,
			},
			{
				project: this.projectId,
			}
		);
		return this.get<IPublicMetadata>(url);
	}

	public updateExpositionEndpointMetadata(
		id: number,
		metadata: ExpositionEndpointMetadataEntity
	): Observable<IPublicMetadata> {
		const resourceUrl = this.generateResourceUrl('EXPOSITIONS_PUBLIC_METADATA');
		const body: IPublicMetadata = {
			title: metadata.title,
			details: metadata.details,
			// eslint-disable-next-line camelcase
			access_point: metadata.accessPoint,
			keywords: this.generateKeywordsPayload(metadata.keywords.toArray()),
		};
		const url = this.urlService.generateUrl(
			resourceUrl,
			{
				id,
			},
			{
				project: this.projectId,
			}
		);
		return this.http.put<IPublicMetadata>(url, body);
	}

	public getExpositionColumns(
		id: number
	): Observable<Array<IExpositionColumnConfig>> {
		const resourceUrl = this.generateResourceUrl('EXPOSITIONS_PUBLIC_COLUMNS');
		const url = this.urlService.generateUrl(
			resourceUrl,
			{
				id,
			},
			{
				project: this.projectId,
			}
		);
		return this.get<Array<IExpositionColumnConfig>>(
			url,
			CachePolicyEntity.build({
				policy: CachePolicy.NetworkOnly,
				evictTimeout: 5 * 60,
				isCacheable: true,
			})
		);
	}

	public updateExpositionColumns(
		id: number,
		columns: Array<ExpositionColumnConfigEntity>
	): Observable<Array<IExpositionColumnConfig>> {
		const resourceUrl = this.generateResourceUrl('EXPOSITIONS_PUBLIC_COLUMNS');
		const body = this.generateColumnsConfigPayload(columns);
		const url = this.urlService.generateUrl(
			resourceUrl,
			{
				id,
			},
			{
				project: this.projectId,
			}
		);
		return this.http.put<Array<IExpositionColumnConfig>>(url, body);
	}

	public getExpositionAccess(
		expositionId: number,
		accessId: string
	): Observable<IExpositionPublicationAccessConfig> {
		const resourceUrl = this.generateResourceUrl(
			'EXPOSITIONS_PUBLIC_ACCESS_BY_ID'
		);
		const url = this.urlService.generateUrl(
			resourceUrl,
			{
				id: expositionId,
				accessId,
			},
			{
				project: this.projectId,
			}
		);
		return this.get<IExpositionPublicationAccessConfig>(url);
	}

	public updateExpositionAccess(
		expositionId: number,
		access: AccessConfigEntity
	): Observable<IExpositionPublicationAccessConfig> {
		const resourceUrl = this.generateResourceUrl(
			'EXPOSITIONS_PUBLIC_ACCESS_BY_ID'
		);
		const body = this.generateAccessConfigPayload(access);
		const url = this.urlService.generateUrl(
			resourceUrl,
			{
				id: expositionId,
				accessId: access.id,
			},
			{
				project: this.projectId,
			}
		);
		return this.http.put<IExpositionPublicationAccessConfig>(url, body);
	}

	public createExpositionAccess(
		expositionId: number,
		access: AccessConfigEntity
	): Observable<IExpositionPublicationAccessConfig> {
		const resourceUrl = this.generateResourceUrl('EXPOSITIONS_PUBLIC_ACCESS');
		const body = this.generateAccessConfigPayload(access);
		const url = this.urlService.generateUrl(
			resourceUrl,
			{
				id: expositionId,
			},
			{
				project: this.projectId,
			}
		);
		return this.http.post<IExpositionPublicationAccessConfig>(url, body);
	}

	public deleteExpositionAccess(
		expositionId: number,
		accessId: string
	): Observable<void> {
		const resourceUrl = this.generateResourceUrl(
			'EXPOSITIONS_PUBLIC_ACCESS_BY_ID'
		);
		const url = this.urlService.generateUrl(
			resourceUrl,
			{
				id: expositionId,
				accessId,
			},
			{
				project: this.projectId,
			}
		);
		return this.http.delete<void>(url);
	}

	public publishExposition(
		clientId: string,
		expositionId: number
	): Observable<IJobTracking> {
		const resourceUrl = this.generateResourceUrl('EXPOSITIONS_PUBLISH');
		const url = this.urlService.generateUrl(
			resourceUrl,
			{
				id: expositionId,
			},
			{
				project: this.projectId,
				clientId,
			}
		);
		return this.http.post<IJobTracking>(url, {});
	}

	public updateExpositionData(
		clientId: string,
		expositionId: number
	): Observable<IJobTracking> {
		const resourceUrl = this.generateResourceUrl('EXPOSITIONS_PUBLISH');
		const url = this.urlService.generateUrl(
			resourceUrl,
			{
				id: expositionId,
			},
			{
				project: this.projectId,
				clientId,
			}
		);
		return this.http.put<IJobTracking>(url, {});
	}

	public clearExpositionData(expositionId: number): Observable<void> {
		const resourceUrl = this.generateResourceUrl('EXPOSITIONS_DATA_CLEAR');
		const url = this.urlService.generateUrl(
			resourceUrl,
			{
				id: expositionId,
			},
			{
				project: this.projectId,
			}
		);
		return this.http.delete<void>(url);
	}

	public activateExposition(expositionId: number): Observable<void> {
		const resourceUrl = this.generateResourceUrl('EXPOSITIONS_ENABLE');
		const url = this.urlService.generateUrl(
			resourceUrl,
			{
				id: expositionId,
			},
			{
				project: this.projectId,
			}
		);
		return this.http.put<void>(url, {});
	}

	public deactivateExposition(expositionId: number): Observable<void> {
		const resourceUrl = this.generateResourceUrl('EXPOSITIONS_DISABLE');
		const url = this.urlService.generateUrl(
			resourceUrl,
			{
				id: expositionId,
			},
			{
				project: this.projectId,
			}
		);
		return this.http.put<void>(url, {});
	}

	public updateExpositionParams(
		clientId: string,
		expositionId: number,
		updateMetadata: boolean,
		updateColumns: boolean,
		updateAccess: boolean
	): Observable<IJobTracking> {
		const resourceUrl = this.generateResourceUrl('EXPOSITIONS_PARAMS');
		const url = this.urlService.generateUrl(
			resourceUrl,
			{
				id: expositionId,
			},
			{
				project: this.projectId,
				clientId,
				updateMetadata,
				updateColumns,
				updateAccess,
			}
		);
		return this.http.put<IJobTracking>(url, {});
	}

	public deleteExposition(expositionId: number): Observable<void> {
		const resourceUrl = this.generateResourceUrl('EXPOSITIONS_DELETE');
		const url = this.urlService.generateUrl(
			resourceUrl,
			{
				id: expositionId,
			},
			{
				project: this.projectId,
			}
		);
		return this.http.delete<void>(url);
	}

	public getMigratedExpositionsList(
		caching = {
			noCache: false,
		}
	): Observable<ReadonlyArray<IMigratedExposition>> {
		const resourceUrl = this.generateResourceUrl('EXPOSITIONS_MIGRATED_LIST');
		const url = this.urlService.generateUrl(
			resourceUrl,
			{},
			{
				project: this.projectId,
			}
		);
		const cachePolicy = caching.noCache
			? CachePolicyEntity.build({
					policy: CachePolicy.NetworkOnly,
					evictTimeout: 0,
					isCacheable: false,
			  })
			: CachePolicyEntity.build({
					policy: CachePolicy.NetworkAndCache,
					evictTimeout: 60,
					isCacheable: true,
			  });

		return this.get<ReadonlyArray<IMigratedExposition>>(url, cachePolicy);
	}

	public getExpositionsForMigrationList(
		caching = {
			noCache: false,
		}
	): Observable<ReadonlyArray<IEligibleForMigration>> {
		const resourceUrl = this.generateResourceUrl('EXPOSITIONS_MIGRATION_LIST');
		const url = this.urlService.generateUrl(
			resourceUrl,
			{},
			{
				project: this.projectId,
			}
		);
		const cachePolicy = caching.noCache
			? CachePolicyEntity.build({
					policy: CachePolicy.NetworkOnly,
					evictTimeout: 0,
					isCacheable: false,
			  })
			: CachePolicyEntity.build({
					policy: CachePolicy.NetworkAndCache,
					evictTimeout: 60,
					isCacheable: true,
			  });

		return this.get<Array<IEligibleForMigration>>(url, cachePolicy);
	}

	public getConsumersForExposedDatablocks(
		dbIds: Array<number>
	): Observable<IConsumerForDatablockExposition> {
		const resourceUrl = this.generateResourceUrl(
			'EXPOSITIONS_MIGRATION_LIST_MEMBERS'
		);
		const url = this.urlService.generateUrl(
			resourceUrl,
			{},
			{
				selectedDbs: dbIds,
				project: this.projectId,
			}
		);

		return this.get<IConsumerForDatablockExposition>(
			url,
			CachePolicyEntity.build({
				policy: CachePolicy.NetworkOnly,
				evictTimeout: 0,
				isCacheable: false,
			})
		);
	}

	public mapConsumers(
		requestId: string,
		mapping: Map<string, ExpositionPreviousConsumerEntity>,
		availableConsumers: Array<ExpositionConsumerEntity>
	): Observable<void> {
		const tupleOfConsumers = availableConsumers.reduce<
			Array<[number, ExpositionConsumerEntity]>
		>((acc, curr) => {
			acc.push([curr.id, curr]);
			return acc;
		}, []);
		const mapOfConsumers = Map(tupleOfConsumers);
		const resourceUrl = this.generateResourceUrl(
			'EXPOSITIONS_MIGRATION_LIST_EXEC'
		);
		const payload: { [k: string]: { id: number; group: boolean } } = {};
		mapping.forEach((m) => {
			const found = mapOfConsumers.get(m.targetConsumerId as number);
			if (!found) {
				return;
			}
			const isTargetAGroup = found.isGroup;
			payload[m.id] = {
				id: m.targetConsumerId as number,
				group: isTargetAGroup,
			};
		});
		const url = this.urlService.generateUrl(
			resourceUrl,
			{
				requestId,
			},
			{
				project: this.projectId,
			}
		);
		return this.http.post<void>(url, payload);
	}

	private generateExpositionPayloadForSave(
		datablockId: number,
		metadata: ExpositionMetadataEntity,
		endpointMetadata: ExpositionEndpointMetadataEntity,
		columns: Array<ExpositionColumnConfigEntity>,
		access: AccessConfigEntity
	): IExpositionCreate {
		const payload: IExpositionCreate = {
			// eslint-disable-next-line camelcase
			datablock_id: datablockId,
			metadata: {
				label: metadata.label,
				description: metadata.description,
				tags: this.generateMetadataTagPayload(metadata.tags.toArray()),
				actif: false,
			},
			title: endpointMetadata.title,
			details: endpointMetadata.details,
			keywords: this.generateKeywordsPayload(
				endpointMetadata.keywords.toArray()
			),
			// eslint-disable-next-line camelcase
			access_point: endpointMetadata.accessPoint,
			access: [this.generateAccessConfigPayload(access)],
			columns: this.generateColumnsConfigPayload(columns),
		};
		return payload;
	}

	private generateAccessConfigPayload(
		access: AccessConfigEntity
	): IExpositionPublicationAccessConfig {
		const columns = access.columnsConfig.elements.toArray().map((c) => c[1]);
		const consumers = access.consumers.elements.toArray().map((c) => c[1]);
		return {
			id: access.id,
			type: access.type === AccessType.Open ? 'OPEN' : 'LIMITED',
			label: access.label,
			description: access.description,
			active: access.isActive,
			filter: access.filter,
			members: this.generateMembersPayload(consumers),
			columns: this.generateColumnsConfigPayload(columns),
		};
	}

	private generateColumnsConfigPayload(
		columns: Array<ExpositionColumnConfigEntity>
	): Array<IExpositionColumnConfig> {
		return columns.map((c) => {
			const column: IExpositionColumnConfig = {
				uuid: c.id,
				label: c.label,
				alias: c.alias,
				description: c.description,
				type: this.getColumnType(c.columnType),
				list: c.isList,
				active: c.isActive,
				id: c.isPrimaryKey,
				filter: c.isFiltered,
				// eslint-disable-next-line camelcase
				case_insensitive: c.isCaseSensitive,
				hash: c.isHashed,
				hidden: c.isHidden,
				status: [],
			};
			return column;
		});
	}

	private generateMetadataTagPayload(
		tags: Array<AppTagItemEntity>
	): Array<IMetadataTag> {
		return tags.map((t) => ({
			id: t.id !== -1 ? t.id : undefined,
			code: t.label,
			color: t.color,
			position: t.position,
		}));
	}

	private generateKeywordsPayload(
		keywords: Array<AppTagItemEntity>
	): Array<string> {
		return keywords.map((t) => t.label);
	}

	private generateMembersPayload(
		consumers: Array<ExpositionConsumerEntity>
	): IViewMembers {
		return {
			users: consumers
				.filter((c) => !c.isGroup)
				.map((c) => ({
					id: c.id,
					name: c.name,
				})),
			groups: consumers
				.filter((c) => c.isGroup)
				.map((c) => ({
					id: c.id,
					name: c.name,
				})),
		};
	}

	private getColumnType(type: ColumnType): string {
		switch (type) {
			case ColumnType.Str:
				return 'string';
			case ColumnType.File:
				return 'file';
			case ColumnType.Double:
				return 'double';
			case ColumnType.Dec:
				return 'decimal';
			case ColumnType.Date:
				return 'date';
			case ColumnType.BigInt:
				return 'big_integer';
			case ColumnType.Bin:
				return 'binary';
			case ColumnType.Words:
				return 'words';
			case ColumnType.Int:
				return 'integer';
			case ColumnType.Bool:
				return 'boolean';
			case ColumnType.Geo:
				return 'geometry';
			default:
				return '';
		}
	}
}
