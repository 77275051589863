import { Injectable } from '@angular/core';
import { AppIcons, IIcons } from '@dc-common-ui';

export enum ComponentIcons {
	Access = 'adbComponent:access',
	Column = 'adbComponent:column',
	Data = 'adbComponent:data',
	Error = 'adbComponent:error',
	Filter = 'adbComponent:filter',
	FilterOff = 'adbComponent:filterOff',
	Group = 'adbComponent:group',
	Help = 'adbComponent:help',
	PublicEndpointMetadata = 'adbComponent:public-endpoint-metadata',
	Success = 'adbComponent:success',
	User = 'adbComponent:user',
}

@Injectable()
export class AppUiComponentIcons extends AppIcons {
	private readonly path = './src/img';
	protected namespace = 'adbComponent';
	protected icons: IIcons = {
		[ComponentIcons.Access]: `${this.path}/common/key_48dp_FILL1_wght400_GRAD0_opsz48.svg`,
		[ComponentIcons.Column]: `${this.path}/common/view_column_FILL1_wght300_GRAD0_opsz48.svg`,
		[ComponentIcons.Data]: `${this.path}/common/table_rows_narrow_48dp_FILL1_wght300_GRAD0_opsz48.svg`,
		[ComponentIcons.Error]: `${this.path}/common/cancel_48dp_FILL1_wght300_GRAD0_opsz48.svg`,
		[ComponentIcons.Filter]: `${this.path}/common/filter_alt_FILL1_wght300_GRAD0_opsz48.svg`,
		[ComponentIcons.FilterOff]: `${this.path}/common/filter_alt_off_48dp.svg`,
		[ComponentIcons.Group]: `${this.path}/common/groups_FILL1_wght300_GRAD0_opsz48.svg`,
		[ComponentIcons.Help]: `${this.path}/common/live_help_48dp_5F6368_FILL1_wght400_GRAD0_opsz48.svg`,
		[ComponentIcons.PublicEndpointMetadata]: `${this.path}/common/text_ad_24dp_FILL0_wght400_GRAD0_opsz24.svg`,
		[ComponentIcons.Success]: `${this.path}/common/check_circle_48dp_FILL1_wght300_GRAD0_opsz48.svg`,
		[ComponentIcons.User]: `${this.path}/common/person_FILL1_wght300_GRAD0_opsz48.svg`,
	};
}
