<ng-container>
	<form class="app-export-db-configuration__form" [formGroup]="form">
		<mat-form-field
			class="app-export-db-configuration__form__user-defined-schema"
			*ngIf="form.get(ExportDbFormControls.DbSchema)"
			[appearance]="Appearance">
			<mat-label i18n="@@export.database.option.schema">db-schema</mat-label>
			<input
				type="text"
				matInput
				placeholder="userDefinedSchema"
				i18n-placeholder="@@export.database.option.schema.placeholder"
				id="{{ cmpId + 'database-user-defined-schema' }}"
				[formControlName]="ExportDbFormControls.DbSchema" />
			<!--			<mat-error-->
			<!--				*ngIf="form.get(ExportDbFormControls.DbSchema)?.hasError(ValidationErrorKeys.Required)"-->
			<!--				i18n="-->
			<!--					@@export.database.option.userDefinedSchema.error.required">-->
			<!--				You must make a selection-->
			<!--			</mat-error>-->
		</mat-form-field>

		<app-tooltip
			tooltipText="tooltip"
			i18n-tooltipText="@@form.forbidden-characters.tooltip">
			<mat-radio-group
				id="{{ cmpId + 'database-label-radio' }}"
				color="primary"
				[formControlName]="ExportDbFormControls.NamingStrategy"
				class="app-export-db-configuration__radio-group">
				<mat-radio-button
					id="{{ cmpId + 'database-label-radio-opt-' + idx }}"
					*ngFor="let option of options; let idx = index"
					[value]="option.value">
					{{ option.label }}
				</mat-radio-button>
			</mat-radio-group>
		</app-tooltip>

		<mat-form-field
			class="app-export-db-configuration__form__user-defined-label"
			*ngIf="
				form.get(ExportDbFormControls.NamingStrategy)?.value ===
				DbTableNamingStrategy.UserDefinedLabel
			"
			[appearance]="Appearance">
			<mat-label i18n="@@export.database.option.userDefinedLabel.new">
				New Label
			</mat-label>
			<input
				type="text"
				matInput
				placeholder="userDefinedLabel"
				i18n-placeholder="
					@@export.database.option.userDefinedLabel.new.placeholder"
				id="{{ cmpId + 'database-user-defined-label' }}"
				[formControlName]="ExportDbFormControls.TargetLabel" />
			<mat-error
				*ngIf="
					form
						.get(ExportDbFormControls.TargetLabel)
						?.hasError(ValidationErrorKeys.Required)
				"
				i18n="@@export.database.option.userDefinedLabel.new.error.required">
				You must make a selection
			</mat-error>
			<mat-error
				*ngIf="
					form
						.get(ExportDbFormControls.TargetLabel)
						?.hasError(ValidationErrorKeys.Pattern)
				"
				i18n="@@export.database.option.userDefinedLabel.new.error.pattern">
				forbidden-characters-present
			</mat-error>
		</mat-form-field>

		<mat-form-field
			class="app-export-db-configuration__form__user-defined-label"
			*ngIf="form.get(ExportDbFormControls.NodeKeys)"
			[appearance]="Appearance">
			<mat-label i18n="@@export.database.option.userDefinedNodeKeysLabel.new">
				New Label
			</mat-label>
			<input
				type="text"
				matInput
				placeholder="userDefinedNodeKeysLabel"
				i18n-placeholder="
					@@export.database.option.userDefinedNodeKeysLabel.new.placeholder"
				id="{{ cmpId + 'neo4j-node-keys-label' }}"
				[formControlName]="ExportDbFormControls.NodeKeys" />
			<mat-error
				*ngIf="
					form
						.get(ExportDbFormControls.NodeKeys)
						?.hasError(ValidationErrorKeys.Required)
				"
				i18n="@@export.database.option.userDefinedLabel.new.error.required">
				You must make a selection
			</mat-error>
		</mat-form-field>
	</form>
</ng-container>
