import { HttpErrorResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import {
	combineAll,
	concatenateAll,
	isSame,
	UuidService,
	WINDOW,
} from '@dc-common-core';
import { AppModalService } from '@dc-common-ui';
import { AppTagItemEntity } from '@dc-common-ui';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { OrderedSet } from 'immutable';
import {
	catchError,
	delay,
	exhaustMap,
	filter,
	map,
	of,
	switchMap,
	tap,
	withLatestFrom,
} from 'rxjs';

import { NgCommService } from '../../ajs-ng-communication.service';
import { AjsScopeService } from '../../ajs-scope.service';
import { AjsStateService } from '../../ajs-state.service';
import { states } from '../../core/application.states';
import { MetadataAdapter } from '../../core/metadata.adapter';
import { TagAdapter } from '../../core/tag.adapter';
import {
	CharsetRequester,
	ConnectorRequester,
	ExportTemplateRequester,
} from '../../requesters';
import { IDcTag } from '../../requesters/core/tag.model';
import { TagRequester } from '../../requesters/core/tag.requester';
import { FilenameLanguageTranslationRequester } from '../../requesters/export-template/filename-language-translation.requester';
import { ExportDatablockModalComponent } from '../components/export-datablock-modal/export-datablock-modal.component';
import { FileNameGenStrategy } from '../components/export-file-configuration/export-file-configuration.entity';
import { ExportTemplateDeleteConfirmationComponent } from '../components/export-template-delete-confirmation/export-template-delete-confirmation.component';
import { ExportTemplateAdapter } from '../export-template.adapter';
import { ExportTemplateParser } from '../export-template.parser';
import {
	closeExecuteExportUsingTemplateModal,
	configureSelectedDatablockExport,
	createExportTemplateConfig,
	createExportTemplateConfigFailure,
	createExportTemplateConfigSuccess,
	deleteExportTemplatesById,
	deleteExportTemplatesByIdSuccess,
	executeExportUsingTemplate,
	exportExecutionFinished,
	fetchAvailableCharsetsFailure,
	fetchAvailableCharsetsSuccess,
	fetchAvailableConnectorsSuccess,
	fetchAvailableTagsSuccess,
	fetchExportConfigFailure,
	fetchExportConfigSuccess,
	fetchExportTemplateConfig,
	fetchExportTemplateList,
	fetchExportTemplateListFailure,
	fetchExportTemplateListSuccess,
	fetchSelectedExportTemplateConfig,
	getAllAvailableTemplates,
	goToExportTemplateListView,
	initExportDatablockUsingExistingTemplate,
	initExportTemplateConfig,
	predictExportFileNameBasedOnPattern,
	predictExportFileNameBasedOnPatternSuccess,
	resetExportTemplate,
	searchAvailableExportTemplate,
	updateExportTemplateConfig,
} from './export-template.actions';
import { ExportTemplateSelector } from './export-template.selector';

interface IExportJobSocketResponse {
	data: { status: string; client_id: string } | undefined;
}

@Injectable()
export class ExportTemplateEffects {
	public charsets$ = createEffect(() =>
		this.actions$.pipe(
			ofType(
				initExportTemplateConfig,
				initExportDatablockUsingExistingTemplate
			),
			switchMap(() =>
				combineAll({
					charsets: this.charsetRequester.getAvailableCharsets(),
					predicted:
						this.filenameLanguageTranslationRequester.predictOutputFileName(
							['%uuid'],
							undefined
						),
				})
			),
			map(({ charsets, predicted }) =>
				fetchAvailableCharsetsSuccess({
					charsets: this.exportTemplateParser.generateCharsets(charsets),
					predicted: this.exportTemplateAdapter.replaceMultipleForwardSlash(
						concatenateAll(predicted, '/')
					),
				})
			),
			catchError((error: HttpErrorResponse, caught) => {
				console.error(error);
				this.store.dispatch(
					fetchAvailableCharsetsFailure({
						error,
					})
				);
				return caught;
			})
		)
	);

	public connectors$ = createEffect(() =>
		this.actions$.pipe(
			ofType(
				initExportTemplateConfig,
				initExportDatablockUsingExistingTemplate
			),
			switchMap(() => this.connectorRequester.getAvailableConnectors(['HTTP'])),
			map((res) => this.exportTemplateParser.generateConnectorsList(res)),
			map((list) =>
				fetchAvailableConnectorsSuccess({
					connectors: list,
				})
			)
		)
	);

	public triggerGetTags$ = createEffect(() =>
		this.actions$.pipe(
			ofType(createExportTemplateConfig, updateExportTemplateConfig),
			withLatestFrom(this.exportTemplateSelector.getAvailableTags$()),
			delay(1000),
			switchMap(([action, tags]) => {
				const hasNewTags = action.payload.templateTags.some((t) => t.id === -1);
				if (hasNewTags) {
					return combineAll({
						tags: this.tagRequester.getAvailableTags(true),
						shouldRemap: true,
					});
				}
				const hasSameTagsLabels = isSame(
					action.payload.templateTags,
					tags.toArray(),
					'label'
				);
				const hasSameColors = isSame(
					action.payload.templateTags,
					tags.toArray(),
					'color'
				);
				if (hasNewTags || !hasSameTagsLabels || !hasSameColors) {
					return combineAll({
						tags: this.tagRequester.getAvailableTags(true),
						shouldRemap: true,
					});
				}
				return combineAll({
					tags,
					shouldRemap: false,
				});
			}),
			map((res) =>
				res.shouldRemap
					? this.tagAdapter.generateTags(res.tags as ReadonlyArray<IDcTag>)
					: (res.tags as OrderedSet<AppTagItemEntity>)
			),
			map((tags) =>
				fetchAvailableTagsSuccess({
					tags: OrderedSet(tags),
				})
			)
		)
	);

	public tags$ = createEffect(() =>
		this.actions$.pipe(
			ofType(initExportTemplateConfig),
			switchMap(() => this.tagRequester.getAvailableTags()),
			map((res) => this.tagAdapter.generateTags(res)),
			map((tags) =>
				fetchAvailableTagsSuccess({
					tags,
				})
			)
		)
	);

	public exportTemplateList$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fetchExportTemplateList, deleteExportTemplatesByIdSuccess),
			switchMap((action) =>
				this.exportTemplateRequester.getAvailableTemplates(
					action.excludePublic,
					action.excludeInactive
				)
			),
			map((response) => this.exportTemplateParser.generateList(response)),
			map((payload) =>
				fetchExportTemplateListSuccess({
					elems: payload,
				})
			),
			tap(() =>
				this.ajsScopeService.broadcastOnRootScope(
					'export-template:list:refresh'
				)
			),
			catchError((error: HttpErrorResponse, caught) => {
				console.error(error);
				this.store.dispatch(
					fetchExportTemplateListFailure({
						error,
					})
				);
				return caught;
			})
		)
	);

	public availableExportTemplateList$ = createEffect(() =>
		this.actions$.pipe(
			ofType(getAllAvailableTemplates),
			switchMap((action) =>
				this.exportTemplateRequester.getAvailableSearchableTemplates()
			),
			map((response) => this.exportTemplateParser.generateList(response)),
			map((payload) =>
				fetchExportTemplateListSuccess({
					elems: payload,
				})
			),
			catchError((error: HttpErrorResponse, caught) => {
				console.error(error);
				this.store.dispatch(
					fetchExportTemplateListFailure({
						error,
					})
				);
				return caught;
			})
		)
	);

	public exportTemplateListBySearchTerm$ = createEffect(() =>
		this.actions$.pipe(
			ofType(searchAvailableExportTemplate),
			switchMap((action) =>
				combineAll({
					shouldExcludeInactive: true,
					response: this.exportTemplateRequester.searchTemplatesByTerm(
						action.searchTerm,
						action.excludePublic
					),
				})
			),
			map((result) =>
				this.exportTemplateParser.generateList(
					result.response,
					result.shouldExcludeInactive
				)
			),
			map((payload) =>
				fetchExportTemplateListSuccess({
					elems: payload,
				})
			),
			catchError((error: HttpErrorResponse, caught) => {
				console.error(error);
				this.store.dispatch(
					fetchExportTemplateListFailure({
						error,
					})
				);
				return caught;
			})
		)
	);

	public exportTemplateConfig$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fetchExportTemplateConfig, fetchSelectedExportTemplateConfig),
			withLatestFrom(this.exportTemplateSelector.getSelectedDatablock$()),
			switchMap(([action, targetDatablockId]) =>
				combineAll({
					exportTemplate: this.exportTemplateRequester.getById(action.exportId),
					datablockId: of(targetDatablockId ? targetDatablockId : undefined),
				})
			),
			map(({ exportTemplate, datablockId }) => ({
				exportTemplate:
					this.exportTemplateParser.generateExportTemplate(exportTemplate),
				metadata: this.metadataAdapter.generateMetadata(
					exportTemplate.metadata
				),
				datablockId,
			})),
			switchMap(({ exportTemplate, metadata, datablockId }) => {
				if (
					exportTemplate.fileNameConfig.strategy ===
						FileNameGenStrategy.UserDefinedFileNamePattern &&
					exportTemplate.fileNameConfig.userDefinedLabel !== undefined
				) {
					return combineAll({
						exportTemplate: of(exportTemplate),
						metadata: of(metadata),
						predicted:
							this.filenameLanguageTranslationRequester.predictOutputFileName(
								[
									exportTemplate.path,
									exportTemplate.fileNameConfig.userDefinedLabel as string,
								],
								datablockId
							),
					});
				}
				return combineAll({
					exportTemplate: of(exportTemplate),
					metadata: of(metadata),
					predicted:
						this.filenameLanguageTranslationRequester.predictOutputFileName(
							[exportTemplate.path, '%uuid'],
							undefined
						),
				});
			}),
			map(({ exportTemplate, metadata, predicted }) =>
				fetchExportConfigSuccess({
					entity: exportTemplate.setPredictedFileName(
						this.exportTemplateAdapter.replaceMultipleForwardSlash(
							concatenateAll(predicted, '/')
						)
					),
					metadata,
				})
			),
			catchError((error: HttpErrorResponse, caught) => {
				console.error(error);
				this.store.dispatch(
					fetchExportConfigFailure({
						error,
					})
				);
				return caught;
			})
		)
	);

	public exportTemplateSave$ = createEffect(() =>
		this.actions$.pipe(
			ofType(createExportTemplateConfig, updateExportTemplateConfig),
			exhaustMap((action) => {
				if (action.payload.templateDetails.id === -1) {
					return combineAll({
						isNew: true,
						response:
							this.exportTemplateRequester.createExportTemplateConfiguration(
								action.payload
							),
					});
				}
				return combineAll({
					isNew: false,
					response:
						this.exportTemplateRequester.updateExportTemplateConfiguration(
							action.payload.templateDetails.id,
							action.payload
						),
				});
			}),
			map((result) => ({
				isNew: result.isNew,
				details: this.exportTemplateParser.parseTemplateBasicDetails(
					result.response
				),
			})),
			tap((result) => {
				const msg = result.isNew
					? $localize`:i18n=@@export.save.success:`
					: $localize`:i18n=@@export.save.update:`;
				this.ngCommService.notifyOnSuccess(msg);
			}),
			map((result) =>
				createExportTemplateConfigSuccess({
					templateId: result.details.id,
					exportType: result.details.type,
				})
			),
			catchError((error: HttpErrorResponse, caught) => {
				this.ngCommService.notifyOnError($localize`:i18n=@@export.save.error:`);
				this.store.dispatch(
					createExportTemplateConfigFailure({
						error,
					})
				);
				return caught;
			})
		)
	);

	public exportTemplateRedirectToEdit$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(createExportTemplateConfigSuccess),
				tap((action) =>
					this.stateService.go(
						states.ExportTemplateEdit,
						{
							exportConfigId: `${action.templateId}`,
							type: action.exportType,
						},
						true
					)
				)
			),
		{
			dispatch: false,
		}
	);

	public deleteExportTemplates$ = createEffect(() =>
		this.actions$.pipe(
			ofType(deleteExportTemplatesById),
			map((action) => ({
				action,
				ref: this.modalService.openPopup<unknown>(
					ExportTemplateDeleteConfirmationComponent,
					{
						title: $localize`:i18n=@@export.delete.modal.title:`,
						subTitle: $localize`:i18n=@@export.delete.modal.subTitle:`,
						width: '40%',
						height: '30%',
					}
				),
			})),
			switchMap((result) =>
				combineAll({
					action: result.action,
					shouldContinue: result.ref.afterClosed(),
				})
			),
			filter((result) => !!result.shouldContinue.action),
			exhaustMap((result) =>
				combineAll({
					action: result.action,
					response: this.exportTemplateRequester.deleteByIds(
						result.action.elemIds
					),
				})
			),
			tap((result) =>
				this.ngCommService.notifyOnError(
					$localize`:i18n=@@export.delete.success:`
				)
			),
			map((result) =>
				deleteExportTemplatesByIdSuccess({
					excludePublic: result.action.excludePublic,
					excludeInactive: result.action.excludeInactive,
				})
			)
		)
	);

	public exportTemplateRedirectToList$ = createEffect(() =>
		this.actions$.pipe(
			ofType(goToExportTemplateListView),
			tap(() => this.stateService.go(states.ExportTemplateList)),
			map(() => resetExportTemplate())
		)
	);

	public configureSelectedDatablockExport$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(configureSelectedDatablockExport),
				map((action) => {
					const subTitle =
						$localize`:i18n=@@datablock.list.export.modal.subTitle:` +
						' ' +
						action.datablockLabel;
					return this.modalService.openPopup<{ datablockId: number }>(
						ExportDatablockModalComponent,
						{
							title: $localize`:i18n=@@datablock.list.export.modal.title:`,
							subTitle,
							width: '80%',
							height: '95%',
							datablockId: action.datablockId,
						}
					);
				}),
				switchMap((modalRef) => modalRef.afterClosed())
			),
		{
			dispatch: false,
		}
	);

	public fileNamePredictor$ = createEffect(() =>
		this.actions$.pipe(
			ofType(predictExportFileNameBasedOnPattern),
			withLatestFrom(this.exportTemplateSelector.getSelectedDatablock$()),
			switchMap(([action, targetDatablockId]) => {
				if (
					action.currentConfig.fileNameConfig.strategy ===
						FileNameGenStrategy.UserDefinedFileNamePattern &&
					action.currentConfig.fileNameConfig.userDefinedLabel !== undefined
				) {
					return this.filenameLanguageTranslationRequester.predictOutputFileName(
						[
							action.currentConfig.path,
							action.currentConfig.fileNameConfig.userDefinedLabel,
						],
						targetDatablockId
					);
				}
				return this.filenameLanguageTranslationRequester.predictOutputFileName(
					[action.currentConfig.path, '%uuid'],
					targetDatablockId
				);
			}),
			map((predicted) =>
				predictExportFileNameBasedOnPatternSuccess({
					predicted: this.exportTemplateAdapter.replaceMultipleForwardSlash(
						concatenateAll(predicted, '/')
					),
				})
			),
			catchError((error: HttpErrorResponse, caught) => {
				console.error(error);
				return caught;
			})
		)
	);

	public executeExportUsingTemplate$ = createEffect(() =>
		this.actions$.pipe(
			ofType(executeExportUsingTemplate),
			withLatestFrom(this.exportTemplateSelector.getSelectedDatablock$()),
			tap(() =>
				this.ngCommService.notifyOnSuccess(
					$localize`:i18n=@@datablock.export.modal.executing:`
				)
			),
			exhaustMap(([action, targetDatablockId]) => {
				if (targetDatablockId === undefined) {
					throw new Error('no datablock selected to execute export');
				}
				const clientUuid = this.uuidService.uuidV4();
				return combineAll({
					clientId: clientUuid,
					exportHistoryId: this.exportTemplateRequester.execute(
						clientUuid,
						targetDatablockId,
						action.exportTemplate
					),
				});
			}),
			switchMap((payload) =>
				combineAll({
					payload,
					stompResponse:
						this.ajsScopeService.listenOnRootScope<IExportJobSocketResponse>(
							'exportJobFinished'
						),
				})
			),
			filter(
				(obj) =>
					obj.stompResponse?.data?.status.toLocaleLowerCase() !== 'running' &&
					obj.stompResponse?.data?.client_id === obj.payload.clientId
			),
			tap((obj) => {
				if (
					(obj.stompResponse?.data?.status.toLocaleLowerCase() === 'success' ||
						obj.stompResponse?.data?.status.toLocaleLowerCase() === 'cached') &&
					obj.stompResponse.data.client_id === obj.payload.clientId
				) {
					this.modalService.closeActiveModal();
					this.ajsScopeService.broadcastOnRootScope(
						'exportJobFinished:success:to-ajs',
						obj.stompResponse
					);
				} else if (
					obj.stompResponse?.data?.status.toLocaleLowerCase() === 'error'
				) {
					throw new Error(
						'error during export execution using selected export template'
					);
				}
			}),
			map(() => exportExecutionFinished()),
			catchError((error: HttpErrorResponse, caught) => {
				console.error(error);
				this.ngCommService.notifyOnError(
					$localize`:i18n=@@datablock.export.modal.executing.error:`
				);
				this.store.dispatch(exportExecutionFinished());
				return caught;
			})
		)
	);

	public exportDatablockModalClose$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(closeExecuteExportUsingTemplateModal, exportExecutionFinished),
				tap(() => {
					if (this.window.location.href.includes('edit')) {
						this.stateService.go(states.DatablockEdit);
						return;
					}
					this.stateService.go(states.DatablockList);
				})
			),
		{
			dispatch: false,
		}
	);

	public constructor(
		@Inject(WINDOW) private readonly window: Window,
		private readonly actions$: Actions,
		private readonly store: Store,
		private readonly uuidService: UuidService,
		private readonly filenameLanguageTranslationRequester: FilenameLanguageTranslationRequester,
		private readonly exportTemplateRequester: ExportTemplateRequester,
		private readonly exportTemplateParser: ExportTemplateParser,
		private readonly exportTemplateAdapter: ExportTemplateAdapter,
		private readonly exportTemplateSelector: ExportTemplateSelector,
		private readonly charsetRequester: CharsetRequester,
		private readonly connectorRequester: ConnectorRequester,
		private readonly tagRequester: TagRequester,
		private readonly ngCommService: NgCommService,
		private readonly stateService: AjsStateService,
		private readonly metadataAdapter: MetadataAdapter,
		private readonly tagAdapter: TagAdapter,
		private readonly ajsScopeService: AjsScopeService,
		private readonly modalService: AppModalService
	) {}
}
