import {
	IListAttrs,
	IListColumnConfig,
	IListOperations,
} from '@dc-common-core';
import { immerable, produce } from 'immer';
import { Map } from 'immutable';

import { MigratedExpositionItemEntity } from './migrated-exposition-item.entity';

interface IMigratedExpositionListEntityExtra
	extends IListColumnConfig<MigratedExpositionItemEntity> {
	isFixed?: boolean;
	isSelectable?: boolean;
	isSearchable?: boolean;
	isInGroup?: boolean;
	isVisible?: boolean;
	canBeHidden?: boolean;
	canBeReordered?: boolean;
	canBeChosen?: boolean;
	format?: string;
	visibleIndex?: number;
	visible?: boolean;
	hasHeaderFilter?: boolean;
}

interface IMigratedExpositionListEntity
	extends IListAttrs<MigratedExpositionItemEntity> {
	availableColumns: Map<string, IMigratedExpositionListEntityExtra>;
}

const entityDefaults: IMigratedExpositionListEntity = {
	elements: Map(),
	availableColumns: Map(),
};

export class MigratedExpositionListEntity
	implements
		IMigratedExpositionListEntity,
		IListOperations<MigratedExpositionListEntity, MigratedExpositionItemEntity>
{
	private [immerable] = true;
	public elements = entityDefaults.elements;
	public availableColumns = entityDefaults.availableColumns;

	public static build(
		availableCols: Array<IMigratedExpositionListEntityExtra>
	): MigratedExpositionListEntity {
		const inst = new MigratedExpositionListEntity();
		const cols = availableCols.reduce((acc, curr) => {
			acc.push([curr.field, curr]);
			return acc;
		}, new Array<[string, IMigratedExpositionListEntityExtra]>());
		inst.availableColumns = Map(cols);
		return inst;
	}

	private constructor() {}

	public getAvailableColumns(): Map<
		string,
		IListColumnConfig<MigratedExpositionItemEntity>
	> {
		return this.availableColumns;
	}

	public setElements(
		elems: Map<string, MigratedExpositionItemEntity>
	): MigratedExpositionListEntity {
		return produce(this, (draft: MigratedExpositionListEntity) => {
			draft.elements = elems;
		});
	}
}
