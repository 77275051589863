import { Injectable } from '@angular/core';
import { MatStepper } from '@angular/material/stepper';
import { ComponentStore } from '@ngrx/component-store';
import { Map } from 'immutable';
import { map, Observable } from 'rxjs';

import { ExpositionPreviousConsumerEntity } from './exposition-previous-consumer.entity';
import { MigrationReadyExpositionList } from './migration-ready-exposition-list.entity';

export enum MigrationStep {
	ExpositionsSelection = 0,
	ConsumersMapping = 1,
}

export interface IExpositionMigrationStepsCompletions {
	expositionSelection: boolean;
	consumersMapping: boolean;
}

interface IExpositionMigrationsStore {
	currentStep: MigrationStep;
	previousStep: MigrationStep | null;
	forMigration: MigrationReadyExpositionList | null;
	consumersToMap: Map<string, ExpositionPreviousConsumerEntity>;
	stepsCompletion: IExpositionMigrationStepsCompletions;
}

@Injectable()
export class ExpositionMigrationsStore extends ComponentStore<IExpositionMigrationsStore> {
	public readonly currentStep$ = this.state.pipe(
		map((state) => state.currentStep)
	);

	public readonly previousStep$ = this.state.pipe(
		map((state) => state.previousStep)
	);

	public readonly stepsCompletion$ = this.state.pipe(
		map((state) => state.stepsCompletion)
	);

	public readonly readyForMigration$ = this.state.pipe(
		map((state) => state.forMigration)
	);

	public readonly consumersToMap$ = this.state.pipe(
		map((state) => state.consumersToMap)
	);

	public constructor() {
		super({
			currentStep: MigrationStep.ExpositionsSelection,
			previousStep: null,
			forMigration: null,
			consumersToMap: Map(),
			stepsCompletion: {
				consumersMapping: false,
				expositionSelection: false,
			},
		});
	}

	private get state(): Observable<IExpositionMigrationsStore> {
		return this.select((state) => state);
	}

	public back(
		stepper: MatStepper,
		currentCompletion: IExpositionMigrationStepsCompletions
	): void {
		this.patchState({
			stepsCompletion: {
				expositionSelection: currentCompletion.expositionSelection,
				consumersMapping: false,
			},
		});
		stepper.previous();
	}

	public setStepCompletion(
		completion: IExpositionMigrationStepsCompletions
	): void {
		this.patchState({
			stepsCompletion: completion,
		});
	}

	public changeStep(steps: {
		currentStep: number;
		previousStep: number;
	}): void {
		this.patchState({
			previousStep: steps.previousStep,
			currentStep: steps.currentStep,
		});
	}

	public setReadyForMigration(list: MigrationReadyExpositionList | null): void {
		if (list === null) {
			return;
		}
		this.patchState({
			forMigration: list,
		});
	}

	public setConsumersToMap(
		consumers:
			| Map<string, ExpositionPreviousConsumerEntity>
			| Array<ExpositionPreviousConsumerEntity>
	): void {
		if (Map.isMap(consumers)) {
			return this.patchState({
				consumersToMap: consumers,
			});
		}
		const mapping = consumers.reduce<
			Map<string, ExpositionPreviousConsumerEntity>
		>((acc, curr) => {
			acc = acc.set(curr.id, curr);
			return acc;
		}, Map<string, ExpositionPreviousConsumerEntity>());
		this.patchState({
			consumersToMap: mapping,
		});
	}
}
