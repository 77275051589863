<ng-container *ngIf="vo$ | async; let vo">
	<app-page *ngIf="!vo.isLoading">
		<app-page-header>
			<app-breadcrumb [paths]="paths"></app-breadcrumb>
		</app-page-header>
		<app-page-actions></app-page-actions>

		<app-page-content>
			<app-panel
				headerHeight="3rem"
				isAlwaysOpen="true"
				hasSearchableGrid="true"
				hasRefresh="true"
				maxActionsCount="6"
				[itemsCount]="vo.history.elements.size"
				(refresh)="refresh()"
				(search)="search($event)">
				<span panel-title i18n="@@expositions.history.list.title">
					Exposition History
				</span>
				<dx-data-grid
					keyExpr="id"
					[elementAttr]="{
						id: 'exposition-history-list',
						class: 'app-exposition-history-list'
					}"
					[dataSource]="vo.history.elements | toArray"
					[hoverStateEnabled]="true"
					[showColumnHeaders]="true"
					[allowColumnResizing]="false"
					[columnAutoWidth]="false"
					[showRowLines]="false"
					[showColumnLines]="false"
					[rowAlternationEnabled]="false"
					[showBorders]="false"
					[width]="'100%'">
					<dxo-header-filter [visible]="true"></dxo-header-filter>

					<dxo-load-panel
						shadingColor="rgba(0,0,0,0.4)"
						indicatorSrc="/src/img/loader-duotone.svg"
						[height]="100"
						[width]="100"
						[enabled]="true"
						[showIndicator]="true"
						[showPane]="true"
						[shading]="true"></dxo-load-panel>

					<!-- Fixable in position columns	-->
					<dxo-column-fixing [enabled]="true"></dxo-column-fixing>

					<ng-container
						*ngFor="let col of vo.history.availableColumns | toArray">
						<dxi-column
							*ngIf="col.field === 'status'"
							[dataField]="col.field"
							[caption]="col.alias"
							[groupIndex]="col.groupIdx"
							[fixed]="false"
							[allowResizing]="true"
							[allowHiding]="true"
							[allowGrouping]="true"
							[allowSearch]="col.isSearchable"
							[allowFiltering]="col.isFilterable"
							[allowSorting]="true"
							[format]="col.format"
							[alignment]="'left'"
							[visibleIndex]="col.visibleIndex"
							[allowHeaderFiltering]="col.hasHeaderFilter"
							[width]="100"
							cellTemplate="expositionStatusTemplate"></dxi-column>
						<dxi-column
							*ngIf="col.field === 'columns'"
							[dataField]="col.field"
							[caption]="col.alias"
							[groupIndex]="col.groupIdx"
							[fixed]="false"
							[allowResizing]="true"
							[allowHiding]="true"
							[allowGrouping]="true"
							[allowSearch]="col.isSearchable"
							[allowFiltering]="col.isFilterable ?? false"
							[allowSorting]="true"
							[format]="col.format"
							[alignment]="'left'"
							[visibleIndex]="col.visibleIndex"
							[allowHeaderFiltering]="col.hasHeaderFilter"
							cellTemplate="configurationTemplate"
							[width]="200"></dxi-column>
						<dxi-column
							*ngIf="col.field === 'dataEntries'"
							[dataField]="col.field"
							[caption]="col.alias"
							[groupIndex]="col.groupIdx"
							[fixed]="false"
							[allowResizing]="true"
							[allowHiding]="true"
							[allowGrouping]="true"
							[allowSearch]="col.isSearchable"
							[allowFiltering]="col.isFilterable"
							[allowSorting]="true"
							[alignment]="'left'"
							[visibleIndex]="col.visibleIndex"
							[allowHeaderFiltering]="col.hasHeaderFilter"
							[width]="180"
							cellTemplate="dataLinesTemplate"></dxi-column>
						<dxi-column
							*ngIf="col.field === 'date'"
							[dataField]="col.field"
							[caption]="col.alias"
							[groupIndex]="col.groupIdx"
							[fixed]="false"
							[allowResizing]="true"
							[allowHiding]="true"
							[allowGrouping]="true"
							[allowSearch]="col.isSearchable"
							[allowFiltering]="col.isFilterable"
							[allowSorting]="true"
							[alignment]="'left'"
							[type]="col.type"
							[format]="col.format"
							[visibleIndex]="col.visibleIndex"
							[allowHeaderFiltering]="col.hasHeaderFilter"
							cellTemplate="dateTemplate"></dxi-column>
						<dxi-column
							*ngIf="col.field === 'avatar'"
							[dataField]="col.field"
							[caption]="col.alias"
							[groupIndex]="col.groupIdx"
							[fixed]="false"
							[allowResizing]="true"
							[allowHiding]="true"
							[allowGrouping]="true"
							[allowSearch]="col.isSearchable"
							[allowFiltering]="col.isFilterable"
							[allowSorting]="false"
							[alignment]="'left'"
							[type]="col.type"
							[format]="col.format"
							[visibleIndex]="col.visibleIndex"
							[allowHeaderFiltering]="col.hasHeaderFilter"
							[width]="140"
							cellTemplate="avatarTemplate"></dxi-column>
						<dxi-column
							*ngIf="col.field === 'duration'"
							[dataField]="col.field"
							[caption]="col.alias"
							[groupIndex]="col.groupIdx"
							[fixed]="false"
							[allowResizing]="true"
							[allowHiding]="true"
							[allowGrouping]="true"
							[allowSearch]="col.isSearchable"
							[allowFiltering]="col.isFilterable"
							[allowSorting]="false"
							[alignment]="'left'"
							[type]="col.type"
							[format]="col.format"
							[visibleIndex]="col.visibleIndex"
							[allowHeaderFiltering]="col.hasHeaderFilter"
							cellTemplate="durationTemplate"></dxi-column>
						<dxi-column
							*ngIf="col.field === 'actionDescription'"
							[dataField]="col.field"
							[caption]="col.alias"
							[groupIndex]="col.groupIdx"
							[fixed]="false"
							[allowResizing]="true"
							[allowHiding]="true"
							[allowGrouping]="true"
							[allowSearch]="col.isSearchable"
							[allowFiltering]="col.isFilterable"
							[allowSorting]="false"
							[alignment]="'left'"
							[type]="col.type"
							[format]="col.format"
							[visibleIndex]="col.visibleIndex"
							[allowHeaderFiltering]="col.hasHeaderFilter"
							[width]="'20%'"></dxi-column>
					</ng-container>

					<div *dxTemplate="let cell of 'dataLinesTemplate'">
						<app-overlay-badge
							matTooltip="total lines published"
							i18n-matTooltip="@@expositions.history.list.lines.tooltip"
							[icon]="ComponentIcons.Data"
							[value]="cell.data['dataEntries']"
							[active]="false"></app-overlay-badge>
					</div>

					<div
						class="configuration-column-template"
						*dxTemplate="let cell of 'configurationTemplate'">
						<ng-container
							*ngIf="
								cell.data['action'] === HistoryActionType.UpdateConfig ||
								cell.data['action'] === HistoryActionType.Publish
							">
							<app-overlay-badge
								matTooltip="coloumns"
								i18n-matTooltip="@@exposition.access.list.column.columns"
								[active]="true"
								[icon]="ComponentIcons.Column"
								[isOpen]="vo.openedOverlays.columns === cell.data['id']"
								(overlayDismiss)="closePopover()"
								(click)="openColumnsPopover($event, cell.data['id'])"
								[value]="cell.data['columns'].length">
								<app-popover
									overlayContent
									title="columns"
									[icon]="{ iconName: ComponentIcons.Column }"
									i18n-title="@@exposition.access.list.column.columns"
									[count]="cell.data['columns'].length"
									(adbPopoverClose)="closePopover()">
									<div
										*ngFor="let column of cell.data['columns'] | toArray"
										class="datablock-columns__body-row">
										<app-column-type
											[type]="column['type']"
											[isList]="column['isList']"></app-column-type>
										<span>{{ column.label }}</span>
									</div>
								</app-popover>
							</app-overlay-badge>
							<app-overlay-badge
								matTooltip="access"
								i18n-matTooltip="@@expositions.history.list.access"
								[active]="true"
								[icon]="ComponentIcons.Access"
								[value]="cell.data['access'].length"
								[isOpen]="vo.openedOverlays.access === cell.data['id']"
								(overlayDismiss)="closePopover()"
								(click)="openAccessPopover($event, cell.data['id'])">
								<app-popover
									overlayContent
									title="access"
									[icon]="{ iconName: ComponentIcons.Access }"
									i18n-title="@@expositions.history.list.access"
									[count]="cell.data['access'].length"
									(adbPopoverClose)="closePopover()">
									<div *ngFor="let access of cell.data['access'] | toArray">
										<span>{{ access }}</span>
									</div>
								</app-popover>
							</app-overlay-badge>
							<app-overlay-badge
								id="overlaybadge-endpointMetadata"
								matTooltip="endpoint metadata"
								i18n-matTooltip="@@exposition.metadata.public"
								[active]="true"
								[icon]="ComponentIcons.PublicEndpointMetadata"
								[isOpen]="
									vo.openedOverlays.endpointMetadata === cell.data['id']
								"
								(overlayDismiss)="closePopover()"
								(click)="openEndpointMetadataPopover($event, cell.data['id'])">
								<app-popover
									id="popover-endpointMetadata"
									overlayContent
									title="endpoint"
									i18n-title="@@exposition.metadata.public"
									[icon]="{ iconName: ComponentIcons.PublicEndpointMetadata }"
									(adbPopoverClose)="closePopover()">
									<app-exposition-endpoint-metadata-config
										[expositionMetadata]="cell.data['endPointMetadata']"
										[isInViewMode]="
											true
										"></app-exposition-endpoint-metadata-config>
								</app-popover>
							</app-overlay-badge>
						</ng-container>
					</div>

					<div
						*dxTemplate="let cell of 'expositionStatusTemplate'"
						[ngClass]="{
							'green-badge': cell.data['status'] === PublicationStatus.Success,
							'red-badge': cell.data['status'] === PublicationStatus.Error
						}">
						<app-overlay-badge
							id="overlaybadge-status"
							[active]="cell.data['status'] === PublicationStatus.Error"
							[icon]="
								cell.data['status'] === PublicationStatus.Success
									? ComponentIcons.Success
									: ComponentIcons.Error
							"
							[isOpen]="vo.openedOverlays.logs === cell.data['id']"
							(overlayDismiss)="closePopover()"
							(click)="openErrorLogsPopover($event, cell.data['id'])">
							<app-popover
								id="popover-status"
								overlayContent
								title="error message"
								[icon]="{ iconName: ComponentIcons.Error }"
								i18n-title="@@expositions.history.list.errorMessage"
								(adbPopoverClose)="closePopover()">
								<mat-spinner
									*ngIf="vo.isLoadingJobLogs"
									[diameter]="20"></mat-spinner>
								<ng-container *ngIf="!vo.isLoadingJobLogs">
									<span
										*ngIf="!cell.data['jobLog']"
										i18n="@@expositions.history.list.unknownError">
										unknown error
									</span>
									{{ cell.data['jobLog'] }}
								</ng-container>
							</app-popover>
						</app-overlay-badge>
					</div>

					<div *dxTemplate="let cell of 'dateTemplate'">
						<span>
							{{ cell.data['date'] | date: 'yyyy-MM-dd HH:mm:ss' }}
						</span>
					</div>

					<div
						class="avatar-template"
						*dxTemplate="let cell of 'avatarTemplate'">
						<app-avatar
							avatarSize="small"
							toolTipEnabled="true"
							[avatar]="cell.data['avatar']"></app-avatar>
					</div>
					<div
						class="duration-template"
						*dxTemplate="let cell of 'durationTemplate'">
						{{ cell.data['duration'] }}
						<span>s</span>
					</div>
					<dxo-group-panel [visible]="true"></dxo-group-panel>

					<!-- Sorting	-->
					<dxo-sorting mode="multiple"></dxo-sorting>

					<!-- Pagination -->
					<dxo-paging [pageSize]="50"></dxo-paging>

					<dxo-column-chooser
						[allowSearch]="true"
						[enabled]="false"
						[mode]="'select'">
						<dxo-selection
							[allowSelectAll]="true"
							[recursive]="false"></dxo-selection>
					</dxo-column-chooser>
				</dx-data-grid>
			</app-panel>
		</app-page-content>
	</app-page>
</ng-container>
