import {
	AfterViewInit,
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	Output,
	ViewChild,
} from '@angular/core';
import { MatOptionSelectionChange } from '@angular/material/core';
import { MatFormFieldAppearance } from '@angular/material/form-field';
import { MatSelectChange } from '@angular/material/select';
import { combineAll, DcBaseComponent } from '@dc-common-core';
import { PanelAction } from '@dc-common-ui';
import { Map } from 'immutable';
import {
	BehaviorSubject,
	combineLatest,
	debounceTime,
	distinctUntilChanged,
	filter,
	firstValueFrom,
	map,
	merge,
	Observable,
	of,
	switchMap,
	takeUntil,
	tap,
	withLatestFrom,
} from 'rxjs';

import { ComponentIcons } from '../../../ui/app-components.icons';
import { DcIcons } from '../../../ui/app-dc.icons';
import { ValidationErrorKeys } from '../../../ui/form/validation-erros-keys';
import { ExpositionsSelector } from '../../store';
import {
	ColumnConfigErrorKey,
	ColumnConfigListError,
} from '../exposition-columns-config/exposition-column-config-list.entity';
import { ExpositionColumnConfigEntity } from '../exposition-columns-config/exposition-column-config.entity';
import { ExpositionColumnsConfigComponent } from '../exposition-columns-config/exposition-columns-config.component';
import { ExpositionConsumerListComponent } from '../exposition-consumers-list/exposition-consumer-list.component';
import { ExpositionConsumerEntity } from '../exposition-consumers-list/exposition-consumer.entity';
import {
	AccessConfigEntity,
	AccessType,
} from './exposition-access-config.entity';
import {
	AccessMetadataFormControls,
	ExpositionAccessMetadataForm,
} from './exposition-access-metadata-form';

@Component({
	selector: 'app-exposition-access-config',
	templateUrl: './exposition-access-config.component.html',
	styleUrls: ['./exposition-access-config.component.scss'],
	providers: [ExpositionAccessMetadataForm],
	changeDetection: ChangeDetectionStrategy.OnPush,
	inputs: ['accessConfig', 'isInStepperMode'],
})
export class ExpositionAccessConfigComponent
	extends DcBaseComponent
	implements AfterViewInit
{
	protected readonly ColumnConfigErrorKey = ColumnConfigErrorKey;
	public accessColumnsActions: Array<PanelAction> = [
		{
			order: 1,
			command: (): Promise<void> => this.updateIsActive(true),
			iconName: '',
			description: $localize`:@@expositions.columns.config.action.activate:`,
			typed: 'grouped-context-action',
		},
		{
			order: 2,
			command: (): Promise<void> => this.updateIsActive(false),
			iconName: '',
			description: $localize`:@@expositions.columns.config.action.disable:`,
			typed: 'grouped-context-action',
		},
		{
			order: 3,
			command: (): Promise<void> => this.hashSelectedColumns(),
			iconName: '',
			description: $localize`:@@expositions.columns.config.action.hash:`,
			typed: 'grouped-context-action',
		},
	];
	public consumersActions: Array<PanelAction> = [
		{
			order: 1,
			command: (): Promise<void> => this.removeSelectedConsumers(),
			iconName: 'delete',
			isSvg: false,
			typed: 'plain-context-action',
			description: $localize`:@@expositions.access.config.remove:`,
		},
	];

	private readonly searchInputSubject = new BehaviorSubject<string>('');
	private readonly isOverlayInfoOpenSubject = new BehaviorSubject<boolean>(
		false
	);
	private readonly accessConfigSubject =
		new BehaviorSubject<AccessConfigEntity>(AccessConfigEntity.build());
	private readonly colConfigListErrorsSubject = new BehaviorSubject<
		Map<ColumnConfigErrorKey, ColumnConfigListError>
	>(Map());
	private readonly isMetadataValidSubject = new BehaviorSubject<boolean>(false);

	@Output()
	public hasValidityChanged = new EventEmitter<boolean>();

	@ViewChild(ExpositionConsumerListComponent)
	private readonly expositionConsumerList!: ExpositionConsumerListComponent;

	@ViewChild(ExpositionColumnsConfigComponent)
	private readonly expositionColumnsConfig!: ExpositionColumnsConfigComponent;

	public Appearance: MatFormFieldAppearance = 'legacy';
	protected readonly AccessMetadataFormControls = AccessMetadataFormControls;
	protected readonly AccessType = AccessType;

	public DcIcons = DcIcons;
	public vo$: Observable<{
		accessConfig: AccessConfigEntity;
		filteredConsumers: Array<ExpositionConsumerEntity>;
		searchInput: string;
		listErrors: Map<ColumnConfigErrorKey, ColumnConfigListError>;
		isOverlayInfoOpened: boolean;
		canNotCreateOpenAccess: boolean;
	}>;

	protected readonly ValidationErrorKeys = ValidationErrorKeys;
	protected readonly ComponentIcons = ComponentIcons;
	public constructor(
		private readonly expositionsSelector: ExpositionsSelector,
		public readonly accessMetadataForm: ExpositionAccessMetadataForm
	) {
		super();
		this.cmpId = 'exposition-access-config';

		const isInStepperMode$ = merge(
			of(false),
			this.toObservable<boolean>('isInStepperMode')
		);

		const filteredConsumers$ = combineLatest([
			this.expositionsSelector.getAvailableConsumers$(),
			this.searchInputSubject,
		]).pipe(
			takeUntil(this.onDestroy$),
			debounceTime(75),
			withLatestFrom(this.accessConfigSubject),
			map(([[available, input], access]) => {
				if (input === '') {
					return available.filter(
						(consumer) => !access.consumers.elements.has(`${consumer.id}`)
					);
				}
				return available.filter(
					(consumer) =>
						consumer.name.toLowerCase().includes(input) &&
						!access.consumers.elements.has(`${consumer.id}`)
				);
			})
		);

		this.toObservable<AccessConfigEntity>('accessConfig')
			.pipe(
				takeUntil(this.onDestroy$),
				tap((val) => this.accessConfigSubject.next(val)),
				filter((access) => access.id !== ''),
				distinctUntilChanged(),
				tap((config) => {
					this.accessMetadataForm.populate(config);
					if (config.label) {
						this.isMetadataValidSubject.next(true);
					}
				})
			)
			.subscribe();

		const canNotCreateOpenAccess$ = isInStepperMode$.pipe(
			switchMap((isInStepperMode) => {
				if (isInStepperMode) {
					return of(false);
				}
				return this.toObservable<AccessConfigEntity>('accessConfig').pipe(
					switchMap((access) =>
						this.expositionsSelector.hasConfiguredOpenAccess$(access.id)
					)
				);
			})
		);

		this.vo$ = combineAll({
			accessConfig: this.accessConfigSubject,
			searchInput: this.searchInputSubject,
			filteredConsumers: filteredConsumers$,
			listErrors: this.colConfigListErrorsSubject,
			isOverlayInfoOpened: this.isOverlayInfoOpenSubject,
			canNotCreateOpenAccess: canNotCreateOpenAccess$,
		});
	}

	public ngAfterViewInit(): void {
		super.ngAfterViewInit();

		this.accessMetadataForm.form.valueChanges
			.pipe(
				debounceTime(100),
				withLatestFrom(this.accessConfigSubject),
				takeUntil(this.onDestroy$),
				tap(([, accessConfig]) => {
					const isValid = this.accessMetadataForm.form.valid;
					this.isMetadataValidSubject.next(isValid);
					const metadata = this.accessMetadataForm.extract();

					if (isValid) {
						const updated = accessConfig.updateMetadata(
							metadata.label ?? '',
							metadata.description ?? '',
							metadata.isActive ?? false
						);
						this.accessConfigSubject.next(updated);
					}
				})
			)
			.subscribe();

		combineLatest([
			this.colConfigListErrorsSubject,
			this.isMetadataValidSubject,
			this.accessConfigSubject,
		])
			.pipe(
				takeUntil(this.onDestroy$),
				tap(([errors, isMetadataValid, accessConfig]) => {
					const isAccessTypeValid =
						accessConfig.type === AccessType.Limited
							? accessConfig.consumers.elements.size !== 0
							: true;
					this.hasValidityChanged.emit(
						errors.size === 0 && isMetadataValid && isAccessTypeValid
					);
				})
			)
			.subscribe();
	}

	public onSearchInputChange(input: string): void {
		this.searchInputSubject.next(input);
	}

	public async selected(event: MatOptionSelectionChange): Promise<void> {
		const accessConfig = await firstValueFrom(this.accessConfigSubject);
		const available = await firstValueFrom(
			this.expositionsSelector.getAvailableConsumers$()
		);
		const selected = available.find((c) => c.name === event.source.value);
		if (selected) {
			const updated = accessConfig.updateConsumers(
				accessConfig.consumers.addConsumer(selected)
			);
			this.accessConfigSubject.next(updated);
		}
		this.searchInputSubject.next('');
	}

	public async removeConsumers(ids: Array<string>): Promise<void> {
		const accessConfig = await firstValueFrom(this.accessConfigSubject);
		const updated = accessConfig.updateConsumers(
			accessConfig.consumers.removeConsumers(ids)
		);
		this.accessConfigSubject.next(updated);
		this.searchInputSubject.next('');
	}

	public async changeFilter(filter: string): Promise<void> {
		const accessConfig = await firstValueFrom(this.accessConfigSubject);
		const updated = accessConfig.updateFilter(filter);
		this.accessConfigSubject.next(updated);
	}

	public async changeIsAccessLimited($event: MatSelectChange): Promise<void> {
		const accessConfig = await firstValueFrom(this.accessConfigSubject);
		const updated = accessConfig.updateAccessType($event.value);
		this.accessConfigSubject.next(updated);
	}

	public modifyColumnConfigValidity(
		errors: Map<ColumnConfigErrorKey, ColumnConfigListError>
	): void {
		this.colConfigListErrorsSubject.next(errors);
	}

	public getAccessConfig(): AccessConfigEntity {
		if (!this.expositionColumnsConfig) {
			throw new Error('Exposition Columns Config Cmp no where to be found !');
		}
		const previousAccess = this.accessConfigSubject.value;
		return previousAccess.updateColumnsConfig(
			this.expositionColumnsConfig.getUpdatedList()
		);
	}

	private async updateIsActive(isActive: boolean): Promise<void> {
		const accessConfig = await firstValueFrom(this.accessConfigSubject);
		const selection =
			(await this.expositionColumnsConfig.getSelectedColumns()) as Array<ExpositionColumnConfigEntity>;
		const selectedIds = selection.map((s) => s.id);
		const updated = accessConfig.updateColumnsConfig(
			accessConfig.columnsConfig.updateIsActive(selectedIds, isActive)
		);
		this.accessConfigSubject.next(updated);
	}

	private async hashSelectedColumns(): Promise<void> {
		const accessConfig = await firstValueFrom(this.accessConfigSubject);
		const selection =
			(await this.expositionColumnsConfig.getSelectedColumns()) as Array<ExpositionColumnConfigEntity>;
		const selectedIds = selection
			.map((s) => s.id)
			.filter((s) => accessConfig.columnsConfig.hashedColumns.includes(s));
		if (selectedIds.length) {
			const updated = accessConfig.updateColumnsConfig(
				accessConfig.columnsConfig.updateHash(selectedIds, true)
			);
			this.accessConfigSubject.next(updated);
		}
	}

	private async removeSelectedConsumers(): Promise<void> {
		const accessConfig = await firstValueFrom(this.accessConfigSubject);
		const selection =
			(await this.expositionConsumerList.getSelectedConsumers()) as Array<ExpositionConsumerEntity>;
		const updated = accessConfig.updateConsumers(
			accessConfig.consumers.removeConsumers(selection.map((s) => `${s.id}`))
		);
		this.accessConfigSubject.next(updated);
		this.searchInputSubject.next('');
	}

	public openOverlayInfo(): void {
		this.isOverlayInfoOpenSubject.next(true);
	}
	public closeOverlayInfo(): void {
		this.isOverlayInfoOpenSubject.next(false);
	}
}
