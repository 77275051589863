import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CacheInterceptor, DcLibCoreModule } from '@dc-common-core';

import { UiModule } from '../ui/ui.module';
import { ANGULARJS_BOOTSTRAP_TIMEOUT } from './bootstrap-timeout.token';
import { DcErrorsInterceptor } from './dc-errors.interceptor';
import { DcLanguageInterceptor } from './dc-language.interceptor';
import { DcTokenInterceptor } from './dc-token.interceptor';
import { ProjectStorage } from './project.storage';

@NgModule({
	imports: [
		BrowserAnimationsModule,
		CommonModule,
		HttpClientModule,
		UiModule,
		DcLibCoreModule,
	],
	providers: [
		{
			provide: HTTP_INTERCEPTORS,
			useClass: DcTokenInterceptor,
			multi: true,
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: DcLanguageInterceptor,
			multi: true,
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: CacheInterceptor,
			multi: true,
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: DcErrorsInterceptor,
			multi: true,
		},
		{
			provide: ANGULARJS_BOOTSTRAP_TIMEOUT,
			useValue: 5440,
		},
		ProjectStorage,
	],
})
export class CoreModule {}
