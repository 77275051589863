<ng-container *ngIf="vo$ | async; let vo">
	<div class="app-card-outline">
		<div class="app-card-outline-header">
			<div class="app-card-outline-header__description">
				<div class="app-card-outline-header__title">
					<ng-content select="[card-outline-title]"></ng-content>
				</div>

				<div class="app-card-outline-header__sub-title">
					<ng-content select="[card-outline-subtitle]"></ng-content>
				</div>
			</div>
			<div *ngIf="vo.view" class="app-card-outline-header__btns">
				<ng-content select="[card-outline-btns]"></ng-content>
			</div>
		</div>

		<div class="app-card-outline-content">
			<ng-content select="[card-outline-content]"></ng-content>
		</div>
	</div>
</ng-container>
