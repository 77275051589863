import { ColumnType } from '@dc-common-ui';
import { immerable, produce } from 'immer';

interface IExpositionColumnConfigEntity {
	id: string;
	label: string;
	isActive: boolean;
	alias: string;
	description: string;
	columnType: ColumnType;
	isPrimaryKey: boolean;
	isFiltered: boolean;
	isCaseSensitive: boolean;
	isHashed: boolean;
	isHidden: boolean;
	isList: boolean;
	filterField: string | number | boolean;
}

const entityDefaults: IExpositionColumnConfigEntity = {
	id: '',
	label: '',
	isActive: false,
	alias: '',
	description: '',
	columnType: ColumnType.Unknown,
	isPrimaryKey: false,
	isFiltered: false,
	isCaseSensitive: false,
	isHashed: false,
	isHidden: false,
	isList: false,
	filterField: '',
};

export class ExpositionColumnConfigEntity
	implements IExpositionColumnConfigEntity
{
	private [immerable] = true;
	public id = entityDefaults.id;
	public label = entityDefaults.label;
	public isActive = entityDefaults.isActive;
	public alias = entityDefaults.alias;
	public description = entityDefaults.description;
	public columnType = entityDefaults.columnType;
	public isPrimaryKey = entityDefaults.isPrimaryKey;
	public isFiltered = entityDefaults.isFiltered;
	public isCaseSensitive = entityDefaults.isCaseSensitive;
	public isHashed = entityDefaults.isHashed;
	public isHidden = entityDefaults.isHidden;
	public isList = entityDefaults.isList;
	public filterField = entityDefaults.filterField;

	public static build(
		params: Partial<IExpositionColumnConfigEntity> = {}
	): ExpositionColumnConfigEntity {
		const instance = new ExpositionColumnConfigEntity();
		instance.id = params.id ?? entityDefaults.id;
		instance.label = params.label ?? entityDefaults.label;
		instance.isActive = params.isActive ?? entityDefaults.isActive;
		instance.alias = params.alias ?? entityDefaults.alias;
		instance.description = params.description ?? entityDefaults.description;
		instance.columnType = params.columnType ?? entityDefaults.columnType;
		instance.isPrimaryKey = params.isPrimaryKey ?? entityDefaults.isPrimaryKey;
		instance.isFiltered = params.isFiltered ?? entityDefaults.isFiltered;
		instance.isCaseSensitive =
			params.isCaseSensitive ?? entityDefaults.isCaseSensitive;
		instance.isHashed = params.isHashed ?? entityDefaults.isHashed;
		instance.isHidden = params.isHidden ?? entityDefaults.isHidden;
		instance.isList = params.isList ?? entityDefaults.isList;
		instance.filterField = params.filterField ?? entityDefaults.filterField;
		return instance;
	}

	public resetFilterField(): ExpositionColumnConfigEntity {
		return produce(this, (draft: ExpositionColumnConfigEntity) => {
			draft.filterField = '';
		});
	}

	public updateAlias(value: string): ExpositionColumnConfigEntity {
		return produce(this, (draft: ExpositionColumnConfigEntity) => {
			draft.alias = value;
			draft.filterField = '';
		});
	}

	public updateDescription(value: string): ExpositionColumnConfigEntity {
		return produce(this, (draft: ExpositionColumnConfigEntity) => {
			draft.description = value;
		});
	}

	public updateIsActive(value: boolean): ExpositionColumnConfigEntity {
		return produce(this, (draft: ExpositionColumnConfigEntity) => {
			draft.isActive = value;
			draft.isHidden = false;
			draft.isFiltered = draft.isList ? false : value;
			draft.isPrimaryKey = false;
			draft.isHashed = false;
			draft.isCaseSensitive = false;
			draft.filterField = '';
		});
	}

	public updateIsPrimaryKey(value: boolean): ExpositionColumnConfigEntity {
		return produce(this, (draft: ExpositionColumnConfigEntity) => {
			if (
				!this.isActive ||
				this.isList ||
				this.columnType === ColumnType.Bool
			) {
				return;
			}
			draft.isPrimaryKey = value;
			if (value) {
				draft.isHidden = false;
			}
		});
	}

	public updateIsHidden(value: boolean): ExpositionColumnConfigEntity {
		return produce(this, (draft: ExpositionColumnConfigEntity) => {
			if (!this.isActive || this.isList) {
				return;
			}
			draft.isHidden = value;
			draft.isPrimaryKey = false;
			draft.isHashed = false;
			draft.isFiltered = false;
		});
	}

	public updateIsFiltered(value: boolean): ExpositionColumnConfigEntity {
		return produce(this, (draft: ExpositionColumnConfigEntity) => {
			if (!this.isActive || this.isList) {
				return;
			}
			draft.isFiltered = value;
			draft.isHidden = false;
		});
	}

	public updateIsCaseSensitive(value: boolean): ExpositionColumnConfigEntity {
		return produce(this, (draft: ExpositionColumnConfigEntity) => {
			if (!this.isActive || this.isList || this.columnType !== ColumnType.Str) {
				return;
			}
			draft.isCaseSensitive = value;
		});
	}

	public updateHash(value: boolean): ExpositionColumnConfigEntity {
		return produce(this, (draft: ExpositionColumnConfigEntity) => {
			if (!this.isActive || this.columnType !== ColumnType.Str) {
				return;
			}
			draft.isHashed = value;
			draft.isHidden = false;
		});
	}

	public tagAsDuplicate(toggle: boolean): ExpositionColumnConfigEntity {
		return produce(this, (draft: ExpositionColumnConfigEntity) => {
			draft.filterField = draft.isActive && toggle ? 'DUPLICATED' : '';
		});
	}

	public clone(
		params: Partial<IExpositionColumnConfigEntity> = {}
	): ExpositionColumnConfigEntity {
		const instance = new ExpositionColumnConfigEntity();
		instance.id = params.id ?? this.id;
		instance.label = params.label ?? this.label;
		instance.isActive = params.isActive ?? this.isActive;
		instance.alias = params.alias ?? this.alias;
		instance.description = params.description ?? this.description;
		instance.columnType = params.columnType ?? this.columnType;
		instance.isPrimaryKey = params.isPrimaryKey ?? this.isPrimaryKey;
		instance.isFiltered = params.isFiltered ?? this.isFiltered;
		instance.isCaseSensitive = params.isCaseSensitive ?? this.isCaseSensitive;
		instance.isHashed = params.isHashed ?? this.isHashed;
		instance.isHidden = params.isHidden ?? this.isHidden;
		instance.isList = params.isList ?? this.isList;
		return instance;
	}
}
