import { ValidationErrorKeys } from './validation-erros-keys';

export const uniqueValueFn = (
	value: any, // eslint-disable-line @typescript-eslint/no-explicit-any
	values: Array<string>
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
): { [k: string]: any } | null => {
	if (values.includes(value)) {
		return {
			[ValidationErrorKeys.Duplicates]: true,
		};
	}
	return null;
};
