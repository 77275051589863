import { AppTagItemEntity } from '@dc-common-core';
import { AvatarEntity } from '@dc-common-ui';
import { immerable, produce } from 'immer';

import { ExpositionDatablockColumnEntity } from './exposition-datablock-column.entity';

interface IDatablockEntity {
	id: number;
	label: string;
	columnsCount: number;
	columns: Array<ExpositionDatablockColumnEntity>;
	updateDate: Date;
	updatedBy: string;
	tags: Array<AppTagItemEntity>;
	url: string;
	avatar: AvatarEntity;
}

const entityDefaults: IDatablockEntity = {
	id: -1,
	label: '',
	columnsCount: -1,
	columns: [],
	updateDate: new Date(),
	updatedBy: '',
	tags: [],
	url: '',
	avatar: AvatarEntity.build('user'),
};

export class ExpositionDatablockEntity implements IDatablockEntity {
	private [immerable] = true;
	public id = entityDefaults.id;
	public label = entityDefaults.label;
	public columnsCount = entityDefaults.columnsCount;
	public columns = entityDefaults.columns;
	public updateDate = entityDefaults.updateDate;
	public updatedBy = entityDefaults.updatedBy;
	public tags = entityDefaults.tags;
	public url = entityDefaults.url;
	public avatar = entityDefaults.avatar;

	public static build(
		params: Partial<IDatablockEntity> = {}
	): ExpositionDatablockEntity {
		const instance = new ExpositionDatablockEntity();
		instance.id = params.id ?? entityDefaults.id;
		instance.label = params.label ?? entityDefaults.label;
		instance.columnsCount = params.columnsCount ?? entityDefaults.columnsCount;
		instance.columns = params.columns ?? entityDefaults.columns;
		instance.updateDate = params.updateDate ?? entityDefaults.updateDate;
		instance.updatedBy = params.updatedBy ?? entityDefaults.updatedBy;
		instance.tags = params.tags ?? entityDefaults.tags;
		instance.url = params.url ?? entityDefaults.url;
		instance.avatar = params.avatar ?? entityDefaults.avatar;
		return instance;
	}

	public setColumns(
		columns: Array<ExpositionDatablockColumnEntity>
	): ExpositionDatablockEntity {
		return produce(this, (draft: ExpositionDatablockEntity) => {
			draft.columns = columns;
		});
	}
}
