import {
	IListAttrs,
	IListColumnConfig,
	IListOperations,
} from '@datachain/adb-common';
import { immerable, produce } from 'immer';
import { Map } from 'immutable';

import { ExpositionDatablockColumnEntity } from './exposition-datablock-column.entity';
import { ExpositionDatablockEntity } from './exposition-datablock.entity';

export interface IDatablockListExtra
	extends IListColumnConfig<ExpositionDatablockEntity> {
	isFixed?: boolean;
	isSelectable?: boolean;
	isSearchable?: boolean;
	isInGroup?: boolean;
	isVisible?: boolean;
	canBeHidden?: boolean;
	canBeReordered?: boolean;
	canBeChosen?: boolean;
	format?: string;
}

interface IDatablockListEntity extends IListAttrs<ExpositionDatablockEntity> {
	availableColumns: Map<string, IDatablockListExtra>;
}

const entityDefaults: IDatablockListEntity = {
	elements: Map(),
	availableColumns: Map(),
};

export class ExpositionDatablockListEntity
	implements
		IDatablockListEntity,
		IListOperations<ExpositionDatablockListEntity, ExpositionDatablockEntity>
{
	private [immerable] = true;
	public elements = entityDefaults.elements;
	public availableColumns = entityDefaults.availableColumns;

	public static build(
		availableCols: Array<IDatablockListExtra>
	): ExpositionDatablockListEntity {
		const inst = new ExpositionDatablockListEntity();
		const cols = availableCols.reduce((acc, curr) => {
			acc.push([curr.field, curr]);
			return acc;
		}, new Array<[string, IDatablockListExtra]>());
		inst.availableColumns = Map(cols);
		return inst;
	}

	private constructor() {}

	public getAvailableColumns(): Map<
		string,
		IListColumnConfig<ExpositionDatablockEntity>
	> {
		return this.availableColumns;
	}
	public setElements(
		elems: Map<string, ExpositionDatablockEntity>
	): ExpositionDatablockListEntity {
		return produce(this, (draft: ExpositionDatablockListEntity) => {
			draft.elements = elems;
		});
	}

	public setDatablockColumns(
		datablockId: number,
		columns: Array<ExpositionDatablockColumnEntity>
	): ExpositionDatablockListEntity {
		const datablock = this.elements.get(`${datablockId}`);
		if (!datablock) {
			return this;
		}
		const updatedDatablock = datablock.setColumns(columns);
		const elements = this.elements.set(`${datablockId}`, updatedDatablock);
		return produce(this, (draft: ExpositionDatablockListEntity) => {
			draft.elements = elements;
		});
	}
}
