import { AppTagItemEntity, IisEqual } from '@dc-common-core';
import { immerable, produce } from 'immer';
import { OrderedSet } from 'immutable';

interface IExpositionMetadataEntity {
	id: number;
	label: string;
	description: string;
	tags: OrderedSet<AppTagItemEntity>;
}

const entityDefaults: IExpositionMetadataEntity = {
	id: -1,
	label: '',
	description: '',
	tags: OrderedSet(),
};

export class ExpositionMetadataEntity
	implements IExpositionMetadataEntity, IisEqual<ExpositionMetadataEntity>
{
	private [immerable] = true;
	public id = entityDefaults.id;
	public label = entityDefaults.label;
	public description = entityDefaults.description;
	public tags = entityDefaults.tags;

	public static build(
		params: Partial<IExpositionMetadataEntity> = {}
	): ExpositionMetadataEntity {
		const instance = new ExpositionMetadataEntity();
		instance.id = params.id ?? entityDefaults.id;
		instance.label = params.label ?? entityDefaults.label;
		instance.description = params.description ?? entityDefaults.description;
		instance.tags = params.tags ?? entityDefaults.tags;
		return instance;
	}

	private generateExpositionLabel(datablockLabel: string): string {
		return `Exposition API - ${datablockLabel}`;
	}
	public isEqualTo(obj: ExpositionMetadataEntity): boolean {
		if (this.label.localeCompare(obj.label) !== 0) {
			return false;
		} else if (this.description.localeCompare(obj.description) !== 0) {
			return false;
		}
		return true;
	}

	public updateLabel(datablockLabel: string): ExpositionMetadataEntity {
		return produce(this, (draft: ExpositionMetadataEntity) => {
			draft.label = this.generateExpositionLabel(datablockLabel);
		});
	}
}
