import { IisEqual } from '@dc-common-core';
import { AppTagItemEntity } from '@dc-common-ui';
import { immerable } from 'immer';
import { OrderedSet } from 'immutable';

import { IMetadataEntity, IProjectEntity } from '../metadata.interface';

interface IAppMetadataEntity extends IMetadataEntity {
	project: IProjectEntity;
	tags: OrderedSet<AppTagItemEntity>;
}

const entityDefaults: IAppMetadataEntity = {
	id: -1,
	title: '',
	label: '',
	description: '',
	isActive: true,
	code: '',
	createdBy: '',
	updatedBy: '',
	creationDate: -1,
	updateDate: -1,
	iconId: -1,
	tags: OrderedSet(),
	project: {
		id: -1,
		isPublic: false,
		metadata: {
			label: '',
			title: '',
		},
	},
};

export class AppMetadataEntity
	implements IAppMetadataEntity, IisEqual<AppMetadataEntity>
{
	private [immerable] = true;
	public id = entityDefaults.id;
	public title = entityDefaults.title;
	public description = entityDefaults.description;
	public isActive = entityDefaults.isActive;
	public code = entityDefaults.code;
	public createdBy = entityDefaults.createdBy;
	public updatedBy = entityDefaults.updatedBy;
	public creationDate = entityDefaults.creationDate;
	public updateDate = entityDefaults.updateDate;
	public iconId = entityDefaults.iconId;
	public project = entityDefaults.project;
	public label = entityDefaults.title;
	public tags = entityDefaults.tags;

	public static build(
		params: Partial<IAppMetadataEntity> = {}
	): AppMetadataEntity {
		const instance = new AppMetadataEntity();
		// FIXME: generate new UUID no need to pass it through
		instance.id = params.id ?? entityDefaults.id;
		instance.title = params.title ?? entityDefaults.title;
		instance.description = params.description ?? entityDefaults.description;
		instance.isActive = params.isActive ?? entityDefaults.isActive;
		instance.code = params.code ?? entityDefaults.code;
		instance.createdBy = params.createdBy ?? entityDefaults.createdBy;
		instance.updatedBy = params.updatedBy ?? entityDefaults.updatedBy;
		instance.creationDate = params.creationDate ?? entityDefaults.creationDate;
		instance.updateDate = params.updateDate ?? entityDefaults.updateDate;
		instance.iconId = params.iconId ?? entityDefaults.iconId;
		instance.project = params.project ?? entityDefaults.project;
		instance.tags = params.tags ?? entityDefaults.tags;
		instance.label = instance.title;
		return instance;
	}

	public isEqualTo(obj: AppMetadataEntity): boolean {
		if (this.title.localeCompare(obj.title) !== 0) {
			return false;
		} else if (this.description.localeCompare(obj.description) !== 0) {
			return false;
		} else if (this.isActive !== obj.isActive) {
			return false;
		}
		return true;
	}
}
