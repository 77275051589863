import { Component } from '@angular/core';
import { combineAll, DcBaseComponent } from '@dc-common-core';
import { Store } from '@ngrx/store';
import { firstValueFrom, Observable } from 'rxjs';

import { ComponentIcons } from '../../../ui/app-components.icons';
import { DcIcons } from '../../../ui/app-dc.icons';
import { goToExpositionHistoryRoute } from '../../store';
import { PublicationStatus } from '../publication-status.enum';
import { PublishedExpositionEntity } from './published-exposition.entity';

@Component({
	selector: 'app-published-exposition',
	templateUrl: './published-exposition.component.html',
	styleUrls: ['./published-exposition.component.scss'],
	inputs: ['published'],
})
export class PublishedExpositionComponent extends DcBaseComponent {
	public vo$: Observable<{
		published: PublishedExpositionEntity;
	}>;

	protected readonly PublicationStatus = PublicationStatus;
	protected readonly DcIcons = DcIcons;
	protected readonly ComponentIcons = ComponentIcons;
	public constructor(private readonly store: Store) {
		super();
		this.cmpId = 'exposition-api-publication';

		this.vo$ = combineAll({
			published: this.toObservable<PublishedExpositionEntity>('published'),
		});
	}

	public async goToHistoryRoute(): Promise<void> {
		const published = await firstValueFrom(
			this.toObservable<PublishedExpositionEntity>('published')
		);
		this.store.dispatch(
			goToExpositionHistoryRoute({
				expositionId: published.id,
			})
		);
	}
}
