import { immerable, produce } from 'immer';

import { ExpositionColumnConfigListEntity } from '../exposition-columns-config/exposition-column-config-list.entity';
import { ExpositionConsumerListEntity } from '../exposition-consumers-list/exposition-consumer-list.entity';

export enum AccessType {
	Open = 'open',
	Limited = 'limited',
}

interface IAccessConfigEntity {
	id: string;
	label: string;
	description: string;
	filter: string;
	columnsConfig: ExpositionColumnConfigListEntity;
	consumers: ExpositionConsumerListEntity;
	type: AccessType;
	isActive: boolean;
}

const entityDefaults: IAccessConfigEntity = {
	id: '',
	label: '',
	description: '',
	filter: '',
	columnsConfig: ExpositionColumnConfigListEntity.build([]),
	consumers: ExpositionConsumerListEntity.build([
		{
			field: 'avatar',
			alias: '',
			type: '',
			isFilterable: true,
			visibleIndex: 0,
		},
		{
			field: 'name',
			alias: $localize`:@@expositions.access.config.userOrGroupName:`,
			type: 'string',
			isFilterable: true,
			visibleIndex: 1,
		},
	]),
	type: AccessType.Limited,
	isActive: false,
};

export class AccessConfigEntity implements IAccessConfigEntity {
	private [immerable] = true;
	public id = entityDefaults.id;
	public label = entityDefaults.label;
	public description = entityDefaults.description;
	public filter = entityDefaults.filter;
	public columnsConfig = entityDefaults.columnsConfig;
	public consumers = entityDefaults.consumers;
	public type = entityDefaults.type;
	public isActive = entityDefaults.isActive;

	public static build(
		params: Partial<IAccessConfigEntity> = {}
	): AccessConfigEntity {
		const instance = new AccessConfigEntity();
		instance.id = params.id ?? entityDefaults.id;
		instance.label = params.label ?? entityDefaults.label;
		instance.filter = params.filter ?? entityDefaults.filter;
		instance.description = params.description ?? entityDefaults.description;
		instance.columnsConfig =
			params.columnsConfig ?? entityDefaults.columnsConfig;
		instance.consumers = params.consumers ?? entityDefaults.consumers;
		instance.isActive = params.isActive ?? entityDefaults.isActive;
		instance.type = params.type ?? entityDefaults.type;
		return instance;
	}

	public updateFilter(filter: string): AccessConfigEntity {
		return produce(this, (draft: AccessConfigEntity) => {
			draft.filter = filter;
		});
	}

	public updateMetadata(
		label: string,
		description: string,
		isActive: boolean
	): AccessConfigEntity {
		return produce(this, (draft: AccessConfigEntity) => {
			draft.label = label;
			draft.description = description;
			draft.isActive = isActive;
		});
	}

	public updateConsumers(
		consumers: ExpositionConsumerListEntity
	): AccessConfigEntity {
		return produce(this, (draft: AccessConfigEntity) => {
			draft.consumers = consumers;
		});
	}

	public updateColumnsConfig(
		columnsConfig: ExpositionColumnConfigListEntity
	): AccessConfigEntity {
		return produce(this, (draft: AccessConfigEntity) => {
			draft.columnsConfig = columnsConfig;
		});
	}

	public updateAccessType(type: AccessType): AccessConfigEntity {
		return produce(this, (draft: AccessConfigEntity) => {
			draft.type = type;
		});
	}

	public updateIsActive(isActive: boolean): AccessConfigEntity {
		return produce(this, (draft: AccessConfigEntity) => {
			draft.isActive = isActive;
		});
	}
}
