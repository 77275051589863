import {
	STEPPER_GLOBAL_OPTIONS,
	StepperSelectionEvent,
} from '@angular/cdk/stepper';
import {
	AfterViewInit,
	Component,
	Inject,
	ViewChild,
} from '@angular/core';
import { MatStepper } from '@angular/material/stepper';
import { AppTagItemEntity, combineAll, DcBaseComponent } from '@dc-common-core';
import { AppIcons, PanelAction } from '@dc-common-ui';
import { Store } from '@ngrx/store';
import { Map, OrderedSet } from 'immutable';
import {
	BehaviorSubject,
	distinctUntilChanged,
	firstValueFrom,
	map,
	Observable,
	ReplaySubject,
	takeUntil,
	tap,
} from 'rxjs';

import { ActionIcons } from '../../../ui/app-actions.icons';
import { DcIcons } from '../../../ui/app-dc.icons';
import { ActionIconsInjectable } from '../../../ui/ui.module';
import {
	closeCreateExpositionModal,
	createExposition,
	ExpositionsSelector,
	getAvailableConsumers,
	getColumnConfigList,
	initExpositionStepper,
	refreshDatablockColumns,
	refreshDatablocks,
} from '../../store';
import { ExpositionAccessConfigComponent } from '../exposition-access-config/exposition-access-config.component';
import { AccessConfigEntity } from '../exposition-access-config/exposition-access-config.entity';
import {
	ColumnConfigErrorKey,
	ColumnConfigListError,
	ExpositionColumnConfigListEntity,
} from '../exposition-columns-config/exposition-column-config-list.entity';
import { ExpositionColumnConfigEntity } from '../exposition-columns-config/exposition-column-config.entity';
import { ExpositionColumnsConfigComponent } from '../exposition-columns-config/exposition-columns-config.component';
// eslint-disable-next-line max-len
import { ExpositionEndpointMetadataConfigComponent } from '../exposition-endpoint-metadata-config/exposition-endpoint-metadata-config.component';
// eslint-disable-next-line max-len
import { ExpositionEndpointMetadataEntity } from '../exposition-endpoint-metadata-config/exposition-endpoint-metadata.entity';
import { ExpositionInternalMetadataConfigComponent } from '../exposition-internal-metadata-config/exposition-internal-metadata.config.component';
import { ExpositionMetadataEntity } from '../exposition-internal-metadata-config/exposition-metadata.entity';
import { ExpositionStep, ExpositionStore } from './exposition-store.service';

enum ColumnConfigAction {
	Activate = 'activate',
	Disable = 'disable',
	CaseInsensitive = 'case-insensitive',
	Filter = 'filter',
	Hash = 'hash',
	Hide = 'hide',
}

@Component({
	selector: 'app-expositions-stepper',
	templateUrl: './exposition-stepper.component.html',
	styleUrls: ['./exposition-stepper.component.scss'],
	providers: [
		ExpositionStore,
		{
			provide: STEPPER_GLOBAL_OPTIONS,
			useValue: {
				displayDefaultIndicatorType: false,
			},
		},
	],
})
export class ExpositionStepperComponent
	extends DcBaseComponent
	implements AfterViewInit
{
	private readonly columnActions: Array<PanelAction> = [
		{
			order: 1,
			command: (): Promise<void> =>
				this.triggerAction(ColumnConfigAction.Activate),
			iconName: '',
			description: $localize`:@@expositions.columns.config.action.activate:`,
			typed: 'grouped-context-action',
		},
		{
			order: 2,
			command: (): Promise<void> =>
				this.triggerAction(ColumnConfigAction.Disable),
			iconName: '',
			description: $localize`:@@expositions.columns.config.action.disable:`,
			typed: 'grouped-context-action',
		},
		{
			order: 3,
			command: (): Promise<void> =>
				this.triggerAction(ColumnConfigAction.Filter),
			iconName: '',
			description: $localize`:@@expositions.columns.config.action.filter:`,
			typed: 'grouped-context-action',
		},
		{
			order: 4,
			command: (): Promise<void> =>
				this.triggerAction(ColumnConfigAction.CaseInsensitive),
			iconName: '',
			description: $localize`:@@expositions.columns.config.action.caseInsensitive:`,
			typed: 'grouped-context-action',
		},
		{
			order: 5,
			command: (): Promise<void> => this.triggerAction(ColumnConfigAction.Hash),
			iconName: '',
			description: $localize`:@@expositions.columns.config.action.hashable:`,
			typed: 'grouped-context-action',
		},
		{
			order: 6,
			command: (): Promise<void> => this.triggerAction(ColumnConfigAction.Hide),
			iconName: '',
			description: $localize`:@@expositions.columns.config.action.hide:`,
			typed: 'grouped-context-action',
		},
	];
	protected readonly Icons = DcIcons;
	protected readonly ColumnConfigErrorKey = ColumnConfigErrorKey;
	private readonly selectedDatablockIdSubject = new ReplaySubject<number>(1);
	private readonly colConfigListErrorsSubject = new BehaviorSubject<
		Map<ColumnConfigErrorKey, ColumnConfigListError>
	>(Map());
	public columnActions$ = new BehaviorSubject<Array<PanelAction>>(
		this.columnActions
	);

	public vo$: Observable<{
		isCreationInProgress: boolean;
		currentStep: number;
		expositionMetadata: ExpositionMetadataEntity;
		endpointMetadata: ExpositionEndpointMetadataEntity;
		columnsConfiguration: ExpositionColumnConfigListEntity;
		columnsConfigurationPanelActions: Array<PanelAction>;
		accessConfig: AccessConfigEntity;
		isCurrentStepValid: boolean;
		availableTags: OrderedSet<AppTagItemEntity>;
		listErrors: Map<ColumnConfigErrorKey, ColumnConfigListError>;
		accessPoints: Array<string>;
		stepsValidity: {
			step1: boolean;
			step2: boolean;
			step3: boolean;
			step4: boolean;
		};
	}>;

	@ViewChild(MatStepper)
	public stepper: MatStepper | null = null;

	@ViewChild(ExpositionColumnsConfigComponent)
	public expositionColumnsConfigCmp: ExpositionColumnsConfigComponent | null = null;

	@ViewChild(ExpositionInternalMetadataConfigComponent)
	public expositionInternalMetadata: ExpositionInternalMetadataConfigComponent | null =
		null;

	@ViewChild(ExpositionEndpointMetadataConfigComponent)
	public expositionEndpointMetadata: ExpositionEndpointMetadataConfigComponent | null =
		null;

	@ViewChild(ExpositionAccessConfigComponent)
	public accessConfigComponentCmp: ExpositionAccessConfigComponent | null = null;

	protected readonly DcIcons = DcIcons;
	protected readonly ExpositionStep = ExpositionStep;
	public constructor(
		private readonly store: Store,
		private readonly stepperStore: ExpositionStore,
		private readonly expositionsSelector: ExpositionsSelector,
		@Inject(ActionIconsInjectable) private readonly actionIcons: AppIcons
	) {
		super();
		this.cmpId = 'exposition-stepper';
		this.store.dispatch(initExpositionStepper());

		this.expositionsSelector
			.getColumnConfigList$()
			.pipe(
				takeUntil(this.onDestroy$),
				distinctUntilChanged(),
				tap((columnsConfig) => {
					this.stepperStore.setColumnsConfiguration(columnsConfig);
				})
			)
			.subscribe();

		const accessPoints$ = this.expositionsSelector
			.getExpositions$()
			.pipe(
				map((expositions) =>
					expositions.elements.toArray().map(([, e]) => e.accessPointLabel)
				)
			);

		this.vo$ = combineAll({
			isCreationInProgress: this.expositionsSelector.isSavingInProgress$(),
			currentStep: this.stepperStore.currentStep$,
			expositionMetadata: this.stepperStore.metadata$,
			endpointMetadata: this.stepperStore.endpointMetadata$,
			accessPoints: accessPoints$,
			columnsConfiguration: this.stepperStore.columnsConfiguration$.pipe(
				tap((l) => this.bootstrapIcons(l))
			),
			columnsConfigurationPanelActions: this.columnActions$,
			accessConfig: this.stepperStore.accessConfig$,
			availableTags: this.expositionsSelector.getAvailableTags$(),
			isCurrentStepValid: this.stepperStore.isCurrentStepValid$,
			stepsValidity: this.stepperStore.stepsValidity$,
			listErrors: this.colConfigListErrorsSubject,
		});
	}

	public ngAfterViewInit(): void {
		super.ngAfterViewInit();
		if (this.stepper === null) {
			console.error('stepper not found');
			return;
		}
	}

	public nextStep(): void {
		if (this.stepper === null) {
			return;
		}
		this.stepper.next();
	}

	public previousStep(): void {
		if (this.stepper === null) {
			return;
		}
		this.stepper.previous();
	}

	public close(): void {
		this.store.dispatch(closeCreateExpositionModal());
	}

	public changeCurrentStep($event: StepperSelectionEvent): void {
		this.stepperStore.changeStep({
			currentStep: $event.selectedIndex,
			previousStep: $event.previouslySelectedIndex,
		});
	}

	public async onStepReady(): Promise<void> {
		const currentStep = await firstValueFrom(this.stepperStore.currentStep$);
		const previousStep = await firstValueFrom(this.stepperStore.previousStep$);
		if (!this.stepper) {
			return;
		}
		if (previousStep === ExpositionStep.Datablock) {
			const previouslySelectedId = await firstValueFrom(
				this.stepperStore.selectedDatablockId$
			);
			const selectedId = await firstValueFrom(this.selectedDatablockIdSubject);
			if (previouslySelectedId !== selectedId) {
				this.stepperStore.setDatablockId(selectedId);
			}
		}
		if (
			previousStep === ExpositionStep.Metadata &&
			this.expositionInternalMetadata &&
			this.expositionEndpointMetadata
		) {
			const internalMetadata =
				await this.expositionInternalMetadata.extractFormData();
			const endPointMetadata =
				await this.expositionEndpointMetadata.extractFormData();
			this.stepperStore.setExpositionMetadata(internalMetadata);
			this.stepperStore.setEndpointMetadata(endPointMetadata);
		}

		if (
			this.expositionColumnsConfigCmp &&
			currentStep === ExpositionStep.Access &&
			previousStep === ExpositionStep.Columns
		) {
			this.stepperStore.setColumnsConfiguration(
				this.expositionColumnsConfigCmp.getUpdatedList()
			);
			const existingColumnsConfig = await firstValueFrom(
				this.stepperStore.columnsConfiguration$
			);

			const hashedColumns = existingColumnsConfig.elements
				.filter((c) => c.isHashed)
				.toArray()
				.map(([id]) => id);

			const hiddenColumns = existingColumnsConfig.elements
				.filter((c) => c.isHidden)
				.toArray()
				.map(([id]) => id);

			const cloned = existingColumnsConfig
				.cloneActiveColumns({
					isHashed: false,
				})
				.setHashedAndHiddenColumns(hashedColumns, hiddenColumns);
			const accessConfig = await firstValueFrom(
				this.stepperStore.accessConfig$
			);

			this.stepperStore.setAccessConfig(
				accessConfig.updateColumnsConfig(cloned)
			);

			const availableConsumers = await firstValueFrom(
				this.expositionsSelector.getAvailableConsumers$()
			);
			if (!availableConsumers.length) {
				this.store.dispatch(getAvailableConsumers());
			}
		}

		if (
			currentStep === ExpositionStep.Columns &&
			(previousStep === ExpositionStep.Metadata ||
				previousStep === ExpositionStep.Datablock)
		) {
			const hasSelectionChanged = await firstValueFrom(
				this.stepperStore.hasDatablockSelectionChanged$
			);
			if (!hasSelectionChanged) {
				return;
			}
			const datablockId = await firstValueFrom(this.selectedDatablockIdSubject);
			this.store.dispatch(
				getColumnConfigList({
					datablockId,
				})
			);
		}

		if (
			previousStep === ExpositionStep.Access &&
			this.accessConfigComponentCmp
		) {
			this.stepperStore.setAccessConfig(
				this.accessConfigComponentCmp.getAccessConfig()
			);
		}
	}

	public async changeDatablockSelection(id: number): Promise<void> {
		await this.stepperStore.setValidity(true);
		this.selectedDatablockIdSubject.next(id);

		const datablockLabel = await firstValueFrom(
			this.expositionsSelector.getDatablockLabel$(id)
		);
		const metadata = await firstValueFrom(this.stepperStore.metadata$);
		if (datablockLabel) {
			this.stepperStore.setExpositionMetadata(
				metadata.updateLabel(datablockLabel)
			);
		}
	}

	public async checkMetadataFormValidity(): Promise<void> {
		const currentStep = await firstValueFrom(this.stepperStore.currentStep$);
		if (
			currentStep === ExpositionStep.Metadata &&
			this.expositionInternalMetadata &&
			this.expositionEndpointMetadata
		) {
			await this.stepperStore.setValidity(
				this.expositionInternalMetadata.internalMetadataForm.form.valid &&
					this.expositionEndpointMetadata.endpointMetadataForm.form.valid
			);
		}
	}

	public async modifyColumnConfigValidity(
		errors: Map<ColumnConfigErrorKey, ColumnConfigListError>
	): Promise<void> {
		const currentStep = await firstValueFrom(this.stepperStore.currentStep$);
		if (
			currentStep === ExpositionStep.Columns &&
			this.expositionColumnsConfigCmp
		) {
			const valid = errors.size === 0;
			await this.stepperStore.setValidity(valid);
			const updatedList = this.expositionColumnsConfigCmp.getUpdatedList();
			this.stepperStore.setColumnsConfiguration(updatedList);
			this.colConfigListErrorsSubject.next(errors);
		}
	}

	public async changeAccessConfigValidity(isValid: boolean): Promise<void> {
		const currentStep = await firstValueFrom(this.stepperStore.currentStep$);
		if (currentStep === ExpositionStep.Access) {
			await this.stepperStore.setValidity(isValid);
		}
	}

	public refreshDatablocks(): void {
		this.store.dispatch(refreshDatablocks());
	}

	public async refreshColumns(): Promise<void> {
		const datablockId = await firstValueFrom(this.selectedDatablockIdSubject);

		this.store.dispatch(
			refreshDatablockColumns({
				datablockId,
			})
		);
	}

	// FIXME: handle error isSaveInProgress on success and error
	public async create(): Promise<void> {
		if (!this.accessConfigComponentCmp) {
			return;
		}
		const datablockId = await firstValueFrom(
			this.stepperStore.selectedDatablockId$
		);
		const metadata = await firstValueFrom(this.stepperStore.metadata$);
		const endpointMetadata = await firstValueFrom(
			this.stepperStore.endpointMetadata$
		);
		const columns = await firstValueFrom(
			this.stepperStore.columnsConfiguration$
		);

		this.store.dispatch(
			createExposition({
				payload: {
					datablockId,
					metadata,
					endpointMetadata,
					accessConfig: this.accessConfigComponentCmp.getAccessConfig(),
					columnsConfig: columns.elements.toArray().map(([, column]) => column),
				},
			})
		);
	}

	private async triggerAction(action: ColumnConfigAction): Promise<void> {
		if (!this.expositionColumnsConfigCmp) {
			return;
		}
		const selection =
			(await this.expositionColumnsConfigCmp.getSelectedColumns()) as Array<ExpositionColumnConfigEntity>;
		const columnsConfig = await firstValueFrom(
			this.stepperStore.columnsConfiguration$
		);
		const selectedIds = selection.map((s) => s.id);
		switch (action) {
			case ColumnConfigAction.Activate:
				this.stepperStore.setColumnsConfiguration(
					columnsConfig.updateIsActive(selectedIds, true)
				);
				break;
			case ColumnConfigAction.Disable:
				this.stepperStore.setColumnsConfiguration(
					columnsConfig.updateIsActive(selectedIds, false)
				);
				break;
			case ColumnConfigAction.CaseInsensitive:
				this.stepperStore.setColumnsConfiguration(
					columnsConfig.updateIsCaseSensitive(selectedIds, true)
				);
				break;
			case ColumnConfigAction.Filter:
				this.stepperStore.setColumnsConfiguration(
					columnsConfig.updateIsFiltered(selectedIds, true)
				);
				break;
			case ColumnConfigAction.Hash:
				this.stepperStore.setColumnsConfiguration(
					columnsConfig.updateHash(selectedIds, true)
				);
				break;
			case ColumnConfigAction.Hide:
				this.stepperStore.setColumnsConfiguration(
					columnsConfig.updateIsHidden(selectedIds, true)
				);
				break;
		}
	}

	private async bootstrapIcons(
		list: ExpositionColumnConfigListEntity
	): Promise<void> {
		const actions = [ActionIcons.FilterWarning, ActionIcons.Filter];
		const actionMap = await firstValueFrom(
			this.actionIcons.getFromRegistry(...actions)
		);
		if (list.hasDuplicatedAliases) {
			this.columnActions$.next([
				...this.columnActions,
				{
					order: 0,
					command: (): void => {
						if (this.expositionColumnsConfigCmp) {
							this.expositionColumnsConfigCmp.filterDuplicates();
						}
					},
					iconName: actionMap.get(ActionIcons.FilterWarning)?.iconName,
					tooltip: $localize`:@@expositions.columns.config.action.filterDuplicates:`,
					description: $localize`:@@expositions.columns.config.action.filterDuplicates:`,
					typed: '',
				},
			]);
		} else {
			this.columnActions$.next(this.columnActions);
		}
	}
}
