import { Component, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { combineAll } from '@dc-common-core';
import { DcBaseComponent, ModalData } from '@dc-common-ui';
import { Store } from '@ngrx/store';
import {
	BehaviorSubject,
	debounceTime,
	firstValueFrom,
	Observable,
	ReplaySubject,
	takeUntil,
	tap,
} from 'rxjs';

import { DcIcons } from '../../../ui/app-dc.icons';
import {
	closeEditExpositionApiModal,
	createExpositionAccess,
	ExpositionsSelector,
	initExpositionAccessModification,
	initExpositionAccessCreation,
	saveExpositionAccess,
} from '../../store';
import { ExpositionAccessConfigComponent } from '../exposition-access-config/exposition-access-config.component';
import { AccessConfigEntity } from '../exposition-access-config/exposition-access-config.entity';

@Component({
	selector: 'app-exposition-access-modal',
	templateUrl: './exposition-access-modal.component.html',
	styleUrls: ['./exposition-access-modal.component.scss'],
})
export class ExpositionAccessModalComponent<
	T extends { expositionId: number; accessId: string }
> extends DcBaseComponent {
	public readonly Icons = DcIcons;
	private readonly isValid$ = new BehaviorSubject<boolean>(
		this.modalData.accessId !== '-1'
	);
	private readonly access$ = new ReplaySubject<AccessConfigEntity>(1);
	private readonly isNew$ = new BehaviorSubject<boolean>(true);
	public vo$: Observable<{
		access: AccessConfigEntity;
		isValid: boolean;
		isNewConfig: boolean;
		canEdit: boolean;
	}>;

	@ViewChild(ExpositionAccessConfigComponent)
	private readonly accessConfigCmp: ExpositionAccessConfigComponent | null = null;

	protected readonly DcIcons = DcIcons;
	public constructor(
		private readonly store: Store,
		private readonly expositionsSelector: ExpositionsSelector,
		@Inject(MAT_DIALOG_DATA) public readonly modalData: ModalData & T
	) {
		super();
		this.cmpId = 'edit-exposition-access';

		if (this.modalData.accessId === '-1') {
			this.store.dispatch(
				initExpositionAccessCreation({
					expositionId: this.modalData.expositionId,
				})
			);
		} else {
			this.store.dispatch(
				initExpositionAccessModification({
					expositionId: this.modalData.expositionId,
					accessId: this.modalData.accessId,
				})
			);
		}

		this.expositionsSelector
			.getAccessConfig$()
			.pipe(
				debounceTime(50),
				takeUntil(this.onDestroy$),
				tap((access) => {
					this.isNew$.next(access.id === '');
					this.access$.next(access);
				})
			)
			.subscribe();

		this.vo$ = combineAll({
			access: this.access$,
			isValid: this.isValid$,
			isNewConfig: this.isNew$,
			canEdit: this.expositionsSelector.getCanEditExposition$(),
		});
	}

	public close(): void {
		this.store.dispatch(
			closeEditExpositionApiModal({
				expositionId: this.modalData.expositionId,
			})
		);
	}

	public modifyValidity(isValid: boolean): void {
		this.isValid$.next(isValid);
	}

	public async save(): Promise<void> {
		if (!this.accessConfigCmp) {
			return;
		}
		const isNew = await firstValueFrom(this.isNew$);
		const access = this.accessConfigCmp.getAccessConfig();

		if (isNew) {
			this.store.dispatch(
				createExpositionAccess({
					expositionId: this.modalData.expositionId,
					access,
				})
			);
		} else {
			this.store.dispatch(
				saveExpositionAccess({
					expositionId: this.modalData.expositionId,
					access,
				})
			);
		}
	}
}
