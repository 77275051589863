import { Record } from 'immutable';

export const CORE = 'core';

export interface IAppRootState {
	[CORE]: CoreState;
}

export interface ICoreState {
	isAdmin: boolean;
}

const stateDefaults: ICoreState = {
	isAdmin: false,
};

export class CoreState extends Record<ICoreState>(stateDefaults) {
	public setIsAdmin(val: boolean): CoreState {
		return this.set('isAdmin', val);
	}
}
