import { Injectable } from '@angular/core';
import { AppIcons, IIcons } from '@dc-common-ui';

export enum ActionIcons {
	AddExpositionAccess = 'action:exposition-add-access',
	ActivateExposition = 'action:exposition-activate',
	ColumnExpositionChooser = 'action:exposition-column-chooser',
	DeActivateExposition = 'action:exposition-deactivate',
	DeleteExpositionData = 'action:exposition-delete-data',
	DeleteElementExposition = 'action:exposition-delete-element',
	EditExpositionColumns = 'action:exposition-edit-columns',
	Filter = 'action:apply-filtering',
	FilterWarning = 'action:filter-duplicates',
	PublishExposition = 'action:exposition-publish',
	UpdateExpositionData = 'action:exposition-update-data',
}

@Injectable()
export class AppActionsIcons extends AppIcons {
	private readonly path = './src/img';
	protected namespace = 'action';
	protected icons: IIcons = {
		[ActionIcons.AddExpositionAccess]: `${this.path}/common/add_circle_48dp_FILL1_wght400_GRAD0_opsz48.svg`,
		[ActionIcons.ActivateExposition]: `${this.path}/common/share_48dp_FILL1_wght300_GRAD0_opsz48.svg`,
		[ActionIcons.ColumnExpositionChooser]: `${this.path}/common/splitscreen_vertical_add_24dp_FILL0_wght0_GRAD0_opsz24.svg`,
		[ActionIcons.DeActivateExposition]: `${this.path}/common/share_off_48dp_FILL1_wght300_GRAD0_opsz48.svg`,
		[ActionIcons.DeleteExpositionData]: `${this.path}/common/output_circle_48dp_FILL0_wght300_GRAD0_opsz48.svg`,
		[ActionIcons.DeleteElementExposition]: `${this.path}/common/delete_forever_48dp_FILL0_wght400_GRAD0_opsz48.svg`,
		[ActionIcons.EditExpositionColumns]: `${this.path}/common/edit_48dp_FILL1_wght400_GRAD0_opsz48.svg`,
		[ActionIcons.Filter]: `${this.path}/common/filter_alt_FILL1_wght300_GRAD0_opsz48.svg`,
		[ActionIcons.FilterWarning]: `${this.path}/common/filter_warning_FILL1_wght300_GRAD0_opsz48.svg`,
		[ActionIcons.PublishExposition]: `${this.path}/common/publish_48dp_FILL0_wght300_GRAD0_opsz48.svg`,
		[ActionIcons.UpdateExpositionData]: `${this.path}/common/input_circle_48dp_FILL0_wght300_GRAD0_opsz48.svg`,
	};
}
