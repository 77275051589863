import { AppTagItemEntity } from '@dc-common-ui';
import { createAction, props } from '@ngrx/store';
import { Map, OrderedSet, Set } from 'immutable';

import { AppMetadataEntity } from '../../ui/components/app-metadata/app-metadata.entity';
import { ExportCharsetEntity } from '../components/export-template-details/export-charset.entity';
import { ExportConnectorEntity } from '../components/export-template-details/export-connector.entity';
import { ExportTemplateDetailsEntity } from '../components/export-template-details/export-template-details.entity';
import { ExportTemplateListItemEntity } from '../components/export-template-list/export-template-list-item.entity';

export enum ExportTemplateActions {
	InitExportConfig = '[Export] init export',
	FetchExportConfig = '[Export] Get template config',
	DeleteById = '[Export] delete by Id',
	DeleteByIdSuccess = '[BE][Export] Successfully deleted export template',
	GoToExportTemplateListView = '[Export] List view',
	FetchExportConfigSuccess = '[BE][Export] Get template config success',
	FetchExportConfigFailure = '[BE][Export] Get template config Failure',
	FetchAvailableConnectorsSuccess = '[BE][Export] Successfully retrieved available connectors for current project',
	FetchAvailableTagsSuccess = '[BE][Export] Successfully retrieved available tags',
	CreateExportConfig = '[BE][Export] save export template',
	UpdateExportConfig = '[BE][Export] update export template',
	CreateExportConfigSuccess = '[BE][Export] save export template success',
	CreateExportConfigFail = '[BE][Export] save  export template failed',
	FetchExportTemplateList = '[Export] List of export template',
	FetchExportTemplateListSuccess = '[BE][Export] Get list of export template success',
	FetchExportTemplateListFail = '[BE][Export] Get list of export template failure',
	ConfigureExportDatablockById = '[Export] Open export configuration modal for selected datablock',
	ResetExportTemplateSelection = '[Export] reset pre-configured export template selection',
	ExportExecutionFinished = '[Export] Export execution finished',
	PredictFileNameBasedOnPattern = '[Export] predict file name based on defined pattern',
	PredictFileNameBasedOnPatternSuccess = '[BE][Export] successfully predicted file name based on defined pattern',
	InitExportDatablockUsingExistingTemplate = '[Datablock List] Init Export currently selected Datablock using existing template',
	SearchAvailableExportTemplate = '[Datablock List] Search available export template',
	FetchSelectedExportTemplateConfig = '[Datablock List][Export] Fetch selected export template config',
	FetchPreConfiguredExportTemplates = '[Datablock List][Export] Fetch all preconfigured export templates',
	ExecuteExportUsingTemplate = '[Datablock List][Export] Execute export using selected/configured template',
	CloseExecuteExportUsingTemplateModal = '[Datablock List][Export] Close execute export using template modal',
	GetAllAvailableTemplates = '[Datablock List][Export] Get all available templates',
}

export const initExportTemplateConfig = createAction(
	ExportTemplateActions.InitExportConfig
);

export const goToExportTemplateListView = createAction(
	ExportTemplateActions.GoToExportTemplateListView
);

export const resetExportTemplate = createAction(
	ExportTemplateActions.ResetExportTemplateSelection
);

export const exportExecutionFinished = createAction(
	ExportTemplateActions.ExportExecutionFinished
);

export const fetchExportTemplateList = createAction(
	ExportTemplateActions.FetchExportTemplateList,
	props<{
		excludePublic: boolean;
		excludeInactive: boolean;
	}>()
);

export const deleteExportTemplatesById = createAction(
	ExportTemplateActions.DeleteById,
	props<{
		elemIds: Array<number>;
		excludePublic: boolean;
		excludeInactive: boolean;
	}>()
);

export const deleteExportTemplatesByIdSuccess = createAction(
	ExportTemplateActions.DeleteByIdSuccess,
	props<{
		excludePublic: boolean;
		excludeInactive: boolean;
	}>()
);

export const fetchExportTemplateListSuccess = createAction(
	ExportTemplateActions.FetchExportTemplateListSuccess,
	props<{
		elems: Map<
			number,
			ExportTemplateListItemEntity<ExportTemplateDetailsEntity>
		>;
	}>()
);

export const fetchExportTemplateListFailure = createAction(
	ExportTemplateActions.FetchExportTemplateListFail,
	props<{ error: unknown }>()
);

export const fetchExportTemplateConfig = createAction(
	ExportTemplateActions.FetchExportConfig,
	props<{
		exportId: number;
	}>()
);

export const fetchExportConfigSuccess = createAction(
	ExportTemplateActions.FetchExportConfigSuccess,
	props<{ entity: ExportTemplateDetailsEntity; metadata: AppMetadataEntity }>()
);

export const fetchExportConfigFailure = createAction(
	ExportTemplateActions.FetchExportConfigFailure,
	props<{ error: unknown }>()
);

export const initExportDatablockUsingExistingTemplate = createAction(
	ExportTemplateActions.InitExportDatablockUsingExistingTemplate,
	props<{
		excludePublic: boolean;
		excludeInactive: boolean;
	}>()
);

export const searchAvailableExportTemplate = createAction(
	ExportTemplateActions.SearchAvailableExportTemplate,
	props<{
		excludePublic: boolean;
		searchTerm: string;
	}>()
);

export const fetchSelectedExportTemplateConfig = createAction(
	ExportTemplateActions.FetchSelectedExportTemplateConfig,
	props<{
		exportId: number;
	}>()
);

export const fetchAvailableConnectorsSuccess = createAction(
	ExportTemplateActions.FetchAvailableConnectorsSuccess,
	props<{ connectors: Map<number, ExportConnectorEntity> }>()
);

export const fetchAvailableTagsSuccess = createAction(
	ExportTemplateActions.FetchAvailableTagsSuccess,
	props<{ tags: OrderedSet<AppTagItemEntity> }>()
);

export const fetchAvailableCharsetsSuccess = createAction(
	'[BE][Charsets] Successfully retrieved available charsets',
	props<{ charsets: Set<ExportCharsetEntity>; predicted: string }>()
);

export const fetchAvailableCharsetsFailure = createAction(
	'[BE][Charsets] Failure while retrieving charsets',
	props<{ error: unknown }>()
);

export const createExportTemplateConfig = createAction(
	ExportTemplateActions.CreateExportConfig,
	props<{
		payload: {
			templateDetails: ExportTemplateDetailsEntity;
			templateMetadata: AppMetadataEntity;
			templateTags: Array<AppTagItemEntity>;
		};
	}>()
);

export const updateExportTemplateConfig = createAction(
	ExportTemplateActions.UpdateExportConfig,
	props<{
		payload: {
			templateDetails: ExportTemplateDetailsEntity;
			templateMetadata: AppMetadataEntity;
			templateTags: Array<AppTagItemEntity>;
		};
	}>()
);

export const createExportTemplateConfigSuccess = createAction(
	ExportTemplateActions.CreateExportConfigSuccess,
	props<{
		templateId: number;
		exportType: string;
	}>()
);

export const createExportTemplateConfigFailure = createAction(
	ExportTemplateActions.CreateExportConfigFail,
	props<{ error: unknown }>()
);

export const configureSelectedDatablockExport = createAction(
	ExportTemplateActions.ConfigureExportDatablockById,
	props<{
		datablockId: number;
		datablockLabel: string;
	}>()
);

export const fetchPreConfiguredExportTemplates = createAction(
	ExportTemplateActions.FetchPreConfiguredExportTemplates
);

export const executeExportUsingTemplate = createAction(
	ExportTemplateActions.ExecuteExportUsingTemplate,
	props<{
		exportTemplate: ExportTemplateDetailsEntity;
	}>()
);

export const closeExecuteExportUsingTemplateModal = createAction(
	ExportTemplateActions.CloseExecuteExportUsingTemplateModal
);

export const getAllAvailableTemplates = createAction(
	ExportTemplateActions.GetAllAvailableTemplates
);

export const predictExportFileNameBasedOnPattern = createAction(
	ExportTemplateActions.PredictFileNameBasedOnPattern,
	props<{ currentConfig: ExportTemplateDetailsEntity }>()
);

export const predictExportFileNameBasedOnPatternSuccess = createAction(
	ExportTemplateActions.PredictFileNameBasedOnPatternSuccess,
	props<{ predicted: string }>()
);
