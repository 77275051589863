import { immerable, produce } from 'immer';
import {AppTagItemEntity} from "@dc-common-ui";

interface IMigrationReadyExpositionItemEntity {
	sourceDatablockId: number;
	label: string;
	accessPoint: string;
	isSelected: boolean;
	tags: Array<AppTagItemEntity>;
}

const entityDefaults: IMigrationReadyExpositionItemEntity = {
	sourceDatablockId: -1,
	label: '',
	accessPoint: '',
	isSelected: false,
	tags: []
};

export class MigrationReadyExpositionItemEntity
	implements IMigrationReadyExpositionItemEntity
{
	private [immerable] = true;
	public sourceDatablockId = entityDefaults.sourceDatablockId;
	public label = entityDefaults.label;
	public accessPoint = entityDefaults.accessPoint;
	public isSelected = entityDefaults.isSelected;
	public tags = entityDefaults.tags;

	public static build(
		params: Partial<IMigrationReadyExpositionItemEntity> = {}
	): MigrationReadyExpositionItemEntity {
		const inst = new MigrationReadyExpositionItemEntity();
		inst.sourceDatablockId =
			params.sourceDatablockId ?? entityDefaults.sourceDatablockId;
		inst.label = params.label ?? entityDefaults.label;
		inst.accessPoint = params.accessPoint ?? entityDefaults.accessPoint;
		inst.tags = params.tags ?? entityDefaults.tags;
		return inst;
	}

	public toggleSelection(
		isSelected: boolean
	): MigrationReadyExpositionItemEntity {
		return produce(this, (draft: MigrationReadyExpositionItemEntity) => {
			draft.isSelected = isSelected;
		});
	}
}
