import { Component } from '@angular/core';
import { combineAll, DcBaseComponent } from '@dc-common-core';
import { merge, Observable, of } from 'rxjs';

import { DcIcons } from '../../../ui/app-dc.icons';
import { PublicationStatus } from '../publication-status.enum';

@Component({
	selector: 'app-exposition-publication-status',
	templateUrl: './exposition-publication-status.component.html',
	styleUrls: ['./exposition-publication-status.component.scss'],
	inputs: ['status'],
})
export class ExpositionPublicationStatusComponent extends DcBaseComponent {
	public vo$: Observable<{ status: PublicationStatus }>;

	protected readonly PublicationStatus = PublicationStatus;
	protected readonly DcIcons = DcIcons;
	public constructor() {
		super();
		this.cmpId = 'exposition-publication-status';

		this.vo$ = combineAll({
			status: merge(
				of(PublicationStatus.Unpublished),
				this.toObservable<PublicationStatus>('status')
			),
		});
	}
}
