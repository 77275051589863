import { Injectable } from '@angular/core';
import { AppIcons, IIcons } from '@dc-common-ui';

export enum DcIcons {
	AddCircle = 'add-circle',
	Warning = 'warning',
	Cancel = 'cancel',
	Check = 'check',
	Column = 'column',
	Common = 'Common',
	Close = 'close',
	Delete = 'delete',
	DeleteForever = 'delete-forever',
	Edit = 'edit',
	Filter = 'filter',
	FilterOff = 'filter-off',
	Flash = 'flash',
	FlashOff = 'flash-off',
	Formula = 'formula',
	Group = 'group',
	Info = 'info',
	Input = 'input',
	Key = 'key',
	LockOpen = 'lock-open',
	MenuMoreOptionsHandle = 'menu-more-options-open-handle',
	Save = 'save',
	Settings = 'settings',
	Share = 'share',
	ShareOff = 'share-off',
	StackedFormula = 'stacked-formula',
	TableRows = 'table-rows',
	OpenInNewTab = 'open-in-new-tab',
	Output = 'output',
	Pending = 'pending',
	Publish = 'publish',
	Update = 'update',
	User = 'user',
	View = 'view',
}

@Injectable()
export class AppDcIcons extends AppIcons {
	private readonly path = './src/img';

	protected icons: IIcons = {
		[DcIcons.AddCircle]: `${this.path}/common/add_circle_48dp_FILL1_wght400_GRAD0_opsz48.svg`,
		[DcIcons.Warning]: `${this.path}/common/warning_48dp_FILL1_wght400_GRAD0_opsz48.svg`,
		[DcIcons.Cancel]: `${this.path}/common/cancel_48dp_FILL1_wght300_GRAD0_opsz48.svg`,
		[DcIcons.Check]: `${this.path}/common/check_circle_48dp_FILL1_wght300_GRAD0_opsz48.svg`,
		[DcIcons.Column]: `${this.path}/common/view_column_FILL1_wght300_GRAD0_opsz48.svg`,
		[DcIcons.Common]: `${this.path}/genericsData-color-solo.svg`,
		[DcIcons.Close]: `${this.path}/common/close_black_24dp.svg`,
		[DcIcons.Delete]: `${this.path}/common/delete_FILL0_wght400_GRAD0_opsz24.svg`,
		[DcIcons.DeleteForever]: `${this.path}/common/delete_forever_48dp_FILL0_wght400_GRAD0_opsz48.svg`,
		[DcIcons.Edit]: `${this.path}/common/edit_48dp_FILL1_wght400_GRAD0_opsz48.svg`,
		[DcIcons.Filter]: `${this.path}/common/filter_alt_FILL1_wght300_GRAD0_opsz48.svg`,
		[DcIcons.FilterOff]: `${this.path}/common/filter_alt_off_48dp.svg`,
		[DcIcons.Flash]: `${this.path}/common/flash_on_48dp_FILL1_wght300_GRAD0_opsz48.svg`,
		[DcIcons.FlashOff]: `${this.path}/common/flash_off_48dp_FILL1_wght300_GRAD0_opsz48.svg`,
		[DcIcons.Formula]: `${this.path}/svg/icon-formula.svg`,
		[DcIcons.Group]: `${this.path}/common/groups_FILL1_wght300_GRAD0_opsz48.svg`,
		[DcIcons.Info]: `${this.path}/common/chat_info_48dp_FILL0_wght400_GRAD0_opsz48.svg`,
		[DcIcons.Input]: `${this.path}/common/input_circle_48dp_FILL0_wght300_GRAD0_opsz48.svg`,
		[DcIcons.Key]: `${this.path}/common/key_48dp_FILL1_wght400_GRAD0_opsz48.svg`,
		[DcIcons.LockOpen]: `${this.path}/common/lock_open_right_FILL0_wght300_GRAD0_opsz48.svg`,
		[DcIcons.MenuMoreOptionsHandle]: `${this.path}/common/more_vert_FILL0_wght400_GRAD0_opsz24.svg`,
		[DcIcons.Save]: `${this.path}/common/save_48dp_FILL0_wght400_GRAD0_opsz48.svg`,
		[DcIcons.Settings]: `${this.path}/common/settings_48dp_FILL0_wght300_GRAD0_opsz48.svg`,
		[DcIcons.Share]: `${this.path}/common/share_48dp_FILL1_wght300_GRAD0_opsz48.svg`,
		[DcIcons.ShareOff]: `${this.path}/common/share_off_48dp_FILL1_wght300_GRAD0_opsz48.svg`,
		[DcIcons.StackedFormula]: `${this.path}/common/dc-formula.svg`,
		[DcIcons.TableRows]: `${this.path}/common/table_rows_narrow_48dp_FILL1_wght300_GRAD0_opsz48.svg`,
		[DcIcons.OpenInNewTab]: `${this.path}/common/open_in_new_48dp_FILL0_wght300_GRAD0_opsz48.svg`,
		[DcIcons.Output]: `${this.path}/common/output_circle_48dp_FILL0_wght300_GRAD0_opsz48.svg`,
		[DcIcons.Pending]: `${this.path}/common/pending_48dp_FILL0_wght300_GRAD0_opsz48.svg`,
		[DcIcons.Publish]: `${this.path}/common/publish_48dp_FILL0_wght300_GRAD0_opsz48.svg`,
		[DcIcons.Update]: `${this.path}/common/update_48dp_FILL0_wght300_GRAD0_opsz48.svg`,
		[DcIcons.User]: `${this.path}/common/person_FILL1_wght300_GRAD0_opsz48.svg`,
		[DcIcons.View]: `${this.path}/common/visibility_24dp_FILL0_wght400_GRAD0_opsz24.svg`,
	};
}
