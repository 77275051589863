import { Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { combineAll, DcBaseComponent } from '@dc-common-core';
import { DxDataGridComponent } from 'devextreme-angular';
import { SelectionChangedEvent } from 'devextreme/ui/data_grid';
import { Observable } from 'rxjs';

import { DcIcons } from '../../../ui/app-dc.icons';
import { ExpositionConsumerListEntity } from './exposition-consumer-list.entity';
import { ExpositionConsumerEntity } from './exposition-consumer.entity';

@Component({
	selector: 'app-exposition-consumer-list',
	templateUrl: './exposition-consumer-list.component.html',
	styleUrls: ['./exposition-consumer-list.component.scss'],
	inputs: ['consumerList'],
	exportAs: 'ConsumersGrid',
})
export class ExpositionConsumerListComponent extends DcBaseComponent {
	public selectionCount = 0;
	@ViewChild(DxDataGridComponent)
	private readonly grid!: DxDataGridComponent;
	@Output()
	public removeConsumers = new EventEmitter<Array<string>>();
	public vo$: Observable<{
		list: ExpositionConsumerListEntity;
	}>;

	protected readonly DcIcons = DcIcons;
	protected readonly alert = alert;
	public constructor() {
		super();
		this.cmpId = 'exposition-consumers-list';
		this.dxLocalStorageKey = 'dx.grid.expositions:consumers-list';
		this.vo$ = combineAll({
			list: this.toObservable<ExpositionConsumerListEntity>('consumerList'),
		});
	}

	public removeConsumer(key: number): void {
		this.removeConsumers.emit([`${key}`]);
	}

	public onSelectionChange($event: SelectionChangedEvent): void {
		this.selectionCount = $event.currentSelectedRowKeys.length;
	}

	public search(searchTerm: string): void {
		if (this.grid === null) {
			return;
		}
		this.grid.instance.searchByText(searchTerm);
	}

	public getSelectedConsumers(): Promise<Array<ExpositionConsumerEntity>> {
		return this.grid.instance.getSelectedRowsData();
	}
}
