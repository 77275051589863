<ng-container *ngIf="vo$ | async; let vo">
	<app-page>
		<app-page-header class="orphan-project-page-header">
			<h1 i18n="@@orphan.projects">Orphan projects</h1>
		</app-page-header>

		<app-page-content>
			<ng-container *ngIf="vo.isLoading">
				<mat-spinner></mat-spinner>
			</ng-container>

			<ng-container *ngIf="!vo.isLoading">
				<div class="orphan-project">
					<span class="orphan-project__project-label" i18n="@@orphan.project">
						Project
					</span>
					<span
						class="orphan-project__admin-selector"
						i18n="@@orphan.projectAdmin.selection">
						orphan.projectAdmin.selection
					</span>
					<mat-divider class="orphan-project__sep"></mat-divider>
				</div>
				<ng-container *ngIf="vo.orphanProjectList.orphanProjects.length === 0">
					<span i18n="@@orphan.project.none">orphan-notfound</span>
				</ng-container>
				<div
					*ngFor="
						let project of vo.orphanProjectList.orphanProjects;
						index as i;
						last as isLast
					"
					class="orphan-project">
					<span class="orphan-project__project-label">{{ project.label }}</span>
					<mat-form-field
						class="orphan-project__admin-selector"
						[appearance]="Appearance">
						<mat-label i18n="@@orphan.projects.availableAdmins">
							project.admin
						</mat-label>
						<mat-select
							id="{{ cmpId + '-project-' + i + '-select' }}"
							disableOptionCentering
							[ngModel]="vo.projectAdminMap.get(project.id)">
							<mat-option
								id="{{ cmpId + '-project-' + i + '-admin-' + j }}"
								*ngFor="
									let admin of vo.orphanProjectList.availableAdmins;
									let j = index
								"
								(onSelectionChange)="onSelectionChange(project.id, admin.id)"
								[value]="admin.id">
								{{ admin.name }}
							</mat-option>
						</mat-select>
					</mat-form-field>
					<button
						id="{{ cmpId + '-project-' + i + '-assign-btn' }}"
						mat-flat-button
						color="primary"
						i18n="@@orphan.projects.assign"
						class="orphan-project__assign-btn"
						[disabled]="!vo.projectAdminMap.has(project.id)"
						(click)="save(project.id)">
						Assign
					</button>
					<mat-divider
						*ngIf="!isLast"
						class="orphan-project__sep"></mat-divider>
				</div>
			</ng-container>
		</app-page-content>
	</app-page>
</ng-container>
