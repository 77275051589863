import { Injectable } from '@angular/core';
import {
	firstValueFrom,
	map,
	Observable,
	shareReplay,
	switchMap,
	tap,
} from 'rxjs';

import { AjsInjectorService } from './ajs-injector.service';

@Injectable()
export class AjsScopeService {
	private readonly $rootScope: Observable<any>;
	private readonly $scope: Observable<any>;

	public constructor(private readonly ajsInjectorService: AjsInjectorService) {
		this.$scope = this.ajsInjectorService.$injector.pipe(
			map(($injector) => $injector.get('$scope')),
			shareReplay(1)
		);
		this.$rootScope = this.ajsInjectorService.$injector.pipe(
			map(($injector) => $injector.get('$rootScope')),
			tap(($rootScope) => $rootScope.$broadcast('ng:ready')),
			shareReplay(1)
		);
	}

	public async broadcastOnRootScope(
		event: string,
		payload?: any
	): Promise<void> {
		const $rootScope = await firstValueFrom(this.$rootScope);
		$rootScope.$broadcast(event, payload);
	}

	public listenOnRootScope<T>(evtName: string): Observable<T> {
		return this.$rootScope.pipe(
			switchMap(
				($rootScope) =>
					new Observable<T>((observer) =>
						$rootScope.$on(evtName, (event: any, value: T | undefined) => {
							observer.next(value);
						})
					)
			)
		);
	}
}
