import { Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { MatFormFieldAppearance } from '@angular/material/form-field';
import {
	AppTagConfiguratorComponent,
	AppTagItemEntity,
	combineAll,
	DcBaseComponent,
} from '@dc-common-core';
import { OrderedSet } from 'immutable';
import { merge, Observable, of, takeUntil, tap } from 'rxjs';

import { uniqueValue } from '../../../ui/form/unique-value.validator';
import { ValidationErrorKeys } from '../../../ui/form/validation-erros-keys';
import { ExpositionEndpointMetadataEntity } from './exposition-endpoint-metadata.entity';
import {
	ExpositionEndpointMetadataForm,
	EndpointMetadataFormControls,
} from './exposition-endpoint-metadata.form';

@Component({
	selector: 'app-exposition-endpoint-metadata-config',
	templateUrl: './exposition-endpoint-metadata-config.component.html',
	styleUrls: ['./exposition-endpoint-metadata-config.component.scss'],
	providers: [ExpositionEndpointMetadataForm],
	inputs: ['expositionMetadata', 'isInViewMode', 'accessPoints'],
})
export class ExpositionEndpointMetadataConfigComponent extends DcBaseComponent {
	public Appearance: MatFormFieldAppearance = 'legacy';
	public EndpointMetadataFormControls = EndpointMetadataFormControls;

	@ViewChild(AppTagConfiguratorComponent)
	public tagConfiguratorComponentCmp: AppTagConfiguratorComponent | null = null;

	@Output()
	public hasEndPointMetadataChanged = new EventEmitter<boolean>();

	public vo$: Observable<{
		expositionMetadata: ExpositionEndpointMetadataEntity;
		isInViewMode: boolean;
	}>;
	protected readonly ValidationErrorKeys = ValidationErrorKeys;
	public constructor(
		public readonly endpointMetadataForm: ExpositionEndpointMetadataForm
	) {
		super();
		this.cmpId = 'exposition-endpoint-metadata-config';
		this.toObservable<ExpositionEndpointMetadataEntity>('expositionMetadata')
			.pipe(
				takeUntil(this.onDestroy$),
				tap((config) => {
					this.endpointMetadataForm.populate(config);
				})
			)
			.subscribe();

		this.toObservable<Array<string>>('accessPoints')
			.pipe(
				tap((points) =>
					this.endpointMetadataForm.form
						.get(EndpointMetadataFormControls.AccessPoint)
						?.addValidators(uniqueValue(points))
				)
			)
			.subscribe();

		this.endpointMetadataForm.form.valueChanges
			.pipe(
				takeUntil(this.onDestroy$),
				tap(() => {
					this.hasEndPointMetadataChanged.emit();
				})
			)
			.subscribe();

		this.vo$ = combineAll({
			expositionMetadata: merge(
				of(ExpositionEndpointMetadataEntity.build({})),
				this.toObservable<ExpositionEndpointMetadataEntity>(
					'expositionMetadata'
				)
			),
			isInViewMode: merge(
				of(false),
				this.toObservable<boolean>('isInViewMode')
			),
		});
	}

	public async extractFormData(): Promise<ExpositionEndpointMetadataEntity> {
		let keywords = new Array<AppTagItemEntity>();
		if (this.tagConfiguratorComponentCmp) {
			keywords = await this.tagConfiguratorComponentCmp.getUsedTags();
		}
		const endpointMetadata = this.endpointMetadataForm.extract();
		return ExpositionEndpointMetadataEntity.build({
			accessPoint: endpointMetadata?.accessPoint,
			title: endpointMetadata?.title,
			details: endpointMetadata?.details,
			keywords: OrderedSet(keywords),
		});
	}
}
