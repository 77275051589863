import {
	AfterViewInit,
	Component,
	EventEmitter,
	Output,
	ViewChild,
} from '@angular/core';
import { ColumnType, combineAll, DcBaseComponent } from '@dc-common-core';
import { DxDataGridComponent } from 'devextreme-angular';
import { SelectionChangedEvent } from 'devextreme/ui/data_grid';
import { ValidationResult } from 'devextreme/ui/validator';
import { Map } from 'immutable';
import {
	BehaviorSubject,
	firstValueFrom,
	map,
	merge,
	Observable,
	of,
	ReplaySubject,
	takeUntil,
	tap,
} from 'rxjs';

import { DcIcons } from '../../../ui/app-dc.icons';
import {
	ColumnConfigErrorKey,
	ColumnConfigListError,
	ExpositionColumnConfigListEntity,
} from './exposition-column-config-list.entity';
import { ExpositionColumnConfigEntity } from './exposition-column-config.entity';

@Component({
	selector: 'app-exposition-columns-config',
	templateUrl: './exposition-columns-config.component.html',
	styleUrls: ['./exposition-columns-config.component.scss'],
	inputs: [
		'columnConfigList',
		'isInViewMode',
		'isInAccessConfigMode',
		'accessExtraColumnsConfig',
	],
	exportAs: 'ColumnsGrid',
})
export class ExpositionColumnsConfigComponent
	extends DcBaseComponent
	implements AfterViewInit
{
	public DcIcons = DcIcons;
	protected readonly ColumnType = ColumnType;
	public hideAllActiveToggle = false;
	public hideAllFilterToggle = false;
	public hideAllIcToggle = false;
	public hideAllHashToggle = false;
	public hideAllMaskToggle = false;
	public selectionCount = 0;
	public filteredView = false;
	public aliasPattern = '^[a-zA-Z0-9_]*$';
	public activeCount = 1;

	// public hasValidationErrorSubject = new BehaviorSubject<boolean>(false);
	public columnListSubject =
		new BehaviorSubject<ExpositionColumnConfigListEntity>(
			ExpositionColumnConfigListEntity.build([])
		);
	public aliasUpdateSubject = new ReplaySubject<string>(1);

	public vo$: Observable<{
		columnConfigList: ExpositionColumnConfigListEntity;
		isInViewMode: boolean;
		activeColumnsCount: number;
		filteredColumnsCount: number;
		isInAccessConfigMode: boolean;
	}>;

	@Output()
	public errors = new EventEmitter<
		Map<ColumnConfigErrorKey, ColumnConfigListError>
	>();

	@ViewChild(DxDataGridComponent)
	private readonly grid: DxDataGridComponent | null = null;

	public constructor() {
		super();
		this.cmpId = 'expositions-columns-config';
		this.dxLocalStorageKey = 'dx.grid.expositions:columns-config';

		this.toObservable<ExpositionColumnConfigListEntity>('columnConfigList')
			.pipe(
				takeUntil(this.onDestroy$),
				tap((list) => {
					this.columnListSubject.next(list);
					this.errors.next(list.errors);
				})
			)
			.subscribe();

		const activeColsCount$ = this.columnListSubject.pipe(
			takeUntil(this.onDestroy$),
			map((list) =>
				list.elements.reduce((acc, curr) => (curr.isActive ? acc + 1 : acc), 0)
			)
		);

		const filteredColsCount$ = this.columnListSubject.pipe(
			takeUntil(this.onDestroy$),
			tap((l) => {
				if (this.filteredView && !l.hasDuplicatedAliases) {
					this.filterDuplicates();
				}
			}),
			map((list) =>
				list.elements.reduce(
					(acc, curr) => (curr.isFiltered ? acc + 1 : acc),
					0
				)
			)
		);

		this.vo$ = combineAll({
			isInViewMode: merge(
				of(false),
				this.toObservable<boolean>('isInViewMode')
			),
			isInAccessConfigMode: merge(
				of(false),
				this.toObservable<boolean>('isInAccessConfigMode')
			),
			activeColumnsCount: activeColsCount$,
			filteredColumnsCount: filteredColsCount$,
			columnConfigList: this.columnListSubject,
		});
	}

	public ngAfterViewInit(): void {
		super.ngAfterViewInit();
		this.toObservable<boolean>('isInAccessConfigMode')
			.pipe(
				tap((isInAccessMode) => {
					if (isInAccessMode) {
						this.cmpId = 'expositions-access-columns-config';
					}
				})
			)
			.subscribe();
	}

	public async toggleActiveAllRows(): Promise<void> {
		let updatedList;
		const columnsConfig = await firstValueFrom(this.columnListSubject);
		const ids = columnsConfig.getAllIds();
		if (columnsConfig.allActive) {
			updatedList = columnsConfig.updateIsActive(ids, false);
		} else {
			updatedList = columnsConfig.updateIsActive(ids, true);
		}
		this.columnListSubject.next(updatedList);
		this.errors.next(updatedList.errors);
	}

	public async toggleFilterAllRows(): Promise<void> {
		let updatedList;
		const columnsConfig = await firstValueFrom(this.columnListSubject);
		const ids = columnsConfig.getAllIds();
		if (columnsConfig.allFilter) {
			updatedList = columnsConfig.updateIsFiltered(ids, false);
		} else {
			updatedList = columnsConfig.updateIsFiltered(ids, true);
		}
		this.columnListSubject.next(updatedList);
		this.errors.next(updatedList.errors);
	}

	public async toggleIcAllRows(): Promise<void> {
		let updatedList;
		const columnsConfig = await firstValueFrom(this.columnListSubject);
		const ids = columnsConfig.getAllIds();
		if (columnsConfig.allIc) {
			updatedList = columnsConfig.updateIsCaseSensitive(ids, false);
		} else {
			updatedList = columnsConfig.updateIsCaseSensitive(ids, true);
		}
		this.columnListSubject.next(updatedList);
		this.errors.next(updatedList.errors);
	}

	public async toggleHashAllRows(): Promise<void> {
		let updatedList;
		const columnsConfig = await firstValueFrom(this.columnListSubject);
		const ids = columnsConfig.getAllIds();
		if (columnsConfig.allHash) {
			updatedList = columnsConfig.updateHash(ids, false);
		} else {
			updatedList = columnsConfig.updateHash(ids, true);
		}
		this.columnListSubject.next(updatedList);
		this.errors.next(updatedList.errors);
	}

	public async toggleMaskAllRows(): Promise<void> {
		let updatedList;
		const columnsConfig = await firstValueFrom(this.columnListSubject);
		const ids = columnsConfig.getAllIds();
		if (columnsConfig.allHidden) {
			updatedList = columnsConfig.updateIsHidden(ids, false);
		} else {
			updatedList = columnsConfig.updateIsHidden(ids, true);
		}
		this.columnListSubject.next(updatedList);
		this.errors.next(updatedList.errors);
	}

	public toggleAllTriggered($event: MouseEvent): void {
		$event.stopPropagation();
	}

	public async updateIsActive(
		ids: Array<string>,
		checked: boolean
	): Promise<void> {
		const columnsConfig = await firstValueFrom(this.columnListSubject);
		const updatedList = columnsConfig.updateIsActive(ids, checked);
		this.columnListSubject.next(updatedList);
		this.errors.next(updatedList.errors);
	}

	public async updateIsPrimaryKey(
		row: ExpositionColumnConfigEntity,
		checked: boolean
	): Promise<void> {
		const columnsConfig = await firstValueFrom(this.columnListSubject);
		if (row.isList || row.columnType === ColumnType.Bool) {
			return;
		}
		const updatedList = columnsConfig.updateIsPrimaryKey(row.id, checked);
		this.columnListSubject.next(updatedList);
		this.errors.next(updatedList.errors);
	}

	public async updateIsFiltered(
		ids: Array<string>,
		checked: boolean
	): Promise<void> {
		const columnsConfig = await firstValueFrom(this.columnListSubject);
		const updatedList = columnsConfig.updateIsFiltered(ids, checked);
		this.columnListSubject.next(updatedList);
		this.errors.next(updatedList.errors);
	}

	public async updateIsCaseSensitive(
		ids: Array<string>,
		checked: boolean
	): Promise<void> {
		const columnsConfig = await firstValueFrom(this.columnListSubject);
		const updatedList = columnsConfig.updateIsCaseSensitive(ids, checked);
		this.columnListSubject.next(updatedList);
		this.errors.next(updatedList.errors);
	}

	public async updateIsHashed(
		ids: Array<string>,
		checked: boolean
	): Promise<void> {
		const columnsConfig = await firstValueFrom(this.columnListSubject);
		const updatedList = columnsConfig.updateHash(ids, checked);
		this.columnListSubject.next(updatedList);
		this.errors.next(updatedList.errors);
	}

	public async updateIsHidden(
		ids: Array<string>,
		checked: boolean
	): Promise<void> {
		const columnsConfig = await firstValueFrom(this.columnListSubject);
		const updatedList = columnsConfig.updateIsHidden(ids, checked);
		this.columnListSubject.next(updatedList);
		this.errors.next(updatedList.errors);
	}

	public onSelectionChange($event: SelectionChangedEvent): void {
		this.selectionCount = $event.selectedRowsData.length;
	}

	public async updateDescription(
		key: string,
		event: Record<string, string>
	): Promise<void> {
		const columnsConfig = await firstValueFrom(this.columnListSubject);
		if (event.isValid) {
			const updatedList = columnsConfig.updateDescription(key, event.value);
			this.columnListSubject.next(updatedList);
			this.errors.next(updatedList.errors);
			return;
		}
		this.errors.next(columnsConfig.errors);
	}

	public updateUsedAlias($ev: string): void {
		this.aliasUpdateSubject.next($ev);
	}

	public async updateAlias(
		event: ValidationResult,
		row: ExpositionColumnConfigEntity
	): Promise<void> {
		const columnsConfig = await firstValueFrom(this.columnListSubject);
		if (event.isValid) {
			const updatedList = columnsConfig.updateAlias(row.id, event.value);
			this.columnListSubject.next(updatedList);
			this.errors.next(updatedList.errors);
			return;
		}
		this.errors.next(columnsConfig.errors);
	}

	public async getSelectedColumns(): Promise<
		Array<ExpositionColumnConfigEntity>
	> {
		if (!this.grid) {
			return [];
		}
		return (await this.grid.instance.getSelectedRowsData()) as Array<ExpositionColumnConfigEntity>;
	}

	public search(searchTerm: string): void {
		if (this.grid === null) {
			return;
		}
		this.grid.instance.searchByText(searchTerm);
	}

	public filterDuplicates(): void {
		if (!this.grid) {
			return;
		}

		if (!this.filteredView) {
			this.filteredView = true;
			this.grid.instance.filter(['filterField', 'contains', 'DUPLICATED']);
		} else {
			this.filteredView = false;
			this.grid.instance.clearFilter();
		}
	}

	public getUpdatedList(): ExpositionColumnConfigListEntity {
		return this.columnListSubject.value;
	}
}
