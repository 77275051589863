export enum PublicationStatus {
	Unpublished = 'unpublished',
	Available = 'available',
	Unavailable = 'unAvailable',
	Running = 'running',
	Waiting = 'waiting',
	Success = 'success',
	Error = 'error',
	Unknown = 'unknown',
}
