import { immerable, produce } from 'immer';

export interface IExpositionPreviousConsumerEntity {
	id: string;
	fullName: string;
	description: string;
	unRecognized: boolean;
	isGroup: boolean;
	targetConsumerId: string | number | null;
}

const entityDefaults: IExpositionPreviousConsumerEntity = {
	id: '',
	fullName: '',
	description: '',
	unRecognized: true,
	isGroup: false,
	targetConsumerId: null,
};

export class ExpositionPreviousConsumerEntity
	implements IExpositionPreviousConsumerEntity
{
	private [immerable] = true;
	public id = entityDefaults.id;
	public isGroup = entityDefaults.isGroup;
	public fullName = entityDefaults.fullName;
	public description = entityDefaults.description;
	public unRecognized = entityDefaults.unRecognized;
	public targetConsumerId = entityDefaults.targetConsumerId;

	public static build(
		params: IExpositionPreviousConsumerEntity
	): ExpositionPreviousConsumerEntity {
		const instance = new ExpositionPreviousConsumerEntity();
		instance.id = params.id ?? entityDefaults.id;
		instance.isGroup = params.isGroup ?? entityDefaults.isGroup;
		instance.fullName = params.fullName ?? entityDefaults.fullName;
		instance.description = params.description ?? entityDefaults.description;
		instance.unRecognized = params.unRecognized ?? entityDefaults.unRecognized;
		instance.targetConsumerId =
			params.targetConsumerId ?? entityDefaults.targetConsumerId;
		return instance;
	}

	public mapTo(consumerId: number): ExpositionPreviousConsumerEntity {
		return produce(this, (draft: ExpositionPreviousConsumerEntity) => {
			draft.targetConsumerId = consumerId;
		});
	}
}
