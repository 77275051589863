import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

import { uniqueValueFn } from './unique-value';

export const uniqueValue =
	(existingValues: Array<string>): ValidatorFn =>
	(control: AbstractControl): ValidationErrors | null => {
		const { value } = control;
		return uniqueValueFn(value, existingValues);
	};
