<ng-container *ngIf="vo$ | async; let vo">
	<app-page>
		<app-page-header class="formula-page-header">
			<mat-icon [svgIcon]="DcIcons.StackedFormula"></mat-icon>
			<h1 i18n="@@formulas.usages">Formulas Usage</h1>
		</app-page-header>

		<app-page-content>
			<dx-data-grid
				keyExpr="id"
				height="700"
				[elementAttr]="{
					id: 'gridContainer',
					class: 'can-be-passed-like-this'
				}"
				[dataSource]="vo.formulas | toArray"
				[hoverStateEnabled]="true"
				[showColumnHeaders]="true"
				[allowColumnReordering]="true"
				[allowColumnResizing]="true"
				[columnAutoWidth]="true"
				[showRowLines]="true"
				[showColumnLines]="true"
				[rowAlternationEnabled]="true"
				[showBorders]="true"
				[columnMinWidth]="200">
				<dxo-load-panel
					[height]="100"
					[width]="100"
					shadingColor="rgba(0,0,0,0.4)"
					[enabled]="true"
					[showIndicator]="true"
					[showPane]="true"
					[shading]="true"
					indicatorSrc="./src/img/rolling.svg"></dxo-load-panel>

				<!-- Fixable in position columns	-->
				<dxo-column-fixing [enabled]="false"></dxo-column-fixing>
				<!--				<dxi-column [fixed]="false" [allowReordering]="false" [allowResizing]="true" type="selection"></dxi-column>-->

				<ng-container *ngFor="let col of vo.availableColumns | toArray">
					<dxi-column
						[dataField]="col.field"
						[caption]="col.alias"
						[groupIndex]="col.groupIdx"
						[fixed]="false"
						[allowReordering]="false"
						[allowResizing]="true"
						[allowHiding]="true"
						[allowGrouping]="true"
						[allowSearch]="true"
						[allowFiltering]="col.isFilterable ?? false"
						[allowSorting]="true"
						cellTemplate="colTemplate"></dxi-column>
				</ng-container>

				<dxi-column
					caption="Actions"
					[visible]="false"
					[showInColumnChooser]="false"
					type="buttons"
					[width]="110">
					<dxi-button icon="trash" [visible]="true" name="delete"></dxi-button>
					<dxi-button
						hint="Clone"
						icon="copy"
						[visible]="true"
						[disabled]="true"></dxi-button>
					<dxi-button template="myCommand">
						<span class="dx-link" *dxTemplate="let data of 'myCommand'"></span>
					</dxi-button>
				</dxi-column>

				<dxo-column-chooser
					[allowSearch]="true"
					[enabled]="true"
					[mode]="'select'">
					<dxo-selection
						[allowSelectAll]="true"
						[recursive]="false"></dxo-selection>
				</dxo-column-chooser>

				<dxo-export
					[enabled]="true"
					[allowExportSelectedData]="true"
					[formats]="['pdf']"></dxo-export>

				<dxo-grouping
					[autoExpandAll]="true"
					[expandMode]="'all'"
					[allowCollapsing]="true"
					[contextMenuEnabled]="false"></dxo-grouping>

				<dxo-group-panel
					[allowColumnDragging]="false"
					[visible]="true"></dxo-group-panel>

				<dxo-toolbar>
					<dxi-item name="groupPanel"></dxi-item>
					<dxi-item>
						<dx-button
							(click)="refresh()"
							icon="refresh"
							[text]="tranRefresh"></dx-button>
					</dxi-item>
					<dxi-item>
						<dx-button
							width="170"
							(click)="toggle()"
							[icon]="vo.isAllExpanded ? 'collapse' : 'expand'"
							[text]="
								vo.isAllExpanded ? transCollapseAll : transExpandAll
							"></dx-button>
					</dxi-item>
					<dxi-item name="columnChooserButton"></dxi-item>
					<dxi-item name="searchPanel"></dxi-item>
				</dxo-toolbar>

				<!-- Master - detail	-->
				<dxo-master-detail
					[enabled]="true"
					[autoExpandAll]="vo.isAllExpanded"
					[template]="'list-item'"></dxo-master-detail>

				<div class="master-detail-layout" *dxTemplate="let item of 'list-item'">
					<app-formula-usage-details
						[details]="item.data"></app-formula-usage-details>
				</div>

				<div class="" *dxTemplate="let entry of 'colTemplate'">
					<span
						[ngClass]="{
							deprecated:
								entry.data.isDeprecated && entry.column.dataField === 'label'
						}">
						{{ entry.value | toValue }}
					</span>
				</div>

				<!-- Scrolling mode	-->
				<dxo-scrolling rowRenderingMode="virtual"></dxo-scrolling>

				<!-- Search input -->
				<dxo-search-panel [visible]="true"></dxo-search-panel>

				<!-- Filterable columns	-->
				<dxo-filter-row [visible]="true"></dxo-filter-row>

				<!-- Sorting	-->
				<dxo-sorting mode="multiple"></dxo-sorting>

				<!-- State persistence	-->
				<dxo-state-storing
					[enabled]="true"
					type="localStorage"
					[savingTimeout]="DxDataGridTimeout"
					[storageKey]="dxLocalStorageKey"></dxo-state-storing>

				<!-- Pagination -->
				<dxo-paging [pageSize]="1000"></dxo-paging>
				<dxo-pager
					#pager
					[visible]="false"
					[allowedPageSizes]="allowedPageSizes"
					[displayMode]="displayMode"
					[showPageSizeSelector]="true"
					[showInfo]="true"
					[showNavigationButtons]="true"></dxo-pager>
			</dx-data-grid>
		</app-page-content>
	</app-page>
</ng-container>
