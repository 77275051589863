<div *ngIf="vo$ | async; let vo" class="formula-usage-details">
	<div *ngIf="vo.details.datablock && vo.details.datablock.length > 0">
		<span i18n="@@formula.usage.in.datablock">Usage in DataBlocks</span>
		<div *ngFor="let usage of vo.details.datablock" class=" ">
			<a
				id="{{ cmpId }}-{{ vo.details.category }}-{{ vo.details.id }}"
				appNavigate
				[navigateTo]="usage.url">
				{{ usage.label }}
			</a>
		</div>
	</div>

	<div *ngIf="vo.details.entityLink && vo.details.entityLink.length > 0">
		<span i18n="@@formula.usage.in.entities.links">
			Usage in Entities - Links
		</span>
		<div *ngFor="let usage of vo.details.entityLink" class=" ">
			<a
				id="{{ cmpId }}-{{ vo.details.category }}-{{ vo.details.id }}"
				appNavigate
				[navigateTo]="usage.url">
				{{ usage.label }}
			</a>
		</div>
	</div>

	<div
		*ngIf="
			vo.details.entityCharacteristic &&
			vo.details.entityCharacteristic.length > 0
		">
		<span i18n="@@formula.usage.in.entities">
			Usage in Entities - Characteristics
		</span>
		<div *ngFor="let usage of vo.details.entityCharacteristic" class=" ">
			<a
				id="{{ cmpId }}-{{ vo.details.category }}-{{ vo.details.id }}"
				appNavigate
				[navigateTo]="usage.url">
				{{ usage.label }}
			</a>
		</div>
	</div>

	<div *ngIf="vo.details.hdSource && vo.details.hdSource.length > 0">
		<span i18n="@@formula.usage.in.hd">Usage in HD sources</span>
		<div *ngFor="let usage of vo.details.hdSource" class=" ">
			<a
				id="{{ cmpId }}-{{ vo.details.category }}-{{ vo.details.id }}"
				appNavigate
				[navigateTo]="usage.url">
				{{ usage.label }}
			</a>
		</div>
	</div>
</div>
