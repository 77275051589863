import { AppTagItemEntity } from '@dc-common-core';
import { OrderedSet, Record } from 'immutable';

import { ExpositionColumnConfigListEntity } from '../components/exposition-columns-config/exposition-column-config-list.entity';
import { ExpositionConsumerEntity } from '../components/exposition-consumers-list/exposition-consumer.entity';
import { ExpositionDatablockColumnEntity } from '../components/exposition-datablock-list/exposition-datablock-column.entity';
import { ExpositionDatablockListEntity } from '../components/exposition-datablock-list/exposition-datablock-list.entity';
import { ExpositionHistoryListEntity } from '../components/exposition-history-list/exposition-history-list.entity';
import { ExpositionListEntity } from '../components/exposition-list/exposition-list.entity';
import { ExpositionPreviousConsumerEntity } from '../components/exposition-migration-stepper/exposition-previous-consumer.entity';
import { MigrationReadyExpositionList } from '../components/exposition-migration-stepper/migration-ready-exposition-list.entity';
import { MigratedExpositionListEntity } from '../components/exposition-migrations-list/migrated-exposition-list.entity';
import { ExpositionEntity } from '../components/exposition-view/exposition.entity';
import { ExpositionPermissionEntity } from '../exposition.permissions';

export type PublicationAction =
	| 'publish'
	| 'clear-data'
	| 'update-data'
	| 'update'
	| 'activate'
	| 'deactivate'
	| 'none';

export interface IExpositionsState {
	loading: boolean;
	isLoadingDatablockColumns: boolean;
	isSavingExposition: boolean;
	permissions: ExpositionPermissionEntity;
	actionInProgress: PublicationAction;
	availableTags: OrderedSet<AppTagItemEntity>; // FIXME: move to core state
	availableConsumers: Array<ExpositionConsumerEntity>; // FIXME: move to core state
	expositions: ExpositionListEntity;
	v1Expositions: MigrationReadyExpositionList;
	v1Consumers: Array<ExpositionPreviousConsumerEntity>;
	mappingRequestId: string;
	migrated: MigratedExpositionListEntity;
	inViewExposition: ExpositionEntity;
	history: ExpositionHistoryListEntity;
	isLoadingJobErrorLogs: boolean;
	stepper: {
		columns: ExpositionColumnConfigListEntity;
		datablocks: ExpositionDatablockListEntity;
	};
}

const stateDefaults: IExpositionsState = {
	loading: false,
	isLoadingDatablockColumns: false,
	isSavingExposition: false,
	actionInProgress: 'none',
	permissions: ExpositionPermissionEntity.build({}),
	availableConsumers: [],
	availableTags: OrderedSet(),
	expositions: ExpositionListEntity.build([]),
	v1Expositions: MigrationReadyExpositionList.build([]),
	v1Consumers: [],
	mappingRequestId: '',
	migrated: MigratedExpositionListEntity.build([]),
	inViewExposition: ExpositionEntity.build(),
	history: ExpositionHistoryListEntity.build([]),
	isLoadingJobErrorLogs: false,
	stepper: {
		columns: ExpositionColumnConfigListEntity.build([]),
		datablocks: ExpositionDatablockListEntity.build([]),
	},
};

export const ExpositionsFeatureKey = 'expositions';

export class ExpositionsState extends Record<IExpositionsState>(stateDefaults) {
	public static key = ExpositionsFeatureKey;

	public toggleLoading(isLoading: boolean): ExpositionsState {
		return this.set('loading', isLoading);
	}

	public setActionInProgress(action: PublicationAction): ExpositionsState {
		return this.set('actionInProgress', action);
	}

	public toggleIsSavingExposition(isLoading: boolean): ExpositionsState {
		return this.set('isSavingExposition', isLoading);
	}

	public toggleLoadingDatablockColumns(isLoading: boolean): ExpositionsState {
		return this.set('isLoadingDatablockColumns', isLoading);
	}

	public updateDatablockListWithColumns(
		datablockId: number,
		columns: Array<ExpositionDatablockColumnEntity>
	): ExpositionsState {
		const datablocks = this.stepper.datablocks.setDatablockColumns(
			datablockId,
			columns
		);
		const stepper = {
			...this.stepper,
			datablocks,
		};
		return this.set('stepper', stepper);
	}

	public setColumnConfigList(
		columns: ExpositionColumnConfigListEntity
	): ExpositionsState {
		return this.set('stepper', {
			...this.stepper,
			columns,
		});
	}

	public setDatablockList(
		datablocks: ExpositionDatablockListEntity
	): ExpositionsState {
		return this.set('stepper', {
			...this.stepper,
			datablocks,
		});
	}

	public setAvailableConsumers(
		availableConsumers: Array<ExpositionConsumerEntity>
	): ExpositionsState {
		return this.set('availableConsumers', availableConsumers);
	}

	public setTags(tags: OrderedSet<AppTagItemEntity>): ExpositionsState {
		return this.set('availableTags', tags);
	}

	public resetStepper(): ExpositionsState {
		return this.merge(stateDefaults);
	}

	public setExpositionList(list: ExpositionListEntity): ExpositionsState {
		return this.set('expositions', list);
	}

	public setInViewExposition(expo: ExpositionEntity): ExpositionsState {
		return this.set('inViewExposition', expo);
	}

	public setCanCreateExposition(hasPermission: boolean): ExpositionsState {
		return this.set(
			'permissions',
			this.get('permissions').setHasCreateGlobalPermission(hasPermission)
		);
	}

	public setExpositionViewPermissions(permissions: {
		delete: boolean;
		view: boolean;
		edit: boolean;
	}): ExpositionsState {
		return this.set(
			'permissions',
			this.get('permissions').setExpositionViewPermissions(permissions)
		);
	}

	public setV1Expositions(
		list: MigrationReadyExpositionList
	): ExpositionsState {
		return this.set('v1Expositions', list);
	}

	public setUnmappedConsumers(
		consumers: Array<ExpositionPreviousConsumerEntity>
	): ExpositionsState {
		return this.set('v1Consumers', consumers);
	}

	public setRequestId(mappingRequestId: string): ExpositionsState {
		return this.set('mappingRequestId', mappingRequestId);
	}

	public setMigrated(list: MigratedExpositionListEntity): ExpositionsState {
		return this.set('migrated', list);
	}

	public setHistory(list: ExpositionHistoryListEntity): ExpositionsState {
		return this.set('history', list);
	}
	public setIsLoadingErrorLogs(isLoading: boolean): ExpositionsState {
		return this.set('isLoadingJobErrorLogs', isLoading);
	}
}
