import { immerable } from 'immer';

interface IMigratedExpositionItemEntity {
	id: number;
	oldExpositionId: number;
	oldExpositionLabel: string;
	newExpositionId: number;
	newExpositionLabel: string;
	migrationDate: Date | null;
}

const entityDefaults: IMigratedExpositionItemEntity = {
	id: -1,
	oldExpositionId: -1,
	oldExpositionLabel: '',
	newExpositionId: -1,
	newExpositionLabel: '',
	migrationDate: null,
};

export class MigratedExpositionItemEntity
	implements IMigratedExpositionItemEntity
{
	private [immerable] = true;
	public id = entityDefaults.id;
	public oldExpositionId = entityDefaults.oldExpositionId;
	public oldExpositionLabel = entityDefaults.oldExpositionLabel;
	public newExpositionId = entityDefaults.newExpositionId;
	public newExpositionLabel = entityDefaults.newExpositionLabel;
	public migrationDate = entityDefaults.migrationDate;

	public static build(
		params: Partial<IMigratedExpositionItemEntity> = {}
	): MigratedExpositionItemEntity {
		const inst = new MigratedExpositionItemEntity();
		inst.id = params.id ?? entityDefaults.id;
		inst.oldExpositionId =
			params.oldExpositionId ?? entityDefaults.oldExpositionId;
		inst.oldExpositionLabel =
			params.oldExpositionLabel ?? entityDefaults.oldExpositionLabel;
		inst.newExpositionId =
			params.newExpositionId ?? entityDefaults.newExpositionId;
		inst.newExpositionLabel =
			params.newExpositionLabel ?? entityDefaults.newExpositionLabel;
		inst.migrationDate = params.migrationDate ?? entityDefaults.migrationDate;
		return inst;
	}
}
