<ng-container>
	<form [formGroup]="form">
		<app-tooltip
			tooltipText="tooltip"
			i18n-tooltipText="@@form.file.forbidden-characters.tooltip">
			<mat-radio-group
				id="{{ cmpId + 'fileName-radio' }}"
				color="primary"
				[formControlName]="ExportFileFormControls.Strategy"
				class="app-export-file-configuration">
				<mat-radio-button
					id="{{ cmpId + 'fileName-radio-opt-' + idx }}"
					*ngFor="let option of fileNamingOptions; let idx = index"
					[value]="option.value">
					{{ option.label }}
				</mat-radio-button>
			</mat-radio-group>
		</app-tooltip>

		<mat-form-field
			class="app-export-file-configuration__user-defined-pattern"
			*ngIf="
				form.get(ExportFileFormControls.Strategy)?.value ===
				FileNameGenStrategy.UserDefinedFileNamePattern
			"
			[appearance]="Appearance">
			<mat-label i18n="@@export.fileName.strategy.userDefinedFileName.new">
				New Label
			</mat-label>
			<input
				type="text"
				matInput
				placeholder="userDefinedFileNamePattern"
				i18n-placeholder="
					@@export.fileName.strategy.userDefinedFileName.new.placeholder"
				id="{{ cmpId + 'filename-user-defined-pattern' }}"
				[formControlName]="ExportFileFormControls.FileName" />
			<mat-error
				*ngIf="
					form
						.get(ExportFileFormControls.FileName)
						?.hasError(ValidationErrorKeys.Required)
				"
				i18n="@@export.fileName.strategy.userDefinedFileName.new.error">
				You must define a file name
			</mat-error>
			<mat-error
				*ngIf="
					form
						.get(ExportFileFormControls.FileName)
						?.hasError(ValidationErrorKeys.ForbiddenCharacters)
				"
				i18n="@@export.fileName.strategy.userDefinedLabel.new.error.pattern">
				forbidden_character
			</mat-error>
		</mat-form-field>
	</form>
</ng-container>
