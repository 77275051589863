<app-modal *ngIf="vo$ | async; let vo">
	<button mat-icon-button modal-close-btn (click)="close()">
		<mat-icon
			[svgIcon]="DcIcons.Close"
			></mat-icon>
	</button>

	<app-panel
		class="app-card-outline__columns"
		headerHeight="3rem"
		modal-body
		isAlwaysOpen="true"
		hasSearchableGrid="true"
		hasRefresh="true"
		(refresh)="fetchColumns()"
		[actionsAvailable]="columnsGrid.selectionCount"
		[itemsCount]="vo.list.elements.size"
		[actions]="vo.actions"
		(search)="columnsGrid.search($event)">
		<span panel-title i18n="@@exposition.access.list.column.columns">
			columns
		</span>

		<div panel-info class="activated-columns-alert">
			<div *ngIf="columnsGrid.selectionCount" class="activated-columns-alert">
				<span>{{ columnsGrid.selectionCount }}&#32;</span>
				<span i18n="@@expositions.columns.config.selectedElements">Elements selectionnée</span>
			</div>
			<div *ngIf="vo.listErrors.size === 0" class="activated-columns-alert warn">
				<mat-icon [svgIcon]="DcIcons.Warning"></mat-icon>
				<span i18n="@@expositions.columns.config.alert.activatedColumns">
						activated columns used in access
				</span>
			</div>
			<div *ngIf="vo.listErrors.size > 0 && vo.listErrors.has(ColumnConfigErrorKey.NoActiveColumns)"
					 class="activated-columns-alert error">
				<mat-icon color="warn" [svgIcon]="DcIcons.Warning"></mat-icon>
				<span	i18n="@@expositions.columns.config.alert.minOneActive">min one active column</span>
			</div>
			<div *ngIf="vo.listErrors.size > 0 && vo.listErrors.has(ColumnConfigErrorKey.DuplicatedAlias)" class="activated-columns-alert error">
				<mat-icon [svgIcon]="DcIcons.Warning"></mat-icon>
				<span i18n="@@expositions.columns.config.alert.duplicateAlias">duplicated_alias</span>
			</div>
		</div>

		<app-exposition-columns-config
			#columnsGrid="ColumnsGrid"
			[columnConfigList]="vo.list"
			(errors)="
				modifyColumnConfigValidity($event)
			"></app-exposition-columns-config>
	</app-panel>

	<div modal-footer class="app-expositions-columns-modal__footer">
		<button *ngIf="vo.canEdit"
			id="{{ cmpId }}-save-btn"
			[color]="'primary'"
			class="app-expositions-columns-modal__footer__btn"
			mat-raised-button
			[disabled]="vo.listErrors.size !== 0"
			(click)="save()">
			<mat-icon [svgIcon]="DcIcons.Save"></mat-icon>
			<span i18n="@@general.action.update">update</span>
		</button>
	</div>
</app-modal>
