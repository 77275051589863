import { AppTagItemEntity, IisEqual } from '@dc-common-core';
import { immerable } from 'immer';
import { OrderedSet } from 'immutable';

interface IExpositionEndpointMetadataEntity {
	id: number;
	accessPoint: string;
	title: string;
	details: string;
	keywords: OrderedSet<AppTagItemEntity>;
}

const entityDefaults: IExpositionEndpointMetadataEntity = {
	id: -1,
	accessPoint: '',
	title: '',
	details: '',
	keywords: OrderedSet(),
};

export class ExpositionEndpointMetadataEntity
	implements
		IExpositionEndpointMetadataEntity,
		IisEqual<ExpositionEndpointMetadataEntity>
{
	private [immerable] = true;
	public id = entityDefaults.id;
	public accessPoint = entityDefaults.accessPoint;
	public title = entityDefaults.title;
	public details = entityDefaults.details;
	public keywords = entityDefaults.keywords;

	public static build(
		params: Partial<IExpositionEndpointMetadataEntity> = {}
	): ExpositionEndpointMetadataEntity {
		const instance = new ExpositionEndpointMetadataEntity();
		instance.id = params.id ?? entityDefaults.id;
		instance.accessPoint = params.accessPoint ?? entityDefaults.accessPoint;
		instance.title = params.title ?? entityDefaults.title;
		instance.details = params.details ?? entityDefaults.details;
		instance.keywords = params.keywords ?? entityDefaults.keywords;
		return instance;
	}
	public isEqualTo(obj: ExpositionEndpointMetadataEntity): boolean {
		if (this.accessPoint !== obj.accessPoint) {
			return false;
		} else if (this.title !== obj.title) {
			return false;
		} else if (this.details !== obj.details) {
			return false;
		}
		return true;
	}
}
