import { AppTagItemEntity, AvatarEntity } from '@dc-common-ui';
import { immerable, produce } from 'immer';

import { PublicationStatus } from '../publication-status.enum';
import { ExpositionHistoryEntity } from './exposition-history.entity';

interface IPublishedExpositionEntity {
	id: number;
	status: PublicationStatus;
	datablockId: number;
	datablockLabel: string;
	datablockUrl: string;
	url: string;
	publicationDate: string;
	title: string;
	details: string;
	keywords: Array<AppTagItemEntity>;
	columnsCount: number;
	accessCount: number;
	dataCount: number;
	publisherAvatar: AvatarEntity | null;
	history: Array<ExpositionHistoryEntity>;
	hasActiveAccess: boolean;
	dataUrl: string;
	oDataUrl: string;
}

const entityDefaults: IPublishedExpositionEntity = {
	id: -1,
	status: PublicationStatus.Unpublished,
	datablockId: -1,
	datablockLabel: '',
	datablockUrl: '',
	url: '',
	publicationDate: '',
	title: '',
	details: '',
	keywords: [],
	history: [],
	columnsCount: 0,
	publisherAvatar: null,
	accessCount: 0,
	dataCount: 0,
	hasActiveAccess: false,
	dataUrl: '',
	oDataUrl: '',
};

export class PublishedExpositionEntity implements IPublishedExpositionEntity {
	private [immerable] = true;
	public id = entityDefaults.id;
	public status = entityDefaults.status;
	public datablockId = entityDefaults.datablockId;
	public datablockLabel = entityDefaults.datablockLabel;
	public datablockUrl = entityDefaults.datablockUrl;
	public url = entityDefaults.url;
	public publicationDate = entityDefaults.publicationDate;
	public title = entityDefaults.title;
	public details = entityDefaults.details;
	public keywords = entityDefaults.keywords;
	public history = entityDefaults.history;
	public columnsCount = entityDefaults.columnsCount;
	public publisherAvatar = entityDefaults.publisherAvatar;
	public accessCount = entityDefaults.accessCount;
	public dataCount = entityDefaults.dataCount;
	public hasActiveAccess = entityDefaults.hasActiveAccess;
	public dataUrl = entityDefaults.dataUrl;
	public oDataUrl = entityDefaults.oDataUrl;

	public static build(
		params: Partial<IPublishedExpositionEntity> = {}
	): PublishedExpositionEntity {
		const instance = new PublishedExpositionEntity();
		instance.id = params.id ?? entityDefaults.id;
		instance.status = params.status ?? entityDefaults.status;
		instance.datablockId = params.datablockId ?? entityDefaults.datablockId;
		instance.datablockUrl = params.datablockUrl ?? entityDefaults.datablockUrl;
		instance.datablockLabel =
			params.datablockLabel ?? entityDefaults.datablockLabel;
		instance.url = params.url ?? entityDefaults.url;
		instance.publicationDate =
			params.publicationDate ?? entityDefaults.publicationDate;
		instance.title = params.title ?? entityDefaults.title;
		instance.details = params.details ?? entityDefaults.details;
		instance.keywords = params.keywords ?? entityDefaults.keywords;
		instance.history =
			params.history?.sort((a, b) => b.date.localeCompare(a.date)) ??
			entityDefaults.history;
		instance.columnsCount = params.columnsCount ?? entityDefaults.columnsCount;
		instance.accessCount = params.accessCount ?? entityDefaults.accessCount;
		instance.dataCount = params.dataCount ?? entityDefaults.dataCount;
		instance.publisherAvatar =
			params.publisherAvatar ?? entityDefaults.publisherAvatar;
		instance.hasActiveAccess =
			params.hasActiveAccess ?? entityDefaults.hasActiveAccess;
		instance.dataUrl = params.dataUrl ?? entityDefaults.dataUrl;
		instance.oDataUrl = params.oDataUrl ?? entityDefaults.oDataUrl;
		return instance;
	}

	public updateStatus(status: PublicationStatus): PublishedExpositionEntity {
		return produce(this, (draft: PublishedExpositionEntity) => {
			draft.status = status;
		});
	}

	public addInProgressRowToUpdates(): PublishedExpositionEntity {
		return produce(this, (draft: PublishedExpositionEntity) => {
			const up = Array.from(this.history.slice(0, 2));
			up.unshift(
				ExpositionHistoryEntity.build({
					status: PublicationStatus.Running,
				})
			);
			draft.history = up;
		});
	}
}
